%tbCommon {
  display: block;
  // width: 100%;
  padding: 0.5rem;
  font-size: $other-font-size-1;
  line-height: 1.5rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.1rem solid #d8d8d8;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
.textBox {
  @extend %tbCommon;
}
.textBox-search {
  @extend %tbCommon;
  height: 2.5rem;
}
.form-control-rounded {
  border-radius: 30px !important;
}
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
  @extend %tbCommon;
  height: 2rem;
}
input[type="text"]:disabled,
input[type="email"]:disabled,
input[type="number"]:disabled,
input[type="password"]:disabled {
  background-color: #e9ecef;
  opacity: 0.5;
}

textarea {
  @extend %tbCommon;
  // font-size: $other-font-size-2;
  font-family: "Lato", sans-serif;
  height: 8rem;
  width: 100%;
  padding-left: 0.5rem;
  padding-top: 0.2rem;
  resize: none;
}
textarea:disabled {
  opacity: 1;
  background-color: #e9ecef;
}
.react-autosuggest__input{
  font-family: "Lato", sans-serif;
  font-weight: 400;
}
//Hide Arrows From Input Number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

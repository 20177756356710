%vehicle-table-template-columns {
  grid-template-columns: 6% 15% 15% 11% 10% 10% 10% 18% 6%;
}
%vehicleP2P-table-template-columns {
  grid-template-columns: 6% 9% 10% 9% 11% 11% 7% 7% 7% 7% 12% 6%;
}
#vehicleTable .datagrid {
  .row {
    @extend %dataGridRow;
    @extend %vehicle-table-template-columns;
  }
  .column {
    @extend %dataGridColumn;
    @extend %vehicle-table-template-columns;
  }
  // .row__column span {
  //   @extend %dataGridColumn;
  // }
  .row__column label,
  .row__column a,
  .row__column span {
    @extend %class-for-tooltip;
  }
}

#vehicleTableP2P .datagrid {
  .row {
    @extend %dataGridRow;
    @extend %vehicleP2P-table-template-columns;
  }
  .column {
    @extend %dataGridColumn;
    @extend %vehicleP2P-table-template-columns;
  }
  .row__column span {
    @extend %class-for-tooltip;
  }
}

#oldAmenitiesTableId .datagrid,
#newAmenitiesTableId .datagrid {
  .row {
    @extend %dataGridRow;
    grid-template-columns: 10% 30% 30% 30%;
  }
  .column {
    @extend %dataGridColumn;
    grid-template-columns: 10% 30% 30% 30%;
  }
  .row__column {
    padding: 0.4rem;
  }
}

.overflowCommonVehicle {
  @extend %overflowCommon;
  width: 88px;
}

.vehicleRowIcon {
  float: left;
  margin-left: 3px;
  color: #147bc6;
  font-weight: bold;
  font-size: $other-font-size-5;
  padding-right: 5px;
}

.reservation-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
  flex-direction: row;
  min-width: 1400px;
  overflow: auto;
  padding: 0;
  margin: 0;
  font-family: "Inter" !important;
  font-size: 1.2rem;
  //line-height: 1.8rem;
  letter-spacing: 0.0015em;
  //text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  input,
  select {
    font-weight: 600 !important;
    font-size: 1.2rem !important;
    padding-top: 1rem !important;
  }
  .count-select {
    padding-top: 0.3rem !important;
  }
}
.res-container-column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
  //margin: 1rem;
  padding: 1.75em 1em;
  flex-basis: 19%;
  width: 19%;
  //   min-width: 30rem;
  border-right: 1px solid #e6e9ef;
  gap: 1em;
  &:last-child {
    border-right: none;
  }
  .res-main-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    height: 90vh;
  }
}

.common-res-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  background-color: #fff;
  border-radius: 1.3rem;
  //   margin: 0 0.8rem;
  padding: 1.2rem 1rem;
  //min-width: 290px;
  //overflow: auto;
  //box-shadow: 0 2px 6px 0 #dadafda6, 0 2px 6px 0 #ceceee8a;
  &:hover {
    box-shadow: 0 2px 6px 0 #dadafda6, 0 2px 6px 0 #ceceee8a;
  }
  .color-primary {
    color: #001e60 !important;
  }
  .btn {
    align-items: center;
    border-color: #0000;
    border-radius: 0.8rem;
    display: flex;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    justify-content: center;
    line-height: 2.4rem;
    text-transform: uppercase;
    text-transform: capitalize;
    width: 100%;
  }
  .common-reserve-btn {
    background: #ffff;
    color: #001e60;
    height: 3.2rem;
    font-size: 1.2rem !important;
    border-radius: 0.5rem !important;
    border: 1px solid #eaeaea !important;
    padding: 0.3rem 1rem !important;
    min-width: 8rem !important;
    margin-top: 0.3rem !important;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      background: var(--btn-fox-yellow) !important;
    }
    &:disabled {
      background-color: var(--btn-fox-disabled) !important;
      //border-color: #bebebe !important;
      // border-color: var(--btn-fox-disabled) !important;
      color: #fff !important;
      cursor: default;
    }
  }
  .btn-yellow-default {
    height: 3.2rem;
    //font-size: 1.2rem !important;
    border-radius: 0.5rem !important;
    padding: 0.3rem 1rem !important;
    min-width: 8rem !important;
    margin-top: 0.3rem !important;
    font-weight: 600;
    cursor: pointer;
  }
  .btn-outline-danger {
    background-color: #0000;
    background-image: none;
    border-color: #fd3550;
    color: #fd3550;
    &:hover {
      color: #fff;
      background-color: #fd3550;
      border-color: #fd3550;
    }
    &.focus,
    &:focus {
      color: #fff;
      background-color: #fd3550;
      border-color: #fd3550;
      box-shadow: none;
    }
    &.disabled,
    &:disabled {
      color: var(--color-fox-disabled);
      border-color: #eaeaea;
      background-color: transparent;
      cursor: default;
    }
    &:not(:disabled):not(.disabled) {
      &.active,
      &:active {
        color: #fff;
        background-color: #fd3550;
        border-color: #fd3550;
      }
    }
  }
  .fullwidth-btn {
    width: 100% !important;
  }
  input,
  select,
  .rw-input,
  .rw-dropdown-list-input,
  .special-label {
    color: #000000 !important;
    font-weight: 600 !important;
  }
  .sec-dep-cont {
    font-size: 1.4rem;
    font-weight: 600;
    color: #000000;
  }
}
.generel-rate-row {
  display: flex;
  justify-content: space-between;
}
.selected-section {
  box-shadow: 0 2px 6px 0 #dadafda6, 0 2px 6px 0 #ceceee8a;
}
.res-datetime-container {
  display: flex;
  background-color: #e6e9ef;
  border-radius: 0.8rem !important;
  border-color: rgba(0, 0, 0, 0);
  &:hover {
    outline: 1px solid #6cb3ef;
  }
  .separator {
    height: 2.4rem;
    width: 0.11rem;
    background: #b0b9ce;
    margin-top: 1rem;
  }
  // .input-ctrl {
  //   &:first-child {
  //     border-right: 1px solid #dedede !important;
  //   }
  // }
  .rw-datetime-picker
  // .rw-widget,
  //.rw-widget-picker,
  //.rw-state-focus,
  //.rw-widget-picker,
  //.rw-widget-container,
  //.rw-open
  {
    border-radius: 0.8rem !important;
    background-color: #e6e9ef;
    &:hover {
      border: none !important;
      outline: none !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: #e6e9ef;
      text-transform: none !important;
      //   width: inherit;
    }
    &:focus {
      border: none !important;
      outline: none !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: transparent !important;
      text-transform: none !important;
      //   width: inherit;
    }
  }
  .rw-select {
    display: none;
  }
  input {
    outline: none;
    border-radius: 0 !important;
    &:focus {
      border: none !important;
      outline: none !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: #e6e9ef;
      text-transform: none !important;
      //   width: inherit;
    }
    &:hover {
      border: none !important;
      outline: none !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: transparent !important;
      text-transform: none !important;
      background-color: #e6e9ef;
      //   width: inherit;
    }
  }
}
.title-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  img {
    height: 3.2rem;
    width: 3.2rem;
    border: 1px solid #b0b9ce;
  }
  i {
    font-size: 1.6rem;
    cursor: pointer;
    color: #585858;
  }
}
.res-loc-card-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  position: relative;
  .vertical-line {
    position: absolute;
    height: 3.8rem !important;
    left: 1rem;
    margin: 0;
    top: 34.5%;
    border-left: 0.1rem solid #e0e0e0;
  }
  .res-loc-card {
    display: flex;
    align-items: center;
    font-size: 1;
    column-gap: 1rem;
    .loc-left-icon {
      height: 2rem;
      width: 2rem;
    }
    .loc-main-container {
      display: flex;
      flex-direction: column;
    }
    i {
      color: #001e5d;
      font-size: 2rem;
    }
  }
}
.res-item-card-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 3rem;
  border: 1px solid #f1f1f1;
  border-radius: 1rem;
  .res-item-card-name {
    font-size: 1.2rem;
    color: #585858;
  }
  .res-item-card-value {
    font-size: 1.4rem;
    font-weight: 600;
    color: #001e60;
  }
  .res-card-value-container {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    img {
      cursor: pointer;
    }
  }
}
.image-circle {
  border-radius: 50% !important;
  object-fit: contain !important;
}

.accordion-common-container {
  background: #f8f8f8;
  padding: 1.6rem;
  border-radius: 1.6rem;
  margin-bottom: 1rem;
}
.group-ctrl {
  display: flex;
  .input-ctrl {
    flex: auto;
  }
  .checkmark-radio {
    top: 1rem;
  }
  i {
    color: #001e60;
  }
}
.disabled-group {
  label,
  .checkmark-radio {
    color: #ccc !important;
    border-color: #ccc !important;
    cursor: no-drop;
  }
  i {
    color: #ccc;
  }
}
.addons-btn,
.vehicle-btn {
  height: 3rem;
  width: 7rem !important;
  min-width: 7rem !important;
}
.fullwidth-btn {
  width: 100% !important;
}
@media (max-width: 1800px) {
  .addons-btn,
  .vehicle-btn {
    height: 3rem;
    width: 6rem !important;
    min-width: 6rem !important;
    font-size: 1rem !important;
  }
}
@media (max-width: 1750px) {
  // .custom-modal-title {
  //   font-size: 1.2rem !important;
  // }
  .res-container-column {
    font-size: 1rem !important;
    .message-title,
    .message,
    .check-box-ctrl,
    .custom-modal-title,
    .sec-dep-cont {
      font-size: 1.2rem !important;
    }

    .btn-yellow-default,
    .common-reserve-btn,
    .btn-outline-danger,
    .font-size-2 {
      font-size: 1.4rem !important;
    }
    .addons-btn,
    .vehicle-btn {
      height: 3rem;
      width: 7rem !important;
      min-width: 6rem !important;
      font-size: 1.2rem !important;
    }
    .rw-input,
    .rw-widget-picker,
    .common-title,
    .common-title-secondary,
    textarea,
    input,
    .selected-flag,
    .react-tel-input input,
    .react-tel-input input {
      font-size: 1.2rem !important;
    }
  }
}
@media (max-width: 1600px) {
  .custom-modal-title {
    font-size: 1.2rem !important;
  }
  .res-container-column {
    font-size: 1rem !important;
    .font-size-2,
    .message-title,
    .message,
    .check-box-ctrl,
    .custom-modal-title,
    .sec-dep-cont {
      font-size: 1.2rem !important;
    }

    .btn-yellow-default,
    .common-reserve-btn,
    .btn-outline-danger {
      font-size: 1.2rem !important;
    }
    .addons-btn,
    .vehicle-btn {
      height: 3rem;
      width: 6rem !important;
      min-width: 6rem !important;
      font-size: 1rem !important;
    }
    .rw-input,
    .rw-widget-picker,
    .common-title,
    .common-title-secondary,
    textarea,
    input,
    .selected-flag,
    .react-tel-input input,
    .react-tel-input input {
      font-size: 1rem !important;
    }
  }
}
@media (max-width: 1536px) {
  .custom-modal-title {
    font-size: 1.2rem !important;
  }
  .res-container-column {
    font-size: 1rem !important;
    .font-size-2,
    .message-title,
    .message,
    .check-box-ctrl,
    .custom-modal-title,
    .sec-dep-cont {
      font-size: 1.2rem !important;
    }

    .btn-yellow-default,
    .common-reserve-btn,
    .btn-outline-danger {
      font-size: 1.2rem !important;
    }
    .addons-btn,
    .vehicle-btn {
      height: 3rem;
      width: 6rem !important;
      min-width: 6rem !important;
      font-size: 1rem !important;
    }
    .rw-input,
    .rw-widget-picker,
    .common-title,
    .common-title-secondary,
    textarea,
    input,
    .selected-flag,
    .react-tel-input input,
    .react-tel-input input {
      font-size: 1rem !important;
    }
  }
}

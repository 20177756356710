@import "../../sass/abstracts/variables";
@import "../../sass/layout/grid";
#personal-info {
  .redo-ico {
    transform: scaleX(-1);
    font-size: $other-font-size-5;
    vertical-align: -webkit-baseline-middle;
    color: var(--color-primary);
    cursor: pointer;
  }
  .rw-widget-picker input[type="text"] {
    padding: 0 0.5rem;
  }
  /*need to implement some logic later need to add title for every label*/
  label {
    @extend %class-for-tooltip;
  }
  .form-group {
    height: 2rem;
  }
  .form-header {
    position: relative;
    .hbCheckBox {
      line-height: 1;
      position: absolute;
      top: 9px;
      right: 0;
      label:before {
        // border: 1px solid #000;
      }
    }
    i {
      padding-right: 0.5rem;
    }
  }
  .overrides {
    border: 1px solid #e4eaec;
    padding: 0.5rem;
    position: absolute;
    .hbCheckBox {
      display: grid;
      justify-items: baseline;
    }
  }
  .ico-upload-doc {
    height: 1.8rem;
    margin-top: 1re;
    display: inline-block;
    vertical-align: bottom;
    cursor: pointer;
  }
}

#personal-info, #landmark-info {
  .react-tel-input {
    .form-control {
      font-size: 11px !important;
      width: 100% !important;
      height: 22px !important;
      border-radius: 3px !important;
    }

    .invalid-number-message {
      font-size: 10px;
      left: 0;
      top: -15px;
    }

    .flag-dropdown {
      height: 22px !important;
      top: 0.5px;
    }

    //// CSS regarding include country dropdown along with flag
    &.code-with-flag {
      .special-label {
        display: block !important;
        top: 0.3rem !important;
        background: transparent !important;
        left: 20px !important;
        font-size: 1.2rem !important;
        width: 4.3rem;
        text-align: center;
        pointer-events: none;
      }

      .special-label:has(~ .open) {
        z-index: 15 !important;
      }

      .flag-dropdown {
        padding: 0.9rem 0rem !important;

        .selected-flag {
          width: 70px;
          padding: 0 0 0 0.5rem;
          height: 2rem;
          top: -0.95rem;

          .arrow {
            margin-top: -2px;
            left: 55px;
          }
        }
      }

      .form-control {
        padding-left: 75px;
      }
    }

    //// End block regarding include country dropdown along with flag
  }
}


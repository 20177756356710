@import "../../sass/abstracts/variables";
.pricing-header {
  width: 100%;
  height: 22px;
  line-height: 23px;
  //font-size: $default-font-size;
  font-weight: bold;
  color: #fff;
  background: var(--color-primary); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    var(--color-primary-light) 0%,
    var(--color-primary-light-1) 44%,
    var(--color-primary) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, var(--color-primary-light)),
    color-stop(44%, var(--color-primary-light-1)),
    color-stop(100%, var(--color-primary))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    var(--color-primary-light) 0%,
    var(--color-primary-light-1) 44%,
    var(--color-primary) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    var(--color-primary-light) 0%,
    var(--color-primary-light-1) 44%,
    var(--color-primary) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    var(--color-primary-light) 0%,
    var(--color-primary-light-1) 44%,
    var(--color-primary) 100%
  );
  background: linear-gradient(
    to bottom,
    var(--color-primary-light) 0%,
    var(--color-primary-light-1) 44%,
    var(--color-primary) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--color-primary-light)', endColorstr='var(--color-primary)',GradientType=0 );
}

.pricing-body {
  //font-size: $default-font-size;
  line-height: 22px;
  background-color: #fff;
}

.pricing-charge-col {
  width: 39%;
  float: left;
  padding-left: 1%;
  text-align: left;
}

.pricing-full-charge-col {
  width: 74%;
  float: left;
  padding-left: 1%;
  text-align: left;
}
.pricing-full-col {
  width: 99%;
  float: left;
  padding-left: 1%;
  text-align: left;
}

.pricing-unit-col {
  width: 20%;
  float: left;
  text-align: right;
}

.pricing-rate-col {
  width: 20%;
  float: left;
  text-align: right;
}

.pricing-desc-col {
  width: 40%;
  float: left;
  text-align: left;
}

.pricing-total-col {
  width: 19%;
  width: -webkit-calc(20% - 4px);
  width: -moz-calc(20% - 4px);
  width: calc(20% - 4px);
  float: left;
  text-align: right;
  padding-right: 4px;
}

.pricing-full-total-col {
  width: 24%;
  width: -webkit-calc(25% - 4px);
  width: -moz-calc(25% - 4px);
  width: calc(25% - 4px);
  float: left;
  text-align: right;
  padding-right: 4px;
}

.pricing-row {
  min-height: 22px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  position: relative;
}

.pricing-remove {
  width: 20px;
  height: 20px;
  border: 1px solid #147bc4;
  background-color: #147bc4;
  font-weight: bold;
  color: red;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
}
.pricingRelative {
  position: relative;
}
.pricingLeft1 {
  float: left;
  width: 100%;
}
.pricingRight {
  float: right;
}
.pricingClearBoth {
  clear: both;
}
.pricingBold {
  font-weight: bold;
}
.payment-border {
  border: 1px solid var(--color-primary);
}
.crditAddIcon {
  background: none;
  border: none;
  color: var(--color-primary);
  cursor: pointer;
  vertical-align: -webkit-baseline-middle;
}

%sectionLayout {
  min-height: 20px;
  vertical-align: middle;
  text-overflow: ellipsis;
  text-align: left;
  word-wrap: break-word;
}

.popup-container {
  display: grid;
  grid-auto-flow: column;
  padding: 25px;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
}

.popupInfo {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 1fr);
  align-self: start;
}

.popupDetails {
  display: grid;
  grid-auto-flow: row;
  /* grid-template-rows: repeat(3, 1fr); */
  align-self: start;
}

.popupInfo__Title {
  @extend %sectionLayout;
  font-weight: bold;
  width: 100px;
  white-space: nowrap;
}

.popupInfo__Detail {
  @extend %sectionLayout;
}

.popupInfo h3 {
  padding: 0;
  grid-column: 1/-1;
  border-bottom: 1px solid #e0e0e0;
  color: var(--color-primary);
  margin: 0 0px 10px 0px;
}

.column__header_popup {
  @extend %columnHeader;
  font-size: $default-font-size;
  background: var(--table-Header-1);
}
#billingTable #invoiceTable .datagrid .row {
  @extend %dataGridRow;
  grid-template-columns: 9% 9% 8% 8% 8% 7% 7% 5% 6% 5% 8% 5% 5% 5% 5%;
}

#billingTable #invoiceTable .datagrid .column {
  @extend %dataGridColumn;
  grid-template-columns: 9% 9% 8% 8% 8% 7% 7% 5% 6% 5% 8% 5% 5% 5% 5%;
}

#globalSearchMemberTable .datagrid .row {
  @extend %dataGridRow;
  grid-template-columns: 15% 20% 20% 20% 25%;
}

#globalSearchMemberTable .datagrid .column {
  @extend %dataGridColumn;
  grid-template-columns: 15% 20% 20% 20% 25%;
}

#globalSearchReservationTable .datagrid .row {
  @extend %dataGridRow;
  grid-template-columns: 10% 10% 10% 20% 15% 15% 20%;
}

#globalSearchReservationTable .datagrid .column {
  @extend %dataGridColumn;
  grid-template-columns: 10% 10% 10% 20% 15% 15% 20%;
}

#globalSearchrentalTable .datagrid .row {
  @extend %dataGridRow;
  grid-template-columns: 10% 10% 10% 20% 15% 15% 20%;
}

#globalSearchrentalTable .datagrid .column {
  @extend %dataGridColumn;
  grid-template-columns: 10% 10% 10% 20% 15% 15% 20%;
}

#creditHistoryTableId .datagrid .row {
  @extend %dataGridRow;
  grid-template-columns: 12% 10% 7% 10% 10% 8% 8% 9% 6% 20%;
}

#creditHistoryTableId .datagrid .column {
  @extend %dataGridColumn;
  grid-template-columns: 12% 10% 7% 10% 10% 8% 8% 9% 6% 20%;
}

#creditHistoryTableId {
  height: 15.5rem;
}

.tableScroll {
  overflow-y: auto;
  max-height: 15.5rem;
  overflow-x: hidden;
  border-bottom: 1px solid #eee;
  scrollbar-width: thin !important;
}
.tableScroll .row {
  font-size: $other-font-size-1;
}
.tableScrollX {
  overflow-x: auto;
  max-width: 30rem;
  overflow-y: hidden;
  border-bottom: 1px solid #eee;
  scrollbar-width: thin !important;
}

.section2 {
  float: left;
  width: 98%;
  padding-bottom: 5px;
}

.section3 {
  float: left;
  width: 500px;
}

.overrideBoxBorder {
  border: #abb2b9 solid 1px;
  width: 30%;
  border-radius: 5px;
}

.overrideBoxItems {
  width: 100%;
  font-weight: normal;
  margin: 5px;
  margin-bottom: 3px;
  font-size: $other-font-size-2;
}

.star {
  color: Red;
}

.exportPopupScroll {
  overflow-y: auto;
  max-height: 400px;
  overflow-x: hidden;
}

.paddingTop16 {
  padding-top: 16px;
}

.form-group-popup {
  margin-bottom: 2px !important;
}
.form-group-popup,
.grid-col-row {
  .required .col-form-label:after {
    font-weight: bold;
    color: #d06161;
    content: "*";
    position: absolute;
    font-size: $other-font-size-4;
    top: -0.2rem;
    padding-left: 0.5rem;
  }
}

#divAddCredit {
  .rw-widget-input.rw-input {
    padding-left: 10px;
  }

  [class*="icheck-"] {
    min-height: 20px;
    margin-top: 3px !important;
    margin-bottom: 0px !important;
    padding-left: 0px;
  }

  fieldset {
    border: none;
  }

  fieldset:disabled {
    i {
      pointer-events: none;
      color: gray !important;
    }
  }

  .dateandtimepicker {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 55% 43% 15%;
    column-gap: 2%;
  }
}

.upload-image-1 .user-fullimage {
  position: relative;
  border: 1px #e0e0e0 solid;
  // width: 100%;
  // height: 90%;
  padding: 1.5rem;
}

.upload-image-1 .user-fullimage .details {
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  background: #000;
  opacity: 0.7;
  padding: 1rem;
}

.upload-image-1 .uploadPhotoClose {
  position: absolute;
  right: 2px;
  font-size: $other-font-size-2-0;
  color: red;
  display: none;
  margin-top: -16px;
  // background: #fff;
}
.upload-image-1 .uploadIcon {
  //font-size: $default-font-size;
  margin: 7px;
  vertical-align: middle;
  color: #fff;
}
.upload-image-1 .loggedUserUploadImg {
  display: none;
}
.upload-image-1 .user-fullimage:hover .loggedUserUploadImg {
  display: block;
  opacity: 0.7;
}
.display-hidden {
  display: none;
}

.overflowCommon {
  @extend %overflowCommon;
  width: 100%;
}

#creditHistoryTableId {
  .formRow {
    .col-sm-12 {
      padding-bottom: 0.5rem;
      margin-left: 0.1rem;
      padding-left: 0rem;
    }
    .col-sm-3 {
      padding-left: 0rem;
      margin-left: -5rem;
    }
    .col-sm-9 {
      padding-left: 0rem;
    }
  }
}
.modal-body {
  #oldAmenitiesTableId,
  #newAmenitiesTableId {
    font-weight: bold;
  }

  #member-quick-view,
  #vehicle-quick-view,
  #tracker-quick-view,
  #voucher-quick-view {
    span,
    label {
      @extend %class-for-tooltip;
    }
  }
  #globalSearchMemberTable,
  #globalSearchReservationTable,
  #globalSearchrentalTable,
  #transaction-list-table {
    td {
      white-space: nowrap;
      span,
      label {
        @extend %class-for-tooltip;
        cursor: pointer;
      }
    }
  }

  .popup-row {
    display: grid;
    grid-template-columns: 45% 50%;
    padding: 0.2rem;
    column-gap: 5%;
  }
  .partner-info-sec {
    padding: 0rem 5rem 0 4rem;
    .popup-row {
      grid-template-columns: 55% 40%;
    }
  }

  .default-rates {
    td {
      &:nth-child(2) {
        border-left: 1px solid #dee2e6 !important;
      }
      label {
        line-height: 2rem;
        text-align: left !important;
      }
      min-width: 8rem !important;
      text-align: left !important;
    }
    .atrribute-head {
      border: none !important;
      background: #fff !important;
    }
    .atrribute-cell {
      border: none !important;
      background: #fff !important;
      width: 10rem !important;
      text-align: right !important;
    }
  }
}

#divAddCredit,
#divReverseCredit {
  .form-group-popup,
  .grid-col-row {
    &.validation-failed {
      select,
      input,
      textarea {
        border: 1px solid #d06161;
      }
    }
  }
}
// .transformX0 {
//   transform: translateX(-50%) !important;
//   -ms-transform: translateX(-50%);
//   position: absolute;
//   top: 25%;
//   -ms-transform: translateY(-50%);
//   transform: translateY(-50%);
// }
// .horizontal-center-fixed {
//   top: 0%;
//   transform: translateX(-50%) !important;
//   -ms-transform: translateX(-50%);
//   position: fixed;
// }

.u-center-text {
  text-align: center;
}
.u-margin-bottom-small {
  margin-bottom: 1.5rem;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem;
}
.u-margin-bottom-big {
  margin-bottom: 8rem;
}

.tbicon--left {
  padding-right: 0.85rem !important;
  padding-left: 2.9rem !important;
}
.position--relative {
  position: relative !important;
}

.position--absolute {
  position: absolute !important;
}

.position--unset {
  position: unset !important;
}
.input--icon {
  position: absolute;
  top: 0.6rem;
  z-index: 2;
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  // line-height: 1rem;
  text-align: center;
  vertical-align: middle;
}
.icon--right {
  right: 0;
}
.or-line:before {
  right: 24px;
  margin-left: -50%;
}

.or-line:after {
  left: 24px;
  margin-right: -50%;
}

.google-button {
  width: 50%;
  margin: 0;
  border: 1px solid #0c59f2;
  background-color: #fff;
  border-radius: 3px;
  color: #0c59f2;
  /*width: 310px;*/
  height: 33px;
  cursor: pointer;
}

.google-button:hover {
  border: 1px solid #0c59f2;
  background-color: #fff;
  color: #0c59f2;
  box-shadow: 0 0 0 1px #0c59f2;
}

.google-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.google-icon {
  height: 18px;
  width: 18px;
  margin-right: 24px;
  background: url(../../assets/img/icon/gIcon.svg) no-repeat;
}

/* Shadows */
.shadow-primary {
  box-shadow: 0 7px 30px rgba(2, 140, 253, 0.7) !important;
}
.shadow-secondary {
  box-shadow: 0 7px 30px rgba(117, 129, 137, 0.83) !important;
}
.shadow-success {
  box-shadow: 0 7px 30px rgba(21, 201, 33, 0.74) !important;
}
.shadow-danger {
  box-shadow: 0 7px 30px rgba(253, 53, 80, 0.7) !important;
}
.shadow-warning {
  box-shadow: 0 7px 30px rgba(254, 151, 1, 0.65) !important;
}
.shadow-info {
  box-shadow: 0 7px 30px rgba(13, 206, 236, 0.73) !important;
}
.shadow-light {
  box-shadow: 0 7px 30px rgba(186, 193, 193, 0.83) !important;
}
.shadow-dark {
  box-shadow: 0 7px 30px rgba(34, 48, 53, 0.81) !important;
}
/*fill color for svg*/

.color--green {
  fill: green !important;
}
.color--red {
  fill: red !important;
}
/*back ground color*/
.background-edit {
  background: #e3eef380;
}
.background-disabled {
  background: lightgrey !important;
  opacity: 0.75;
}
.background-green {
  background: green !important;
}
.background-red {
  background: red !important;
}
.add-edit-row-highlight {
  background: #e1f0f7 !important;
}
.w-17 {
  width: 17% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-33 {
  width: 33% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-75 {
  width: 75% !important;
}

.w-83 {
  width: 83% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.w-200 {
  width: 200% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}
.h-80 {
  height: 80% !important;
}
.h-85 {
  height: 85% !important;
}
.h-90 {
  height: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}
.mw-60 {
  max-width: 60% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.mt-01,
.my-01 {
  margin-top: 0.1rem !important;
}

.mr-01,
.mx-01 {
  margin-right: 0.1rem !important;
}

.mb-01,
.my-01 {
  margin-bottom: 0.1rem !important;
}

.ml-01,
.mx-01 {
  margin-left: 0.1rem !important;
}

.mtr-01,
.myr-01 {
  margin-top: -0.1rem !important;
}

.mrr-01,
.mxr-01 {
  margin-right: -0.1rem !important;
}

.mbr-01,
.myr-01 {
  margin-bottom: -0.1rem !important;
}

.mlr-01,
.mxr-01 {
  margin-left: -0.1rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.mbr-1,
.myr-1 {
  margin-bottom: -0.25rem !important;
}

.mbr-0-3,
.myr-0-3 {
  margin-bottom: -0.3rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.mlr-0-25 {
  margin-left: -0.25rem !important;
}

.mtr-1 {
  margin-top: -0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mtr-2,
.myr-2 {
  margin-top: -0.5rem !important;
}

.mrr-2,
.mxr-2 {
  margin-right: -0.5rem !important;
}

.mlr-2,
.mxr-2 {
  margin-left: -0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.mbr-2 {
  margin-bottom: -0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mtr-1-0 {
  margin-top: -1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mrr-3,
.mxr-3 {
  margin-right: -1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.mlr-1 {
  margin-left: -1rem !important;
}

.mlr-3 {
  margin-left: -3rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.mtr-4 {
  margin-top: -1.5rem !important;
}

.mrr-4 {
  margin-right: -1.5rem !important;
}

.mbr-4 {
  margin-bottom: -1.5rem !important;
}

.mlr-4 {
  margin-left: -1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.mt-5-0,
.my-5-0 {
  margin-top: 5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 7rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-01 {
  padding: 0.1rem !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-2-0 {
  padding: 2rem !important;
}

.pt-2-0,
.py-2-0 {
  padding-top: 2rem !important;
}

.pr-2-0,
.px-2-0 {
  padding-right: 2rem !important;
}

.pb-2-0,
.py-2-0 {
  padding-bottom: 2rem !important;
}

.pl-2-0,
.px-2-0 {
  padding-left: 2rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 6rem !important;
}

.pl-6,
.px-6 {
  padding-left: 6rem !important;
}
.bb-0 {
  border-bottom: 0 !important;
}
.d-flex {
  display: flex !important;
}
.d-contents {
  display: contents !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.flex-1 {
  flex: 1;
}
.align-items-center {
  align-items: center !important;
}
.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.fs-d {
  font-size: $default-font-size;
}
.fs-07 {
  font-size: $other-font-size-07;
}
.fs-08 {
  font-size: $other-font-size-08;
}
.fs-09 {
  font-size: $other-font-size-09;
}
.fs-0 {
  font-size: $other-font-size-0;
}
.fs-1 {
  font-size: $other-font-size-1;
}
.fs-2 {
  font-size: $other-font-size-2;
}
.fs-3 {
  font-size: $other-font-size-3;
}
.fs-4 {
  font-size: $other-font-size-4 !important;
}
.fs-5 {
  font-size: $other-font-size-5;
}
.fs-6 {
  font-size: $other-font-size-6;
}
.fs-7 {
  font-size: $other-font-size-7;
}
.fs-8 {
  font-size: $other-font-size-8;
}
.fs-9 {
  font-size: $other-font-size-9;
}
.fs-2-0 {
  font-size: $other-font-size-2-0;
}
.fs-2-1 {
  font-size: $other-font-size-2-1 !important;
}
.fs-2-2 {
  font-size: $other-font-size-2-2 !important;
}
.fs-2-3 {
  font-size: $other-font-size-2-3 !important;
}
.fs-2-4 {
  font-size: $other-font-size-2-4 !important;
}
.fs-2-5 {
  font-size: $other-font-size-2-5 !important;
}
.fs-2-6 {
  font-size: $other-font-size-2-6 !important;
}
.fs-2-7 {
  font-size: $other-font-size-2-7 !important;
}
.fs-2-8 {
  font-size: $other-font-size-2-8 !important;
}
.fs-2-9 {
  font-size: $other-font-size-2-9 !important;
}
.fs-3-0 {
  font-size: $other-font-size-3-0 !important;
}
.fs-3-5 {
  font-size: $other-font-size-3-5 !important;
}
.fs-4-0 {
  font-size: $other-font-size-4-0 !important;
}
.top-0-3 {
  top: 0.3rem !important;
}
.topr-0-3 {
  top: -0.3rem !important;
}
.font-bold {
  font-weight: bold !important;
}
.letter-spacing-10 {
  letter-spacing: 1rem;
}
.form-header {
  color: var(--color-primary);
  line-height: 3rem;
  font-size: $other-font-size-3;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 1rem;
  border-bottom: 0.1rem solid var(--color-primary);
  a {
    color: var(--color-primary);
  }
  i {
    padding-right: 0.5rem;
  }
  .inheritedCheckBoxLabel {
    line-height: 1;
  }
  .icheck-primary {
    display: flex;
    align-items: center;
  }

  > i,
  .icon-display-none > i {
    display: none !important;
  }
  &.icon-display-none i {
    display: none !important;
  }
}
.summary-form-header {
  align-items: center;
  // font-weight: bold;
  margin-bottom: 0rem;
  border-top: 0.1rem solid var(--color-primary);
  border-bottom: 0.1rem solid var(--color-primary);
  .externalColor {
    color: #b413dc;
  }
  .testRes {
    background: red;
    color: #fff;
    padding: 0 1rem;
    display: inline-block;
    background-color: red;    
    border-radius: 0.5em;
    border-bottom: 0.2em solid #A30000;
    // box-shadow: 0 2px 3px #ccc;
    &.pseudo-link:hover{
      color: #fff !important;
      background-color: #A30000;  
    }
  }
  .verticalLineSeparator {
    border-left: 0.1rem solid var(--color-primary);
    margin: 0 0.3rem 0 0.3rem;
  }
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
.iconBeforeLink {
  font-size: $other-font-size-5;
  color: var(--color-primary);
  padding-right: 0.3rem;
}
.border-none {
  border: none !important;
}
.border-rounded {
  border: 1px solid #ccc !important;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.bt-1 {
  border-top: 1px solid;
}

.green-color {
  color: #0d7c0d;
}
.blue-color {
  color: rgb(20, 123, 198);
}
.red-color {
  color: red;
}
.whiteColor {
  color: $color-white !important;
}
.light-gray-color {
  color: lightgray;
}
.gray-color {
  color: gray;
}
.darkred-color {
  color: darkred !important;
}

.light-darkred-color {
  color: #b94a48 !important;
}

.default-color{
  color: $color-grey-dark !important;
}

.line-height-1-5 {
  line-height: 1.5 !important;
}
.line-height-1 {
  line-height: 1 !important;
}

.font-weight-normal {
  font-weight: normal !important;
}
.font-weight-light  { font-weight: $font-weight-light !important; }
.font-weight-medium { font-weight: $font-weight-medium !important; }
.font-weight-semi-bold { font-weight: $font-weight-semi-bold !important; }
.font-weight-bold   { font-weight: $font-weight-bold !important; }
.font-italic        { font-style: italic !important; }
.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
}
.line-clamp-6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
  word-break: break-all;
}
.vertical-align-top {
  vertical-align: top;
}

.visibility-hidden {
  visibility: hidden !important;
}

.grayed-out-row,
.grayed-out-row .pseudo-link,
.grayed-out-row .resStatus,
.grayed-out-row .fa,
.grayed-out-row a,
.grayed-out-row-selected:hover {
  color: rgb(187, 187, 187) !important;
}

.grayed-out-row {
  .filled-circle {
    background-color: lightgray;
  }
}
.grayed-out-row-selected {
  color: $color-white !important;
}

.left-0 {
  left: 0 !important;
}
.right-0 {
  right: 0 !important;
}

.vertical-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontal-align-center {
  display: flex;
  justify-content: center;
}
.horizontal-align-right {
  display: flex;
  justify-content: flex-end;
}
.horizontal-align-left {
  display: flex;
  justify-content: flex-start;
}
.flex-direction-column {
  flex-direction: column
}

.overrides {
  border: 1px solid #d3dce9 !important;
  padding: 0.5rem;
  border-radius: 0.5rem;
  position: absolute;
  .overrride-checkBox {
    display: grid;
    justify-items: baseline;
  }
}
.border-right-none {
  border-right: none !important;
}
.break-word {
  word-break: break-word !important;
}

.column-gap-1 {
  column-gap: 1rem;
}
.letter-spacing-1{
  letter-spacing: 0.7rem;
}
.column-gap-3-5 {
  column-gap: 3.5rem;
}

%basic-reservation-datagrid {
  .row {
    @extend %dataGridRow;
    #icoEmployeeId {
      border-left: none !important;
      text-align: right !important;
    }
    #freeCreditAppliedamountId,
    #voucherAddedId {
      border-right: none !important;
      border-left: none !important;
      text-align: left !important;
    }
    #freeCreditGrantedId {
      border-right: none !important;
      text-align: left !important;
    }
    #employeeNameId {
      border-right: none !important;
    }
  }
  .column {
    @extend %dataGridColumn;
  }
  .row__column label,
  .row__column span,
  .row__column a {
    @extend %class-for-tooltip;
  }
}
#reservationTable,
#reservationTableP2P,
#reservationTableP2P_MV,
#reservationTableP2P_MR,
#reservationTable_MV,
#reservationTable_MR {
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding: 0px;
  }
  .react-datepicker-popper {
    z-index: 500;
  }

  .react-datepicker {
    font-size: $other-font-size-1;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    line-height: 1.6;
  }

  .img-container {
    grid: auto / 22% 25% 26% 27% !important;
  }
}

#reservationTable .datagrid {
  @extend %basic-reservation-datagrid;
  .column {
    grid-template-columns: 5% 10.5% 9% 7% 6% 7% 5% 5% 5% 9% 9% 9% 8.5% 5%;
  }
  .row {
    grid-template-columns: 5% 10.5% 9% 7% 6% 5% 2% 5% 5% 5% 9% 9% 9% 1.5% 1.5% 1.5% 4% 5%;
  }
}
#reservationTableP2P .datagrid {
  @extend %basic-reservation-datagrid;
  .column {
    grid-template-columns: 5% 10% 9% 7% 6% 7% 5% 5% 5% 7% 7% 6% 7.5% 8.5% 5%;
  }
  .row {
    grid-template-columns: 5% 10% 9% 7% 6% 5% 2% 5% 5% 5% 7% 7% 6% 7.5% 1.5% 1.5% 1.5% 4% 5%;
  }
}

#reservationTable_MR .datagrid {
  @extend %basic-reservation-datagrid;
  .column {
    grid-template-columns: 5% 11% 9% 7% 6% 5% 5% 6% 11% 10% 11.5% 8.5% 5%;
  }
  .row {
    grid-template-columns: 5% 11% 9% 7% 6% 5% 5% 6% 11% 10% 11.5% 1.5% 1.5% 1.5% 4% 5%;
  }
}
#reservationTableP2P_MR .datagrid {
  @extend %basic-reservation-datagrid;
  .column {
    grid-template-columns: 5% 9% 9% 7% 6% 5% 5% 6% 7% 9.5% 9% 9% 8.5% 5%;
  }
  .row {
    grid-template-columns: 5% 9% 9% 7% 6% 5% 5% 6% 7% 9.5% 9% 9% 1.5% 1.5% 1.5% 4% 5%;
  }
}
#reservationTableP2P_MV .datagrid {
  @extend %basic-reservation-datagrid;
  .column {
    grid-template-columns: 5% 9% 9% 7% 6% 7% 5% 5% 6% 10% 9.5% 9% 8.5% 5%;
  }
  .row {
    grid-template-columns: 5% 9% 9% 7% 6% 5% 2% 5% 5% 6% 10% 9.5% 9% 1.5% 1.5% 1.5% 4% 5%;
  }
}

#chargeTable .datagrid {
  .row {
    @extend %dataGridRow;
    grid-template-columns: 130% 30% 30% 30%;
  }
  .column {
    @extend %dataGridColumn;
    grid-template-columns: 130% 30% 30% 30%;
  }
}

.resGenreInfoSummary {
  width: 100px;
  border-radius: 5px;
  background: red;
  color: #fff;
  float: left;
  margin-left: 30px;
  margin-bottom: 2px;
  text-align: center;
  font-weight: bold;
}

//Reservation status wise color set//
#reservationSummary {
  .NOT_STARTED {
    color: #ff0000;
  }
  .PENDING {
    color: #d36c19;
  }
  .CONFIRMED {
    color: #5dbd91;
  }
  .CANCELLED {
    color: #ff6a00;
  }
  .MESSAGE {
    color: #d4bfff;
  }
  .INCOMPLETE {
    color: #ffd800;
  }
  .BACKGROUND_CHECK_PENDING {
    color: #de2f13;
  }
  .PAYMENT_PENDING {
    color: #6b2222;
  }
  .COMPLETED.true {
    color: #ff00ff;
  }
  .COMPLETED.false,
  .COMPLETED {
    color: #8dacb0;
  }
  .IN_PROGRESS.true {
    color: #d98880;
  }
  .IN_PROGRESS.false,
  .IN_PROGRESS {
    color: #eacf12;
  }
  .LATE {
    color: #d98880;
  }
  .RETURN_LATE {
    color: #ff00ff;
  }
  .WEBERROR {
    color: darkgoldenrod;
  }
}

#transactionListTable .datagrid {
  .row {
    @extend %dataGridRow;
    grid-template-columns: 7% 7% 6% 6% 7% 5% 5% 5% 5% 5% 7% 5% 5% 5% 5% 5% 5% 5%;
  }
  .column {
    @extend %dataGridColumn;
    grid-template-columns: 7% 7% 6% 6% 7% 5% 5% 5% 5% 5% 7% 5% 5% 5% 5% 5% 5% 5%;
  }
}

#integrationStatusTable .datagrid {
  .row {
    @extend %dataGridRow;
    grid-template-columns: 10% 14% 10% 15% 15% 18% 18%;
    &.payloadColumnShow {
      grid-template-columns: 10% 14% 10% 15% 15% 12% 12% 12% !important;
    }
  }
  .column {
    @extend %dataGridColumn;
    grid-template-columns: 10% 14% 10% 15% 15% 18% 18%;
    text-align: center;
    &.payloadColumnShow {
      grid-template-columns: 10% 14% 10% 15% 15% 12% 12% 12% !important;
    }
  }
}

#integrationStatusTable,
#invoice-modal-id {
  .payload-select-item-border {
    border: 1px solid #ccc !important;
    border-top: 0px !important;
  }
  .selected-row-color {
    background-color: #adbfde !important;
  }
  .vertical-align-center {
    display: revert;
    &.cardIcon {
      display: flex !important;
      align-items: center;
      justify-content: center;
      column-gap: 0.5rem;
    }
  }
  .line-clamp {
    word-break: normal !important;
  }

  .table {
    td {
      border-bottom: 1px solid #ccc !important;
    }
    td:first-child {
      border-left: 1px solid #ccc !important;
    }
    td:last-child {
      border-right: 1px solid #ccc !important;
    }
  }
  .expand-bg-color {
    background: #adbfde4a;
  }
}

#requestResponsePayloadTable {
  .sectionLabel {
    font-weight: bold;
    font-size: 13px;
  }
  .datagrid {
    .row {
      @extend %dataGridRow;
      grid-template-columns: 10% 15% 10% 13% 13% 19% 20%;
    }
    .column {
      @extend %dataGridColumn;
      grid-template-columns: 10% 15% 10% 13% 13% 19% 20%;
      text-align: center;
    }
  }
  #expand-collapse-id {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.container {
  width: auto !important;
}

.datagrid .row__column {
  .icon {
    list-style: none;
    display: flex;
    grid-auto-flow: column;
    -webkit-align-content: center;
    align-content: center;
  }
  .tt-container label {
    @extend %class-for-tooltip;
  }
}

#imageRowsId .row1 .row__column {
  padding: 8px 4px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: none;
  //font-size: $default-font-size;
}

.contentOverflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-align: justify;
}

.iconBesideTextBox {
  position: absolute;
  top: 0;
  right: 0;
  //font-size:  $default-font-size;
  font-weight: bold;
}

#divOptInXpress {
  &.icheck-primary > input:first-child {
    &:not(:checked) + {
      label::before {
        background-color: brown !important;
      }
    }
    &:checked + {
      label::before,
      input[type="hidden"] + label::before {
        background-color: purple !important;
      }
    }
  }
}

#contractDetailsSectionId {
  .col-4,
  .col-8 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
}

#reservation-rental-summary {
  .enabled-field-for-tooltip label {
    @extend %class-for-tooltip;
  }
}

#cancelled-reservation-modal {
  label {
    @extend %class-for-tooltip;
  }
}
.error-block {
  display: flex;
  justify-items: center;
  align-items: center;
  column-gap: 1rem;
  border-radius: 1rem;
  background: #feebeb;
  padding: 12px 16px 12px 16px;

  i {
    font-size: 2.8rem;
    color: #f93838;
  }

  .error-msg-block {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    .error-title {
      font-size: 1.4rem;
      color: #e33333;
    }

    .error-msg {
      font-size: 1.2rem;
    }
  }
}

/* style of "Dot" to separate base rates in the vehicle section */
.dot {
  position: relative;
}

.dot::before {
  content: "";
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-color: #bebebe;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* End Dot */

/* Custom accordion styles */
.accordion-header {
  padding-top: 1rem;
  // padding-right: 3rem;
  cursor: pointer;
  width: 100%;
  display: inline-flex;
}

.accordion-header:hover {
  color: #000;
}

.accordion-header.active {
  color: #000;
}

.accordion-content {
  display: none;
  transition: max-height 2s ease;
}

.accordion-content.show {
  display: block;
  max-height: 100rem;
}

/* End Custom accordion styles */

/* Custom Message Box styles */
.message-box {
  position: absolute;
  padding: 1rem;
  border-radius: 0.5rem;
  border-radius: 1.2rem;
  background: #fff;
  z-index: 10;
  box-shadow: 0px 1.6rem 3.2rem 0px rgba(0, 0, 0, 0.16);
  padding: 1.6rem !important;

  .message-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .message-title {
    font-style: normal;
    font-weight: $font-weight-semi-bold;
    line-height: 2.4rem;
    color: #000;
    font-size: 1.6rem;
    letter-spacing: 0.0024rem;
  }

  .message-subtitle {
    margin-bottom: 0.5rem;
    color: #000;
    // font-family: Roboto;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: $font-weight-semi-bold;
    line-height: 140%;
  }

  .message-content {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 150%;
  }

  .message {
    flex: 1;
    margin-right: 0rem;
    text-align: justify;
  }

  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 2rem;
    color: #aaa;
    width: 2rem !important;

    img {
      height: 2rem !important;
      width: 2rem !important;
    }
  }
}

/* End Custom Message Box styles */

.fox-res-default-page {
  display: flex;
  padding: 8rem 0rem 8rem 0rem;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
  background: #fff;
  border-radius: 1.6rem;

  .message-icon {
    display: flex;
    padding: 1.6rem;
    align-items: flex-start;
    gap: 0.8rem;
    border-radius: 6.4rem;
    border: 1px solid var(--color-grey-light-5);
    background: var(--color-grey-light-5);
  }

  .message-title {
    color: #000;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: $font-weight-semi-bold;
    line-height: 1.8rem;
  }

  .message {
    color: #585858;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 140%;
    padding: 0 1.5rem;
  }
}

// Reservation status wise defined color
.NOT_STARTED {
  color: #ff0000;
}

.PENDING {
  color: #d36c19;
}

.CONFIRMED {
  color: #5dbd91;
}

.CANCELLED {
  color: #ff6a00 !important;
}

.MESSAGE {
  color: #d4bfff;
}

.INCOMPLETE {
  color: #ffd800;
}

.BACKGROUND_CHECK_PENDING {
  color: #de2f13;
}

.PAYMENT_PENDING {
  color: #6b2222;
}

.COMPLETED.true {
  color: #ff00ff;
}

.COMPLETED.false {
  color: #8dacb0;
}

.IN_PROGRESS.true {
  color: #d98880;
}

.IN_PROGRESS.false {
  color: #eacf12;
}

.LATE {
  color: #d98880;
}

.RETURN_LATE {
  color: #ff00ff;
}

.WEBERROR {
  color: darkgoldenrod;
}
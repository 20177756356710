.markercluster-map {
  height: 90vh;
}

.leaflet-container {
  height: 90vh;
  width: 100%;
}

.background-green {
  background-color: rgba(200, 100, 0, 0.5);
  /* Tint color */
  background-blend-mode: multiply;
}

.landmark-lbl {
  position: absolute;
  //left: 15px;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  top: 11px;
}

.shuttle-lbl {
  position: absolute;
  /* left: 0; */
  color: #000;
  font-size: 1.8rem;
  cursor: pointer;
  top: 2px;
  text-align: center;
  //width: 18px;
}

/* Customize the Clustered Markers */
.landmark-circle {
  width: 5em;
  height: 5em;
  border: 2px solid #ededed;
  box-sizing: border;
  border-radius: 50%;
  position: absolute;
  top: 0;
}

.landmark-circle + .landmark-circle {
  transition: 0.5s;
  top: 5px;
  left: -5px;
  border: 2px solid #ededed;
}

.landmark-circle + .landmark-circle + .landmark-circle {
  transition: 0.5s;
  top: 10px;
  left: -10px;
  border: 2px solid #ededed;
}

.shuttle-icon {
  position: absolute;
  left: 0;
  color: #000;
  display: inline;
  font-size: 3.3rem;
  cursor: pointer;
  top: 8px;
  text-align: center;
  width: 18px;
  color: purple;
}

.shuttle-icon + .shuttle-icon {
  top: 16px;
  left: -8px;
}

.shuttle-icon + .shuttle-icon + .shuttle-icon {
  top: 24px;
  left: -16px;
}

.landmark-count-class-2 {
  position: absolute;
  font-size: 18px;
  color: #ededed;
  top: 0.9rem;
  left: 0.9rem;
}

.landmark-count-class-3 {
  position: absolute;
  font-size: 18px;
  color: #ededed;
  top: 15px;
  left: -3rem;
}

.shuttle-count-class-2 {
  position: absolute;
  font-size: 2rem;
  color: #ededed;
  top: 0.9rem;
  left: 0.9rem;
  font-weight: bold;
}

.shuttle-count-class-3 {
  position: absolute;
  font-size: 2rem;
  color: #ededed;
  top: 15px;
  left: -3rem;
  font-weight: bold;
}

.marker-cluster-custom {
  width: 5em;
  height: 5em;
  margin: 5px;
  border-radius: 50%;
  background-color: purple;
  border: 1px solid #fff;
  box-shadow: 0 0 0 2px green, 0 0 0 3px white, 0 0 0 5px green;
  font-size: 1.8rem;
  color: #ededed;
}

.marker-cluster-custom-landmark {
  background: #eebb00;
  border: 0.1875em solid #db7373;
  border-radius: 50%;
  // box-shadow: 0.375em 0.2em 0 0 rgba(15, 28, 63, 0.25);
  height: 5em;
  width: 5em;
  font-size: 1.8rem;
  color: #122eed;
  background: rgba(253, 156, 115, 0.6);
  border: 3px solid #ededed;
  border-radius: 50%;
  color: #ededed;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
  font-size: 1.8rem;
}

.mycluster1 {
  width: 40px;
  height: 40px;
  background-color: greenyellow;
  text-align: center;
  font-size: 24px;
}

.custom-marker-cluster1 {
  background: #ffffff;
  border: 15px solid #f00800;
  border-radius: 50%;
  color: #000000;
  height: 33px;
  line-height: 37px;
  text-align: center;
  width: 33px;
}

#shuttleTable .leaflet-zoom-animated {
  bottom: -55px !important;

  &.table-view-landmark {
    bottom: -40px !important;
  }
  &.table-view-shuttle {
    bottom: -20px !important;
  }
  &.route-content-popup {
    bottom: -20px !important;
  }
}

/***zoom style***/
#shuttleTable .leaflet-control {
  border-bottom: none;
}

#shuttleTable .leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

.leaflet-refresh-ctrl {
  position: absolute;
  top: 61px;
  left: -34px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 1.8rem !important;
}

.leaflet-direction-ctrl {
  position: absolute;
  top: 100px;
  left: -34px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 1.8rem !important;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.shuttle-cluster-double {
  position: absolute !important;
  top: 7px !important;
  left: -7px !important;
}

.shuttle-cluster-double-1 {
  position: absolute !important;
  top: 6px !important;
  left: -8px !important;
}

.shuttle-cluster-tripple {
  position: absolute !important;
  top: 12px !important;
  left: -16px !important;
}

.shuttle-count {
  position: absolute;
  font-size: 17px;
  color: #000;
  z-index: 500;
  top: 7px;
  left: 10px;
}

.shuttle-count-1 {
  position: absolute;
  font-size: 17px;
  color: #000;
  z-index: 500;
  top: 12px;
  left: 0px;
}

.table-view {
  z-index: 10 !important;
  top: -3rem;
}

.number2 {
  z-index: 10 !important;
  left: -280px !important;
  top: -7rem;
}

.number3 {
  z-index: 10 !important;
  bottom: auto;
  left: 130px !important;
  top: -7rem;
}

.leaflet-popup::before {
  content: "";
  background: #122eed;
}

.leaflet-popup {
  &.table-view-shuttle {
    bottom: 0px !important;
    left: -196px !important;

    .leaflet-popup-content {
      line-height: 1;
      margin: -3px 5px 3px 5px !important;
    }
  }

  &.table-view-landmark {
    bottom: -20px !important;
    left: -196px !important;

    .leaflet-popup-content {
      line-height: 1;
      margin: -5px 0 0 !important;
    }
  }

  .leaflet-popup-content {
    .topHeader .heading-tertiary {
      font-size: 1.4rem !important;
    }

    font-size: 1.1rem !important;
  }
}

/**routing path css**/
.leaflet-routing-container {
  top: 48px;
}

.leaflet-routing-container-hide .leaflet-routing-collapse-btn {
  top: 0px !important;
  left: 2px !important;
}

.route-btn-conatainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.travel-mode {
  display: flex;
  justify-content: center;
  column-gap: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.travel-mode .icon-disabled {
  color: #bbb;
  cursor: no-drop;
}

.travel-point {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.travel-mode .fa-car {
  color: #1e4e9c;
  cursor: pointer;
}

.travel-mode i {
  font-size: 1.8rem;
}

.travel-mode i::before {
  border-radius: 50%;
  background: #e8f0fe;
  padding: 0.5rem;
}

.marker-popup {
  font-size: 1.2rem;
}

.marker-popup-btn-container {
  display: flex;
  justify-content: center;

  .btn i {
    font-size: 1.5rem;
    padding-right: 0.5rem;
  }
}

.leaflet-popup-content {
  padding: 1rem 1.5rem 1.5rem;
}

.autocomplete-wrapper {
  width: 350px;
  position: relative;
  display: inline-block;
}

.autocomplete-wrapper > div {
  width: 100%;
}

.autocomplete-wrapper input {
  border: 1px solid #cecece;
  padding: 7px;
  font-size: 12px;
  width: 100%;
}

.rptate-icon {
  position: absolute;
  right: 4px;
  top: 75px;
  z-index: 12;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.autocomplete-wrapper input:focus {
  border-color: #0f67ff;
  box-shadow: none;
  outline: none;
}

.autocomplete-wrapper .dropdown {
  position: absolute;
  width: 100%;
  padding: 0;
  text-align: left;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #0f67ff;
  border-top: none;
  z-index: 2;
}

.autocomplete-wrapper .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 15px;
}

.autocomplete-wrapper .item.selected-item {
  background-color: #ecf0f1;
  color: #000;
}

.autocomplete-wrapper .item:hover {
  background-color: #ecf0f1;
  color: #000;
}

.headertop {
  font-size: $other-font-size-4;
  height: 5rem;
  background-color: var(--color-primary);
  border-bottom: var(--line);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .org-logo {
    display: flex;
    align-items: center;
    img {
      object-fit: fill;
    }

    .logo-max-width {
      max-width: 8rem;
    }
    .logo-size {
      //height: 4rem;
      width: 7rem;
      max-height: 4rem;
    }

    .logo-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: palegoldenrod;
      width: 7rem;
      height: auto !important;
      max-width: 8rem;
      font-size: 1.3rem;
      font-weight: bold;
      display: -webkit-box !important;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-align: center;
    }

    .background-image-class {
      filter: brightness(100%);
      opacity: 100%;
    }

    .header-logo-background {
      border-radius: 0.5rem 0 0 0.5rem;
      background-image: linear-gradient(#1e3c72, #2a5298, #3d5fd6);
    }
  }
  @media only screen and (max-width: $bp-smallest) {
    flex-wrap: wrap;
    align-content: space-around;
    height: 11rem;
  }
  .org-logo > div {
    border: solid 0.1rem rgba(211, 211, 211, 0.4);
    &.no-org-dropdwon {
      border: none;
    }
  }

  .org-logo > div:nth-of-type(1) {
    &.no-border-right {
      border-right: none;
    }
    border-radius: 0.5rem 0 0 0.5rem;
  }

  .org-logo > div.no-org-dropdwon:nth-of-type(1) {
    .header-logo-background {
      border-radius: 0.5rem;
    }
    .background-image-class {
      border: solid 0.1rem rgba(211, 211, 211, 0.4);
      border-radius: 0.5rem;
      padding-top: 0.2rem;
    }
  }

  .org-logo > div:nth-of-type(2) {
    border-left: none;
    border-radius: 0 0.5rem 0.5rem 0;
  }
}

.common-search {
  flex: 0 0 25%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: $bp-smallest) {
    order: 1;
    flex: 0 0 100%;
    // background-color: var(--color-grey-light-2);
  }
  input {
    width: 55rem;
    max-width: 60rem;
    height: 2.8rem;
    z-index: 501;
  }
  &__input {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    // background-color: var(--color-grey-light-2);
    border: none;
    padding: 0.7rem 2rem;
    border-radius: 10rem;
    width: 90%;
    transition: all 0.2s;
    margin-right: -3.25rem;

    &:focus {
      outline: none;
      width: 100%;
      //   background-color: var(--color-grey-light-3);
    }

    &::-webkit-input-placeholder {
      font-weight: 100;
      //   color: var(--color-grey-light-4);
    }
  }
  &__button {
    border: none;
    background-color: #fff;
    // background-color: var(--color-grey-light-2);

    &:focus {
      outline: none;
    }

    &:active {
      transform: translateY(0.2rem);
    }
  }

  &__icon {
    height: 2rem;
    width: 2rem;
    fill: var($color-white);
    left: 0;
  }
  .searchIconPosition {
    right: 0;
    color: #111;
    height: 2.8rem;
    z-index: 502;
    background-color: #ddd;
    border-radius: 0 0.25rem 0.25rem 0;
    border: 0.1rem solid #acaaaa;
    border-left: none;
  }
  .search-area-dropdown {
    width: 12.5rem;
    z-index: 502;
    left: 0;
    background-color: lightgray !important;
    border-radius: 0.25rem !important;
    .rw-widget-container {
      height: 2.8rem !important;
      background-color: lightgray !important;
      border-radius: 0.25rem 0 0 0.25rem !important;
    }
    .rw-open{
      .rw-widget-container {
        border-radius: 0.25rem 0 0 0 !important;
      }
    }
  }
  .input-clear-icon-position{
    z-index: 502;
    right: 2.5rem;
  }
}

.watermark {
  text-align: center;
  position: relative;
  top: 15%;
  font-weight: bolder;
  font-size: 2.2rem;
  letter-spacing: 0.7rem;
  display: inline-table;
  color: lightgrey;
  opacity: 0.5;
}

////////////////////////////////////////
// USER NAVIGATION
.user-nav {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .show {
    background-color: var(--color-quicklink-hover-1);
    color: $color-white;
    top: 1rem !important;
    left: 1rem !important;
  }
  & > * {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
  }
  & > *:not(:last-child) {
    padding: 0 1rem;
  }
  & > *:hover {
    background-color: var(--color-quicklink-hover-1);
  }

  &__icon-box {
    position: relative;
  }

  &__icon {
    height: 2rem;
    width: 2rem;
    fill: $color-white;
  }

  &__notification {
    font-size: $default-font-size;
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 50%;
    background-color: var(--color-notification);
    color: $color-white;
    position: absolute;
    top: -0.8rem;
    right: -0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__user {
    display: flex;
    align-items: center;
    color: $color-white;
  }
  &__user-photo {
    height: 2.7rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  &.parent-org-dropdown > :first-child {
    padding: 0 0.5rem;
  }
  .logo,
  .logo-text,
  .header-logo-background {
    background-color: transparent;
    cursor: default;
  }
  #impersonate-user-container{
    border-radius: 0.8rem;
    .user-nav__user-photo{
      height: 2rem;
    }
  
  &:hover{
    background-color: transparent !important;
    cursor: default;
  }
}
  // #impersonating-user{
  //   background-color: transparent !important;
  //   cursor: default;
  // }
  #admin-user{
    background-color: #fff !important;
    cursor: pointer;
    .user-nav__user{
      color: #001034;
    }
    &:hover{
      background-color: var(--color-quicklink-hover-1) !important;
      .user-nav__user{
        color: #fff;
      }
    }
  }
  >span{
    background-color: transparent !important;
    cursor: default;
  }
}
.displayNone {
  display: none !important;
}
.border-impersonate-user {
  border: 1px solid rgba(255, 255, 255, 0.32);
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
}



@import "../../../../sass/abstracts/_mixins.scss";
@import "../../../../sass/abstracts/variables";

#fox-res-pricing-table {
  .fox-res-default-page {
    display: flex;
    padding: 8rem 0rem 8rem 0rem;
    flex-direction: column;
    align-items: center;
    gap: 2.4rem;
  }

  .pricing-section,
  .selected-vehicle {
    background: #fff;
    border-radius: 1.6rem;
    padding: 1.6rem;

    .message-box {
      width: auto !important;
    }
  }

  .selected-vehicle {
    margin-bottom: 1.2rem;
    padding: 1.2rem 1.6rem;
  }

  .charge-table-rate,
  .charge-table-addons,
  .charge-table-payments-credit {
    display: flex;
    padding: 1.6rem;
    flex-direction: column;
    gap: 0.6rem;
    border-radius: 1.6rem;
    border: 1px solid #f1f1f1;

    .row {
      [class^="col-lg"] {
        padding: 0;
        color: #000;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 1.8rem;
        /* 150% */
        letter-spacing: 0.0018rem;
      }

      :nth-child(2) {
        font-weight: $font-weight-semi-bold;
      }
    }
  }

  .charge-table-addons {
    gap: 0;
  }

  .addons-separator {
    .row {
      border-bottom: 1px solid #f1f1f1;
      padding: 0.8rem 0;
    }

    :last-child {
      border-bottom: none;
    }
  }

  #taxes-fees-message-box {
    &.message-box {
      min-width: max-content !important;
      width: 30rem !important;
    }

    .message {
      display: inline-flex;
      flex-direction: column;
      gap: 1.2rem;

      .row {
        color: #000;
        font-weight: 500;
      }
    }
  }

  #credit-policy-message-box,
  #paynow-addons-disclosure-message-box {
    &.message-box {
      width: 40rem !important;
    }
  }

  .vehicle-class-item-in-card {
    img {
      width: 7.4rem;
      height: auto;
    }
  }

  .vehicle-section {
    .model {
      color: #000;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: $font-weight-medium;
      line-height: 140%;
    }

    .catgory {
      border-radius: 4rem;
      background: #e6e9ef;
      width: fit-content;
    }
  }

  .icon-on-label {
    position: absolute !important;
    left: 1.5rem;
  }

  .pricing-body {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    .col-lg-4 {
      font-weight: $font-weight-semi-bold;
    }
  }

  .total-estimated-charge,
  .charge-table-payments-credit {
    border-radius: 0.8rem;
    background: #334b80;
    display: flex;
    padding: 1.2rem 1.6rem;
    justify-content: center;
    gap: 1.2rem;

    // font-size: 1.6rem;
    .row {
      width: 100%;

      [class^="col-lg"] {
        color: var(--Neutral-BNW-white, #fff);
        font-size: 1.2rem;
        font-style: normal;
        font-weight: $font-weight-bold;
        line-height: 1.6rem;
        /* 100% */
      }

      .col-lg-4 {
        font-weight: $font-weight-bold;
      }

      &.total {
        [class^="col-lg"] {
          font-size: 1.6rem;
          padding: 0;
        }
      }
    }
  }

  .res-pricing-action-block {
    display: flex;
    align-items: flex-start;
    gap: 1.6rem;
    min-width: 100%;

    .res-summary-actions {
      display: flex;
      align-items: flex-end;
      gap: 1.2rem;
      justify-content: flex-end;

      .action-icon {
        display: flex;
        padding: 0.8rem;
        align-items: center;
        gap: 0.6rem;
        border-radius: 3.2rem;
        background: #e6e9ef;
        cursor: pointer;

        &:hover {
          background-color: #001e60;

          img {
            &.invoice-icon {
              content: url("../../../../assets//img/input/invoice-hover.svg");
            }

            &.transaction-icon {
              content: url("../../../../assets//img/input/transaction-hover.svg");
            }

            &.voucher-icon {
              content: url("../../../../assets//img/input/coupon-percent-hover.svg");
            }

            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }

  .charge-table-payments-credit {
    background: transparent;
    flex-direction: column;

    .row {
      [class^="col-lg"] {
        color: #000;
      }
    }
  }
}

.res-security-block {
  #security-deposit-policy-message-box {
    &.message-box {
      width: 40rem !important;
    }
  }
}

.new-addons-label {
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  background: #ebeffe;
  color: #395ff9;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  width: max-content;
  text-transform: uppercase;
}

.test-res-label,
.last-res-label {
  padding: 0.4rem 0.8rem 0.3rem;
  border-radius: 3rem;
  background: #feebeb;
  color: #e33333;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  width: max-content;
  text-transform: uppercase;
}

.last-res-label {
  background: #efe7fe;
  color: #590ee0;
}

.res-security-block {
  width: 100%;
  background: #fff;
  padding: 1.6rem;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: space-between;
  position: relative;

  .sec-title {
    i {
      font-size: 1.6rem;
      cursor: pointer;
    }
  }

  .error-block {
    display: flex;
    justify-items: center;
    align-items: center;
    column-gap: 1rem;
    border-radius: 1rem;
    background: #feebeb;
    padding: 12px 16px 12px 16px;

    i {
      font-size: 2.8rem;
      color: #f93838;
    }

    .error-msg-block {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;

      .error-title {
        font-size: 1.4rem;
        color: #e33333;
      }

      .error-msg {
        font-size: 1.2rem;
      }
    }
  }

  .security-check {
    label {
      font-size: 1.4rem;
      font-weight: 400 !important;
      color: #000000;
      line-height: 1.2 !important;
    }
  }

  .charge-block {
    height: 48px;
    padding: 12px;
    border-radius: 8px;
    border-radius: 12px;
    background: #f1f1f1;
    display: flex;
    justify-content: space-between;

    label {
      font-size: 1.4rem;
      font-weight: 600;
      color: #000;
    }
  }

  /* The Modal (background) */
  .custom-modal {
    display: none;
    /* Hidden by default */
    // position: fixed; /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    //overflow: auto; /* Enable scroll if needed */
    // background-color: rgb(0, 0, 0); /* Fallback color */
    // background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  /* Modal Content */
  .custom-modal-content {
    background-color: #fefefe;
    // margin: auto;
    padding: 10px;
    // border: 1px solid #888;
    width: 90%;
    border-radius: 1.6rem;
    box-shadow: 0px 16px 32px 0px #00000029;
  }

  .custom-modal-block {
    display: block;
  }

  /* The Close Button */
  .custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .custom-modal-title {
      font-size: 1.4rem;
      font-weight: 600;
      color: #000;
    }

    .custom-modal-close {
      color: #585858;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  .custom-modal-close:hover,
  .custom-modal-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
}

.addons-container {
  .fa-info-circle {
    line-height: 1.6rem;
  }
}
.counter-addons {
  border-radius: 0.5rem;
  //border: 1px solid #e6e9ef;
  margin-top: 0.3rem;
  .count-btn {
    font-size: 1.1rem !important;
    padding: 0 !important;
    min-width: 3rem !important;
    border-radius: 0 !important;
    margin: 0 !important;
    border: 1px solid #e6e9ef;
    cursor: pointer;
    &:hover {
      background: var(--btn-fox-yellow) !important;
    }
  }
  select {
    padding: 0.3rem 0 0 2rem !important;
    height: 3rem !important;
    width: 6rem;
    font-size: 1.4rem;
  }
  .fa-plus {
    color: #00bcb4;
  }
  .fa-minus {
    color: #e33333;
  }
}
.counte-addons-lbl {
  text-align: center;
  align-self: center;
  height: 3rem;
  width: 3rem;
  align-self: center;
  line-height: 2.8rem;
}

.classic-total-bg {
  background: #585858;
}
.modern-total-bg {
  background: #334b80;
}
.pricing-container {
  // background-color: #f8f8f8;
  color: #666;
  line-height: 22px;
  font-size: 1.3rem;
  .pricing-classic-header,
  .rate-row,
  .generel-rate-row {
    display: flex;
    justify-content: space-between;
  }
  .pricing-classic-header,
  .rate-row {
    font-weight: 400;
  }
  .common-title {
    color: #666;
    font-weight: 600;
  }
  .total-estimated-charge {
    border-radius: 0.8rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
    padding: 1.2rem 1.6rem;
    margin-top: 1rem;
    color: #fff;
  }
}
.modern-pricing-container {
  padding: 1.6rem;
  color: #000;
  line-height: 22px;
  font-size: 1.2rem;
  border: 1px solid #f1f1f1;
  border-radius: 1.6rem;
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  .rate-row,
  .generel-rate-row {
    font-family: Inter;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8rem;
    letter-spacing: 0.0015em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .text-lbl {
    font-weight: 500;
    text-align: left;
  }
  .text-rate {
    font-weight: 600;
    text-align: right;
  }
  .pricing-classic-header,
  .rate-row,
  .generel-rate-row {
    display: flex;
    justify-content: space-between;
  }
  .pricing-classic-header,
  .rate-row {
    font-weight: 400;
  }
  .common-title {
    color: #000;
    font-weight: 600;
  }
  .col-lg-4,
  .col-lg-8 {
    padding: 0;
  }
  .balance-due-row {
    font-size: 1.6rem;
    font-weight: 700 !important;
  }
}
.total-estimated-charge {
  border-radius: 0.8rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  padding: 1.2rem 1.6rem;
  margin-top: 1rem;
  color: #fff;
}
.custom-modal-header {
  padding: 1rem 1rem 0 1rem;
  .fa-times {
    font-size: 2rem;
  }
}
.taxes-fees-modal {
  top: 2.5rem;
  right: 12rem;
  .tax-body {
    display: flex;
    row-gap: 1rem;
    flex-direction: column;
    .generel-rate-row {
      padding: 0 1.8rem 0 1rem;
    }
  }
  .total-estimated-charge {
    font-weight: bold;
    gap: 0 !important;
  }
}
.protection-addons-modal {
  top: 4rem;
  right: 10rem;
  p {
    color: #585858;
  }
}
@media (max-width: 1536px) {
  .modern-pricing-container {
    .rate-row,
    .generel-rate-row,
    .fs-6,
    .txt-underline {
      font-size: 1rem !important;
    }
  }
  .fs-6 {
    font-size: 1.5rem !important;
  }
  .total-estimated-charge {
    padding: 0.5rem !important;
  }
}

@import "../abstracts/variables";

#memberTable .datagrid {
  .row {
    @extend %dataGridRow;
    grid-template-columns: 5% 7% 10% 2% 6% 12% 10% 18% 10% 10% 10%;

    #icoEmployeeId {
      border-left: none !important;
      text-align: right !important;
    }

    #employeeNameId {
      border-right: none !important;
    }

    &.no-driving-license {
      grid-template-columns: 5% 7% 10% 2% 6% 14% 20% 10% 13% 13%;
    }
  }

  .column {
    @extend %dataGridColumn;
    grid-template-columns: 5% 7% 12% 6% 12% 10% 18% 10% 10% 10%;

    &.no-driving-license {
      grid-template-columns: 5% 7% 12% 6% 14% 20% 10% 13% 13%;
    }
  }

  .row__column label,
  .row__column a,
  .row__column span {
    @extend %class-for-tooltip;
  }
}

.memberSumarryInfo h3 {
  padding: 0;
  grid-column: 1/-1;
  border-bottom: 1px solid #e0e0e0;
  color: var(--color-primary);
  margin: 10px 0px 10px 0px;
}

.col20Left {
  width: 20%;
  text-align: left;
}

.col25Left {
  width: 25%;
  text-align: left;
}

.col50Left {
  width: 50%;
  text-align: left;
}

.font-weight-bold {
  font-weight: bold !important;
}

.divRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;
}

.divColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}

.input-container {
  border: 0px solid #e0e0e0;
  // font-size: $default-font-size;
  //font-weight: 700;
  padding: 1.5rem;
  position: relative;
  //padding-top: 2rem;
  //margin-top: 1rem;
  border-radius: 5px;

  &__rb {
    position: absolute;
    display: grid;
    grid-auto-flow: column;
    width: 45rem;
    top: -1.8rem;

    span {
      background: #fff;
    }
  }
}

.btnSize {
  min-width: 8rem;
}

#docUploadTable .datagrid {
  .row {
    @extend %dataGridRow;
    grid-template-columns: 10% 20% 30% 10% 30%;
  }

  .column {
    @extend %dataGridColumn;
    grid-template-columns: 10% 20% 30% 10% 30%;
  }
}

#memberSearchPopupTable .datagrid {
  .row {
    @extend %dataGridRow;
    grid-template-columns: 10% 15% 10% 15% 25% 12% 13%;
  }

  .column {
    @extend %dataGridColumn;
    grid-template-columns: 10% 15% 10% 15% 25% 12% 13%;
  }
}

.popupSearchResultScroll {
  overflow-y: auto;
  max-height: 300px;
  overflow-x: hidden;
}

#memberQuickView {

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 0px;
    padding-left: 10px;
  }

  .formRow h3 {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    font-weight: bold;
  }

  .formRow .tableScroll {
    padding-left: 18px !important;
  }
}

#memberQuickView.input-container {
  border: 0px solid #e0e0e0;
  padding: 1.5rem;
  padding-top: 0;
  position: relative;
  border-radius: 5px;
  font-size: $default-font-size !important;
}

.fa-password-reset>.fa-key {
  font-size: 0.85rem;
}

.profileRoundIcon {
  width: 2.5rem !important;
  height: 2.5rem !important;
  font-size: $other-font-size-4 !important;
  line-height: 2.5rem !important;
}

#memberMessageSearch .search-input-container,
#memberFavoriteSearch .search-input-container,
#memberVehicleSearch .search-input-container {
  .rw-widget {
    width: 16rem !important;
  }

  .search-input-row {
    display: grid;
    grid-template-columns: 20% 20% 15% 35%;
    column-gap: 2%;
  }

  .search-input-row__column {
    display: grid;
    grid-template-columns: 30% 70%;
  }

  .search-input-row__column:nth-child(3) {
    display: grid;
    column-gap: 3%;
  }
}

#memberMessageSearchTable .datagrid {
  .row {
    @extend %dataGridRow;
    grid-template-columns: 10% 10% 10% 10% 60%;
  }

  .column {
    @extend %dataGridColumn;
    grid-template-columns: 10% 10% 10% 10% 60%;
  }

  .row__column label,
  .row__column a,
  .row__column span {
    @extend %class-for-tooltip;
  }
}

#memberFeedbackSearchTable .datagrid {
  .row {
    @extend %dataGridRow;
    grid-template-columns: 5% 10% 10% 50% 10% 15%;
  }

  .column {
    @extend %dataGridColumn;
    grid-template-columns: 5% 10% 10% 50% 10% 15%;
  }

  .row__column label,
  .row__column a,
  .row__column span {
    @extend %class-for-tooltip;
  }
}

#memberFavoriteSearchTable {
  .datagrid {
    .row {
      @extend %dataGridRow;
      grid-template-columns: 5% 8% 12% 12% 13% 15% 15% 10% 10%;
    }

    .column {
      @extend %dataGridColumn;
      grid-template-columns: 5% 8% 12% 12% 13% 15% 15% 10% 10%;
    }
  }

  fieldset {
    border: none;
  }

  #btnRequestNow {
    margin-left: -8rem;
  }
}

#memberVehicleSearchTable {
  .datagrid {
    .row {
      @extend %dataGridRow;
      grid-template-columns: 5% 5% 5% 13% 8% 8% 8% 8% 8% 8% 8% 8% 8%;
      height: 2.3rem !important;
    }

    .column {
      @extend %dataGridColumn;
      grid-template-columns: 5% 5% 5% 13% 8% 8% 8% 8% 8% 8% 8% 8% 8%;
    }
  }

  .memberVehicleSearchBtn {
    display: grid;
    grid-template-columns: 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6%;
    column-gap: 1.5%;
  }

  .btnVehicle {
    padding: 0 !important;
    font-size: $other-font-size-0 !important;
    cursor: pointer !important;
    font-weight: 400 !important;
    letter-spacing: 1px !important;
    border-radius: 0.25rem !important;
    border: 1px solid transparent !important;
    height: 2rem !important;
    width: 9rem !important;
    height: 3.5rem !important;
  }
}

.review-img {
  border-radius: 24px;
  box-shadow: 0 1px 2px #8d8d8d;
  background-size: contain;
  height: 48px;
  margin-top: 10px;
  width: 48px;
}

// .add-reverse-credit-modal {
//   .col-sm-1,
//   .col-sm-2,
//   .col-sm-3,
//   .col-sm-4,
//   .col-sm-5,
//   .col-sm-6,
//   .col-sm-7,
//   .col-sm-8,
//   .col-sm-9,
//   .col-sm-10,
//   .col-sm-11,
//   .col-sm-12 {
//     position: relative;
//     width: 100%;
//     min-height: 1px;
//     padding-right: 5px !important;
//     padding-left: 15px;
//   }
// }

#creditHistoryTableId {
  .table thead th {
    font-size: $other-font-size-0 !important;
  }

  .table tr td label {
    text-overflow: ellipsis;
    overflow: hidden !important;
    white-space: nowrap;
    display: block;
  }
}
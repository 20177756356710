.close {
  float: right;
  font-size: $other-font-size-5;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

// .modal-open {
//   overflow: hidden;
// }

.fox-modal {
  .close {
    color: #000 !important;
    font-size: $other-font-size-2-0;
  }
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
  text-align: center;
  padding: 0 !important;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.4;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.4rem;
  padding-left: 1rem !important;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding-right: 1.5rem;
}
.modal-title {
  margin-bottom: 0;
  //line-height: 0;
  font-size: $other-font-size-3;
}
.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
/* Modals */
.modal-header {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.modal-content {
  border-radius: 0.25rem;
}
.fox-modal {
  .modal-content {
    border-radius: 1.6rem;
  }
}

.swal-modal {
  border-radius: 0.25rem;
}
.swal-button,
.swal-button:focus {
  background-color: var(--color-primary);
  box-shadow: 0 7px 30px rgba(2, 140, 253, 0.7) !important;
}
.swal-button--cancel {
  color: #fff;
  background-color: var(--color-primary);
}
/* Background Colors */
.bg-primary {
  background-color: var(--color-primary) !important;
}
.bg-success {
  background-color: #15ca20 !important;
}
.bg-info {
  background-color: #0dceec !important;
}
.bg-secondary {
  background-color: #75808a !important;
}
.bg-danger {
  background-color: #fd3550 !important;
}
.bg-dark {
  background-color: #223035 !important;
}
.bg-light {
  background-color: #e9eaea !important;
}
.bg-warning {
  background-color: #ff9700 !important;
}
.bg-contrast {
  background: rgba(255, 255, 255, 0.3) !important;
}
.bg-body {
  background: rgb(247, 247, 255) !important;
}
.bg-inverse-primary {
  background-color: rgba(0, 140, 255, 0.18) !important;
}
.bg-inverse-success {
  background-color: rgba(21, 202, 32, 0.18) !important;
}
.bg-inverse-info {
  background-color: rgba(13, 206, 236, 0.18) !important;
}
.bg-inverse-secondary {
  background-color: rgba(117, 128, 138, 0.18) !important;
}
.bg-inverse-danger {
  background-color: rgba(253, 53, 80, 0.18) !important;
}
.bg-inverse-dark {
  background-color: rgba(34, 48, 53, 0.2) !important;
}
.bg-inverse-warning {
  background-color: rgba(255, 151, 0, 0.18) !important;
}
.bg-inverse-light {
  background-color: rgba(233, 234, 234, 0.2) !important;
}

.swal-text {
  text-align: center;
}
.swal-footer {
  text-align: center;
}

.border-left-sm {
  border-left: 2px solid !important;
}

.border-right-sm {
  border-right: 2px solid !important;
}

.border-top-sm {
  border-top: 2px solid !important;
}

.border-bottom-sm {
  border-bottom: 2px solid !important;
}

.border-left-lg {
  border-left: 4px solid !important;
}

.border-right-lg {
  border-right: 4px solid !important;
}
.border-top-lg {
  border-top: 4px solid !important;
}
.border-bottom-lg {
  border-bottom: 4px solid !important;
}
/* Borders */
.border-primary {
  border-color: var(--color-primary) !important;
}
.border-success {
  border-color: #15ca20 !important;
}
.border-info {
  border-color: #0dceec !important;
}
.border-secondary {
  border-color: #75808a !important;
}
.border-secondary-light {
  border-color: #33444a !important;
}
.border-danger {
  border-color: #fd3550 !important;
}
.border-dark {
  border-color: #223035 !important;
}
.border-warning {
  border-color: #ff9700 !important;
}
.border-light {
  border-color: #e9eaea !important;
}

/* Text Colors */
.text-white {
  color: #fff !important;
}
.text-primary {
  color: var(--color-primary) !important;
}
.text-success {
  color: #15ca20 !important;
}
.text-info {
  color: #0dceec !important;
}
.text-secondary {
  color: #75808a !important;
}
.text-danger {
  color: #fd3550 !important;
}
.text-dark {
  color: #223035 !important;
}
.text-warning {
  color: #ff9700 !important;
}
.text-light {
  color: #e9eaea !important;
}
.text-dark-red {
  color: var(--color-notification-dark) !important;
}

/* Shadows */
.shadow-primary {
  box-shadow: 0 7px 30px rgba(2, 140, 253, 0.7) !important;
}
.shadow-secondary {
  box-shadow: 0 7px 30px rgba(117, 129, 137, 0.83) !important;
}
.shadow-success {
  box-shadow: 0 7px 30px rgba(21, 201, 33, 0.74) !important;
}
.shadow-danger {
  box-shadow: 0 7px 30px rgba(253, 53, 80, 0.7) !important;
}
.shadow-warning {
  box-shadow: 0 7px 30px rgba(254, 151, 1, 0.65) !important;
}
.shadow-info {
  box-shadow: 0 7px 30px rgba(13, 206, 236, 0.73) !important;
}
.shadow-light {
  box-shadow: 0 7px 30px rgba(186, 193, 193, 0.83) !important;
}
.shadow-dark {
  box-shadow: 0 7px 30px rgba(34, 48, 53, 0.81) !important;
}

////Modified for modal Body
.modal input {
  @extend %tbCommon;
}

.modal .row {
  display: flex;
}

.modal .column {
  flex: 45%;
}

.HeaderColumn {
  font-weight: bold;
  float: left;
}

.control_conatiner_style {
  margin-left: 0.5%;
  height: auto;
  min-height: 20px;
  padding: 0px 0px 0px 0px;
  float: left;
}

.controlrow_style {
  width: 98%;
  height: auto;
  min-height: 22px;
  margin: 2px 0px 0px 1%;
  float: left;
}

.lab_style_normalfont_nowrap {
  font-weight: normal;
  width: 100%;
  //font-size: $default-font-size;
  min-height: 20px;
  float: left;
  text-align: left;
  padding-left: 10px;
  margin: 0px;
  white-space: nowrap;
}

.revCrSelectedRow {
  background: #ccc !important;
  border: 2px solid #000 !important;
}

.section1 {
  float: left;
  width: 98%;
}

.width90WithBold {
  width: 90%;
  border-bottom: 1px solid #e0e0e0;
  font-weight: bold;
}

.width70 {
  width: 70%;
  color: #494949;
  font-weight: normal;
  //font-size: $default-font-size;
  text-align: left;
  white-space: nowrap;
}

.label_style1 {
  color: #494949;
  font-weight: bold;
  width: 100%;
  //font-size: $default-font-size;
  min-height: 20px;
  height: auto;
  margin-top: 0px;
  margin-left: 0px;
  white-space: normal;
  vertical-align: middle;
  text-overflow: ellipsis;
  float: left;
  text-align: left;
  word-wrap: break-word;
}

lab_style_normalfont {
  color: #494949;
  font-weight: normal;
  width: 100%;
  font-size: $other-font-size-1;
  height: 20px;
  overflow: hidden;
  white-space: normal;
  vertical-align: middle;
  text-overflow: ellipsis;
  float: left;
  text-align: left;
  margin: 0px;
  padding-left: 10px;
}

.customSize {
  @extend .modal-content;
  width: 130%;
}

h3 {
  // color: #147bc4;
  font-size: $other-font-size-4;
  padding: 5px;
  margin: 0px;
}

/* General styles for the modal */

/* 
Styles for the html/body for special modal where we want 3d effects
Note that we need a container wrapping all content on the page for the 
perspective effects (not including the modals and the overlay).
*/
.md-perspective,
.md-perspective body {
  height: 100%;
  overflow: hidden;
}

.md-perspective body {
  background: #222;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  perspective: 600px;
}

.md-modal {
  position: fixed;
  top: 50%;
  left: 43%;
  width: 50%;
  max-width: 630px;
  min-width: 320px;
  height: auto;
  z-index: 2000;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.md-show {
  visibility: visible;
}

.md-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show ~ .md-overlay {
  opacity: 1;
  visibility: visible;
}

/* Content styles */
.md-content {
  color: #fff;
  background: #e74c3c;
  position: relative;
  border-radius: 3px;
  margin: 0 auto;
}

.md-content h3 {
  margin: 0;
  padding: 0.4em;
  text-align: center;
  font-size: 2.4em;
  font-weight: 300;
  opacity: 0.8;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px 3px 0 0;
}

.md-content > div {
  padding: 15px 40px 30px;
  margin: 0;
  font-weight: 300;
  font-size: 1.15em;
}

.md-content > div p {
  margin: 0;
  padding: 10px 0;
}

.md-content > div ul {
  margin: 0;
  padding: 0 0 30px 20px;
}

.md-content > div ul li {
  padding: 5px 0;
}

.md-content button {
  display: block;
  margin: 0 auto;
  font-size: 0.8em;
}

/* Individual modal styles with animations/transitions */

/* Effect 1: Fade in and scale up */
.md-effect-1 .md-content {
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.md-effect-1 .md-content {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Effect 2: Slide from the right */
.md-effect-2 .md-content {
  -webkit-transform: translateX(20%);
  -moz-transform: translateX(20%);
  -ms-transform: translateX(20%);
  transform: translateX(20%);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  -moz-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.md-show.md-effect-2 .md-content {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

/* Effect 3: Slide from the bottom */
.md-effect-3 .md-content {
  -webkit-transform: translateY(20%);
  -moz-transform: translateY(20%);
  -ms-transform: translateY(20%);
  transform: translateY(20%);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.md-effect-3 .md-content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/* Effect 4: Newspaper */
.md-effect-4 .md-content {
  -webkit-transform: scale(0) rotate(720deg);
  -moz-transform: scale(0) rotate(720deg);
  -ms-transform: scale(0) rotate(720deg);
  transform: scale(0) rotate(720deg);
  opacity: 0;
}

.md-show.md-effect-4 ~ .md-overlay,
.md-effect-4 .md-content {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.md-show.md-effect-4 .md-content {
  -webkit-transform: scale(1) rotate(0deg);
  -moz-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  opacity: 1;
}

/* Effect 5: fall */
.md-effect-5.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.md-effect-5 .md-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(600px) rotateX(20deg);
  -moz-transform: translateZ(600px) rotateX(20deg);
  -ms-transform: translateZ(600px) rotateX(20deg);
  transform: translateZ(600px) rotateX(20deg);
  opacity: 0;
}

.md-show.md-effect-5 .md-content {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transform: translateZ(0px) rotateX(0deg);
  -moz-transform: translateZ(0px) rotateX(0deg);
  -ms-transform: translateZ(0px) rotateX(0deg);
  transform: translateZ(0px) rotateX(0deg);
  opacity: 1;
}

/* Effect 6: side fall */
.md-effect-6.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.md-effect-6 .md-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translate(30%) translateZ(600px) rotate(10deg);
  -moz-transform: translate(30%) translateZ(600px) rotate(10deg);
  -ms-transform: translate(30%) translateZ(600px) rotate(10deg);
  transform: translate(30%) translateZ(600px) rotate(10deg);
  opacity: 0;
}

.md-show.md-effect-6 .md-content {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transform: translate(0%) translateZ(0) rotate(0deg);
  -moz-transform: translate(0%) translateZ(0) rotate(0deg);
  -ms-transform: translate(0%) translateZ(0) rotate(0deg);
  transform: translate(0%) translateZ(0) rotate(0deg);
  opacity: 1;
}

/* Effect 7:  slide and stick to top */
.md-effect-7 {
  top: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.md-effect-7 .md-content {
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  transform: translateY(-200%);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.md-show.md-effect-7 .md-content {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  border-radius: 0 0 3px 3px;
  opacity: 1;
}

/* Effect 8: 3D flip horizontal */
.md-effect-8.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.md-effect-8 .md-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-70deg);
  -moz-transform: rotateY(-70deg);
  -ms-transform: rotateY(-70deg);
  transform: rotateY(-70deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.md-show.md-effect-8 .md-content {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

/* Effect 9: 3D flip vertical */
.md-effect-9.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.md-effect-9 .md-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-70deg);
  -moz-transform: rotateX(-70deg);
  -ms-transform: rotateX(-70deg);
  transform: rotateX(-70deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.md-show.md-effect-9 .md-content {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
}

/* Effect 10: 3D sign */
.md-effect-10.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.md-effect-10 .md-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-60deg);
  -moz-transform: rotateX(-60deg);
  -ms-transform: rotateX(-60deg);
  transform: rotateX(-60deg);
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  transform-origin: 50% 0;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.md-effect-10 .md-content {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
}

/* Effect 11: Super scaled */
.md-effect-11 .md-content {
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.md-effect-11 .md-content {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Effect 12:  Just me */
.md-effect-12 .md-content {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.md-effect-12 ~ .md-overlay {
  background: #e74c3c;
}

.md-effect-12 .md-content h3,
.md-effect-12 .md-content {
  background: transparent;
}

.md-show.md-effect-12 .md-content {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Effect 13: 3D slit */
.md-effect-13.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.md-effect-13 .md-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(-3000px) rotateY(90deg);
  -moz-transform: translateZ(-3000px) rotateY(90deg);
  -ms-transform: translateZ(-3000px) rotateY(90deg);
  transform: translateZ(-3000px) rotateY(90deg);
  opacity: 0;
}

.md-show.md-effect-13 .md-content {
  -webkit-animation: slit 0.7s forwards ease-out;
  -moz-animation: slit 0.7s forwards ease-out;
  animation: slit 0.7s forwards ease-out;
}

@-webkit-keyframes slit {
  50% {
    -webkit-transform: translateZ(-250px) rotateY(89deg);
    opacity: 0.5;
    -webkit-animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0deg);
    opacity: 1;
  }
}

@-moz-keyframes slit {
  50% {
    -moz-transform: translateZ(-250px) rotateY(89deg);
    opacity: 0.5;
    -moz-animation-timing-function: ease-out;
  }
  100% {
    -moz-transform: translateZ(0) rotateY(0deg);
    opacity: 1;
  }
}

@keyframes slit {
  50% {
    transform: translateZ(-250px) rotateY(89deg);
    opacity: 1;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateZ(0) rotateY(0deg);
    opacity: 1;
  }
}

/* Effect 14:  3D Rotate from bottom */
.md-effect-14.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.md-effect-14 .md-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateY(100%) rotateX(90deg);
  -moz-transform: translateY(100%) rotateX(90deg);
  -ms-transform: translateY(100%) rotateX(90deg);
  transform: translateY(100%) rotateX(90deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.md-show.md-effect-14 .md-content {
  -webkit-transform: translateY(0%) rotateX(0deg);
  -moz-transform: translateY(0%) rotateX(0deg);
  -ms-transform: translateY(0%) rotateX(0deg);
  transform: translateY(0%) rotateX(0deg);
  opacity: 1;
}

/* Effect 15:  3D Rotate in from left */
.md-effect-15.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.md-effect-15 .md-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -moz-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -ms-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.md-effect-15 .md-content {
  -webkit-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  -moz-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  -ms-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  transform: translateZ(0px) translateX(0%) rotateY(0deg);
  opacity: 1;
}

/* Effect 16:  Blur */
.md-show.md-effect-16 ~ .md-overlay {
  background: rgba(180, 46, 32, 0.5);
}

.md-show.md-effect-16 ~ .container {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  filter: blur(3px);
}

.md-effect-16 .md-content {
  -webkit-transform: translateY(-5%);
  -moz-transform: translateY(-5%);
  -ms-transform: translateY(-5%);
  transform: translateY(-5%);
  opacity: 0;
}

.md-show.md-effect-16 ~ .container,
.md-effect-16 .md-content {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.md-effect-16 .md-content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/* Effect 17:  Slide in from bottom with perspective on container */
.md-show.md-effect-17 ~ .container {
  height: 100%;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}

.md-show.md-effect-17 ~ .container,
.md-show.md-effect-17 ~ .md-overlay {
  -webkit-transform: rotateX(-2deg);
  -moz-transform: rotateX(-2deg);
  -ms-transform: rotateX(-2deg);
  transform: rotateX(-2deg);
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.md-effect-17 .md-content {
  opacity: 0;
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  -ms-transform: translateY(200%);
  transform: translateY(200%);
}

.md-show.md-effect-17 .md-content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition: all 0.3s 0.2s;
  -moz-transition: all 0.3s 0.2s;
  transition: all 0.3s 0.2s;
}

/* Effect 18:  Slide from right with perspective on container */
.md-show.md-effect-18 ~ .container {
  height: 100%;
  overflow: hidden;
}

.md-show.md-effect-18 ~ .md-overlay {
  background: rgba(143, 27, 15, 0.8);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.md-show.md-effect-18 ~ .container,
.md-show.md-effect-18 ~ .md-overlay {
  -webkit-transform-style: preserve-3d;
  -webkit-transform-origin: 0% 50%;
  -webkit-animation: rotateRightSideFirst 0.5s forwards ease-in;
  -moz-transform-style: preserve-3d;
  -moz-transform-origin: 0% 50%;
  -moz-animation: rotateRightSideFirst 0.5s forwards ease-in;
  transform-style: preserve-3d;
  transform-origin: 0% 50%;
  animation: rotateRightSideFirst 0.5s forwards ease-in;
}

@-webkit-keyframes rotateRightSideFirst {
  50% {
    -webkit-transform: translateZ(-50px) rotateY(5deg);
    -webkit-animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateZ(-200px);
  }
}

@-moz-keyframes rotateRightSideFirst {
  50% {
    -moz-transform: translateZ(-50px) rotateY(5deg);
    -moz-animation-timing-function: ease-out;
  }
  100% {
    -moz-transform: translateZ(-200px);
  }
}

@keyframes rotateRightSideFirst {
  50% {
    transform: translateZ(-50px) rotateY(5deg);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateZ(-200px);
  }
}

.md-effect-18 .md-content {
  -webkit-transform: translateX(200%);
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  transform: translateX(200%);
  opacity: 0;
}

.md-show.md-effect-18 .md-content {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  -webkit-transition: all 0.5s 0.1s;
  -moz-transition: all 0.5s 0.1s;
  transition: all 0.5s 0.1s;
}

/* Effect 19:  Slip in from the top with perspective on container */
.md-show.md-effect-19 ~ .container {
  height: 100%;
  overflow: hidden;
}

.md-show.md-effect-19 ~ .md-overlay {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.md-show.md-effect-19 ~ .container,
.md-show.md-effect-19 ~ .md-overlay {
  -webkit-transform-style: preserve-3d;
  -webkit-transform-origin: 50% 100%;
  -webkit-animation: OpenTop 0.5s forwards ease-in;
  -moz-transform-style: preserve-3d;
  -moz-transform-origin: 50% 100%;
  -moz-animation: OpenTop 0.5s forwards ease-in;
  transform-style: preserve-3d;
  transform-origin: 50% 100%;
  animation: OpenTop 0.5s forwards ease-in;
}

@-webkit-keyframes OpenTop {
  50% {
    -webkit-transform: rotateX(10deg);
    -webkit-animation-timing-function: ease-out;
  }
}

@-moz-keyframes OpenTop {
  50% {
    -moz-transform: rotateX(10deg);
    -moz-animation-timing-function: ease-out;
  }
}

@keyframes OpenTop {
  50% {
    transform: rotateX(10deg);
    animation-timing-function: ease-out;
  }
}

.md-effect-19 .md-content {
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  transform: translateY(-200%);
  opacity: 0;
}

.md-show.md-effect-19 .md-content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition: all 0.5s 0.1s;
  -moz-transition: all 0.5s 0.1s;
  transition: all 0.5s 0.1s;
}

@media screen and (max-width: 32em) {
  body {
    font-size: 75%;
  }
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

//Draggable Modal CSS
.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  min-width: 500px;
}

.react-draggable,
.cursor,
.cursorSubModal {
  cursor: move;
}
.no-cursor,
.no-cursorSubModal {
  cursor: auto;
}
.cursor-y {
  cursor: ns-resize;
}
.cursor-x {
  cursor: ew-resize;
}
.react-draggable strong {
  background: #ddd;
  border: 1px solid #999;
  border-radius: 3px;
  display: block;
  margin-bottom: 10px;
  padding: 3px 5px;
  text-align: center;
}
.box {
  background: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  width: 180px;
  height: 180px;
  margin: 10px;
  padding: 10px;
  float: left;
}

//End Draggable Modal CSS

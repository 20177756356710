@mixin dashboardRow($borderColor, $gridRow) {
  grid-row: $gridRow;
}
%dashboardRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
%dashboardRow1 {
  display: grid;
  //grid-template-columns: repeat(2, 1fr);
}
%dashboardTable {
  border-radius: 0.5rem;
  .topHeader {
    color: #fff;
    grid-column: 1/-1;
    background: var(--color-primary);
    // height: 2.5rem;
    background: linear-gradient(
      to bottom,
      var(--color-topmenu-main-light) 0%,
      var(--color-topmenu-main-dark) 44%,
      var(--color-mainmenu-2) 100%
    );
    border-radius: 5px 5px 0px 0px;
  }

  .column--head__container {
    display: grid;
    grid-auto-flow: column;
    line-height: 1;
  }
  .header_column {
    //margin-top: -8px;
    padding: 8px 4px !important;
    // background: #6fabe5;
    background: #2678c8;
    color: #fff;
    //font-size:  $default-font-size;
    font-weight: 700;
    padding: 0;
  }
  .header-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    line-height: 1;
  }
  .row:hover {
    background: none repeat scroll 0 0 #e4e4e4;
  }
  .row_column {
    // padding: 0;
    padding: 8px 4px;
  }

  .row:nth-child(even) {
    background-color: #f9f9f9;
  }
  h3 {
    margin-left: 1rem;
    color: #fff;
    //font-size:  $default-font-size;
    font-family: "EbrimaRegular", sans-serif;
    padding: 5px;
    margin: 0px;
  }
}
.dashboard {
  // display: grid;
  // grid-template-rows: repeat(2, 1fr);
  // grid-gap: 10px;
  // width: 100%;
  // height: 100%;
  &__top {
    @include dashboardRow(green, 1);
    @extend %dashboardRow1;
  }
  &__quicklink {
    grid-column: 1/2;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    column-gap: 15rem;
    row-gap: 5rem;
    //grid-auto-flow: column;
    //grid-gap: 10px;
    .cardDashboard {
      position: relative;
      align-self: center;
      text-align: center;
      border-radius: 1.5rem;
      height: 10.5rem;
      width: 10.5rem;
      cursor: pointer;
    }
    .quicklink__lbl {
      position: absolute;
      top: 11rem;
      left: -0.5rem;
      width: 12rem;
      //font-size:  $default-font-size;
      font-weight: 700;
      color: var(--color-primary);
      text-align: center;
    }
    img {
      display: inline-block;
    }

    &--card1 {
      padding: 1rem;
      justify-self: center;
    }
    &--card2 {
      justify-self: auto;
    }
    &--card3 {
      img {
        margin-top: 0.4rem;
        height: 9rem;
      }
      // padding: 1rem;
      justify-self: center;
    }

    &--card4 {
      padding: 1rem;
      justify-self: auto;
    }
  }
  &__graph {
    display: grid;
    // grid-template-rows: repeat(2, 1fr);
    // grid-template-columns: repeat(2, 1fr);
    // grid-auto-flow: column;
    // border-style: solid;
    // border-color: violet;
  }
  &__bottom {
    @include dashboardRow(green, 2);
    @extend %dashboardRow1;
    // margin: 2rem;
    // column-gap: 5rem;
  }
  &__reservationgrid {
    grid-column: 1/2;
    &--table {
      @extend %dashboardTable;
    }
  }
  &__rentalgrid {
    &--table {
      @extend %dashboardTable;
    }
  }

  #PersonAssetInTable .datagrid .row,
  #PersonAssetOutTable .datagrid .row {
    @extend %dataGridRow;
    grid-template-columns: 20% 20% 15% 25% 20%;
  }

  #PersonAssetInTable .datagrid .column,
  #PersonAssetOutTable .datagrid .column {
    @extend %dataGridColumn;
    grid-template-columns: 20% 20% 15% 25% 20%;
  }

  #PersonAssetInTable,
  #PersonAssetOutTable {
    padding: 0;
  }
}
// #MemberHistroyTable .datagrid {
//   position: unset;
// }
#MemberHistroyTable .datagrid .column {
  @extend %dataGridColumn;
  grid-template-columns: 20% 20% 15% 25% 20%;
}

#MemberHistroyTable .datagrid .row {
  @extend %dataGridRow;
  grid-template-columns: 20% 20% 15% 25% 20%;
}

#ReservationHistroyTable .datagrid .column {
  @extend %dataGridColumn;
  grid-template-columns: 18% 12% 12% 13% 15% 15% 15%;
}

#ReservationHistroyTable .datagrid .row {
  @extend %dataGridRow;
  grid-template-columns: 16% 12% 12% 15% 15% 15% 15%;
}

#RentalHistroyTable .datagrid .column {
  @extend %dataGridColumn;
  grid-template-columns: 16% 12% 12% 15% 15% 15% 15%;
}

#RentalHistroyTable .datagrid .row {
  @extend %dataGridRow;
  grid-template-columns: 16% 12% 12% 15% 15% 15% 15%;
}

.dashboard__reservationgrid--table,
.dashboard__rentalgrid--table {
  td {
    max-width: 15rem;
    white-space: nowrap;
    label {
      @extend %class-for-tooltip;
    }
  }
}

.dataNotFoundBox {
  text-align: center;
  margin-top: 5px;
  border-top: none;
  border-right: 1px solid rgb(221, 221, 221);
  border-bottom: 1px solid rgb(221, 221, 221);
  border-left: 1px solid rgb(221, 221, 221);
  border-image: initial;
  font-size: 10px !important;
}

.summary_bar {
  color: white !important;
  white-space: nowrap;
  border: 1px solid;
  border-radius: 5px;
  margin-right: -180px;
}

.summary_barLink {
  color: white;
  text-decoration: none;
}

.dashboard-graph {
  margin: 10px 10px 0 0;
  border: 1px solid var(--color-topmenu-main);
  border-radius: 5px;
  padding: 6px 0;
  position: relative;
  background-color: #fff;
  i {
    font-size: $other-font-size-5;
    color: var(--color-primary);
  }
  .graphBetween {
    text-align: center;
    font-weight: bold;
    color: var(--color-primary);
  }
}

#about-modal{
  img{
    width: 20rem;
  }
}

.res-search {
  display: inline-block;
  width: 100%;
  padding: 0.5rem 0 0.5rem 0;
  button {
    line-height: 0;
    height: 2rem;
  }
}
.searchInputTop {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 80% 10% 9%;
}
.searchInputTop__quickLink {
  a {
    color: var(--color-primary);
    text-decoration: none !important;
    font-size: $other-font-size-2;
  }
}
.searchInputTop__export {
  display: grid;
  grid-auto-flow: column;
  justify-self: end;
  grid-gap: 0.1rem;
  list-style: none;
  img {
    width: $other-font-size-5;
    height: $other-font-size-5;
  }
  .verticalLineSeparator {
    border-left: 0.1rem solid var(--color-primary);
    margin: 0.2rem 0.3rem 0 0.1rem;
    height: $default-font-size !important;
  }
  h1 {
    font-size: $default-font-size;
    font-weight: bold !important;
  }
  .replaced-svg {
    cursor: pointer;
  }
}
.search-input-container {
  border: 0.1rem solid #c8c8c8;
  padding: 1.5rem 0.5rem 0.8rem 0.5rem;
  position: relative;
  // padding-top: 2rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  &__rb {
    position: absolute;
    display: grid;
    grid-auto-flow: column;
    width: 37rem;
    padding-left: 0.5rem;
    top: -1rem;
    font-size: $other-font-size-1;
    span {
      background: #fff;
    }
  }
  .search-input-row {
    margin-bottom: 0.5rem;
  }
  .search-input-row:last-child {
    margin-bottom: 0rem;
  }
}
.serachLegend {
  position: absolute;
  right: 12.2rem;
  top: -0.8rem;
  font-size: $other-font-size-1;
  background: #fff;
  color: var(--color-primary);
  cursor: pointer;
}

.search-input-row {
  display: grid;
  grid-template-columns: 22% 22% 22% 22% 6rem;
  justify-content: space-around;
  .search-btn {
    min-width: 6rem;
    max-width: 6rem;
  }
  .rw-input {
    // min-width: 10rem !important;
  }
  .rw-list {
    font-size: 1rem;
  }
  .rw-popup {
    min-width: 10rem;
  }
  .rw-widget-picker input[type="text"] {
    padding-left: 0.5rem !important;
  }
  &__column:not(:last-child) {
    display: grid;
    grid-template-columns: 29% 58%;
    label {
      align-self: center;
    }
    height: 50%;
  }
  label {
    //text-overflow: ellipsis;
    overflow: hidden;
    //white-space: nowrap;
  }
  .dateandtimepicker {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 53% 46% 15%;
    column-gap: 2%;
  }
  .date-only-picker {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 100% 15%;
    column-gap: 2%;
  }
}
.searchFilterContainer {
  display: grid;
  grid-auto-flow: column;
  padding: 0;
  justify-items: start;
  justify-content: start;
  align-items: center;
  column-gap: 0.5rem;
  // margin-bottom: 0.5rem;
  h1 {
    line-height: 2;
    min-width: 10rem;
    font-size: $default-font-size;
  }
  .filter-link {
    //font-size:  $default-font-size;
    display: grid;
    grid-auto-flow: column;
    align-content: center;
    column-gap: 0.5rem;
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-primary);

    &.disabled {
      color: #5a5a5a;
      opacity: 0.65;
    }
  }
  .filterContainer {
    // border: #e0e0e0 solid 0.1rem;
    // border-radius: 0.8rem;
    // padding: 0.5rem;
    // padding-top: 0;
    // padding-bottom: 0;
    display: flex;
    align-items: center;
    height: 3.2rem;
  }
  .icheck-primary {
    // border: #e0e0e0 solid 0.1rem;
    border-radius: 0.8rem;
    padding: 0.5rem;
    color: #5a5a5a;
    input:first-child:disabled + label::before {
      background: #bbbbbb !important;
      border: 0.1rem solid #ccc;
    }
  }
}
.searchFilterPopup {
  &__filter {
    display: grid !important;
    grid-auto-flow: row;
    grid-template-columns: 15rem 15rem 15rem;
    margin: auto;
    .icheck-primary {
      margin: 0 !important;
    }
  }
  .filterContainer {
    // border: #e0e0e0 solid 0.1rem;
    // border-radius: 0.8rem;
    padding: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
    height: 3.2rem;
  }
  &__link {
    cursor: pointer;
    text-decoration: underline;
    font-size: $other-font-size-4;
    font-weight: bold;
  }
  .icheck-primary {
    // border: #e0e0e0 solid 0.1rem;
    // border-radius: 0.8rem;
    padding: 0.5rem;
    color: #5a5a5a;
    min-width: 9.5rem;
    margin-right: 1rem;
    input:first-child:disabled + label::before {
      background: #bbbbbb !important;
      border: 0.1rem solid #ccc;
    }
  }
  &__btn {
    text-align: right;
    :first-child {
      margin-right: 1rem;
    }
  }
}
/*FIlter Color*/
.filterlbl {
  &_Pending:before {
    background: #d36c19 !important;
  }
  &_Declined:before {
    background: #2299cf !important;
  }
  &_Message:before {
    background: #d4bfff !important;
  }
  &_Confirmed:before {
    background: #5dbd91 !important;
  }
  &_NotStarted:before {
    background: #ff0000 !important;
  }
  &_Cancelled:before {
    background: #ff6a00 !important;
  }
  &_Incomplete:before {
    background: #ffd800 !important;
  }
  &_ContractPending:before {
    background: #ff00ff !important;
  }
  &_PaymentPending:before {
    background: #6b2222 !important;
  }
  &_BgCheckPending:before {
    background: #de2f13 !important;
  }
  &_WebError:before {
    background: darkgoldenrod !important;
  }
  &_InProgress:before {
    background: #eacf12 !important;
  }
  &_Completed:before {
    background: #8dacb0 !important;
  }
  &_Late:before {
    background: #d98880 !important;
  }
  &_ReturnedLate:before {
    background: #ff00ff !important;
  }
  &_Credits:before {
    background: #999966 !important;
  }
  &_Active:before {
    background: #3a78ab !important;
  }
  &_Inactive:before {
    background: #b5b2be !important;
  }
  &_Hold:before {
    background: #0000ff !important;
  }
  &_Assigned:before {
    background: #8b3aab !important;
  }
  &_Obsolete:before {
    background: #4bb4e3 !important;
  }
  &_Open:before {
    background: rgb(75, 47, 141) !important;
  }
  &_Close:before {
    background: rgb(231, 25, 94) !important;
  }
  &_AuditOk:before {
    background: #5dbd91 !important;
  }
  &_AuditFailed:before {
    background: #de2f13 !important;
  }
  &_NotAudited:before {
    background: #4bb4e3 !important;
  }
}
.grid-table {
  margin-top: 0.5rem;
}
.pagination {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-template-columns: 15% 67% 18%;
  margin-top: 0.8rem;
  margin-bottom: 0.5rem;
  &__display {
    font-size: $default-font-size;
  }
  &__paging {
    display: grid;
    grid-auto-flow: column;
    list-style: none;
    justify-content: center;
    li {
      a {
        position: relative;
        padding: 0.5rem 1rem;
        margin-left: -0.1rem;
        line-height: 1.42857143;
        color: var(--color-primary);
        text-decoration: none;
        background-color: #fff;
        border: 0.1rem solid #ddd;
      }

      a:hover {
        color: var(--color-primary-light);
        background-color: #eee;
        border-color: #ddd;
      }
      .active {
        z-index: 3;
        color: #fff;
        cursor: default;
        background-color: var(--table-Header-1);
        border-color: var(--table--border-1);
      }
      .pg-btn--disabled {
        color: #777;
        background-color: #eee;
        border-color: #ddd;
      }
    }
  }
  &__displayperpage {
    display: grid;
    grid-auto-flow: column;
    justify-content: right;
    align-items: center;
    font-size: $default-font-size;
    .select {
      min-width: 6rem !important;
      height: 2rem !important;
      line-height: 1 !important;
    }
  }
}
.cbRadioListRow {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 35rem;
  &__column {
    display: grid;
    grid-auto-flow: column;
  }
}
.rw-widget-picker {
  height: 2rem !important;
  margin: 0 !important;
  border-radius: 0 !important;
  input[type="text"] {
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
    height: 100% !important;
    // font-size: $default-font-size !important;
    // margin-top: 0.5rem;
    line-height: 0;
    // font-family: -apple-system;
  }
  margin-bottom: 0rem !important;
  .rw-btn-select {
    height: 100% !important;
  }
}

.rw-widget {
  margin-bottom: 0rem !important;
  .rw-select-bordered {
    vertical-align: middle;
  }
}

.input-container-with-border {
  border: 1px solid #e0e0e0;
  padding: 1.5rem;
  position: relative;
  border-radius: 5px;
  &__rb {
    position: absolute;
    display: grid;
    grid-auto-flow: column;
    width: 45rem;
    top: -1.8rem;
    span {
      background: #fff;
    }
  }
}
.search-normal-row {
  display: grid;
  grid-template-columns: 30% 30% 25% 15%;
  justify-content: space-around;
  width: 1000px;
  .search-normal-btns {
    display: grid;
    grid-template-columns: 40% 40%;
    grid-gap: 5%;
  }
  .date-only-picker {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 100% 15%;
    column-gap: 2%;
  }

  .rw-widget-picker input[type="text"] {
    padding-left: 0.5rem !important;
  }
}

.periodic-filter {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;;
  .sorting-period{
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    select{
      max-width: 50px;
      min-width: 50px;
    }
  }
  .period-control{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
    span{
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      // line-height: 20px;
      white-space: nowrap;
    }
    .preiodic-button{
      font-size: 1.2rem;
      font-weight: 400;
      text-transform: none;
      transition: border 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: none;
      cursor: pointer;
      padding: 2px;
      color: #666;
      border: rgb(218,220,224);
      padding: 0.5rem 1rem;
    }
    .periodic-prev-next{
      width: 32px;
      height: 32px;
      font-size: 1.4rem;
      padding: 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      cursor: pointer;
      border: 1px solid rgb(210,227,252);;
      border-radius: 50%;
      //background: rgb(210,227,252);
      i{
        color: rgb(24,90,188);
      }
    }
    .preiodic-button:hover,.periodic-prev-next:hover{
      background: rgb(183, 209, 249);
      color: #fff;
      i{
        color: #fff;
      }
    }
  }
  .record-period{
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    select{
      max-width: 50px;
      min-width: 50px;
    }
  }
  button:disabled,.disabled{
    background-color: #e9ecef !important;
    border-color: #e9ecef !important;
    color: #cac9c9 !important;
    cursor: no-drop !important;
    i{
      color: #cac9c9 !important;
    }
  }
}

// @import url(
//   https://fonts.googleapis.com/css?family=Open+Sans:600,
//   700,
//   400,
//   300
// );

.lobibox .lobibox-btn {
  &.lobibox-btn-cancel {
    &.active,
    &:active {
      background-image: none;
    }
  }
  &.lobibox-btn-default {
    &.active,
    &:active {
      background-image: none;
    }
  }
  &.lobibox-btn-no {
    &.active,
    &:active {
      background-image: none;
    }
  }
  &.lobibox-btn-ok {
    &.active,
    &:active {
      background-image: none;
    }
  }
  &.lobibox-btn-yes {
    &.active,
    &:active {
      background-image: none;
    }
  }
}

.open > .dropdown-toggle.lobibox .lobibox-btn {
  &.lobibox-btn-cancel,
  &.lobibox-btn-default,
  &.lobibox-btn-no,
  &.lobibox-btn-ok,
  &.lobibox-btn-yes {
    background-image: none;
  }
}

.lobibox,
.lobibox-notify {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.lobibox-notify-wrapper-large.bottom,
.lobibox-notify-wrapper.bottom {
  bottom: 0;
}

.animated-super-fast {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated {
  -webkit-animation-fill-mode: both;
}

.animated-fast {
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated-slow {
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@-webkit-keyframes jumpUp {
  0% {
    -webkit-transform: translate3d(0, calc(230%), 0) scale3d(0, 1, 1);
    -webkit-animation-timing-function: ease-in;
  }

  40% {
    -webkit-transform: translate3d(0, 0, 0) scale3d(0.02, 1.1, 1);
    -webkit-animation-timing-function: ease-out;
  }

  70% {
    -webkit-transform: translate3d(0, -40px, 0) scale3d(0.8, 1.1, 1);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}

@keyframes jumpUp {
  0% {
    transform: translate3d(0, calc(230%), 0) scale3d(0, 1, 1);
    animation-timing-function: ease-in;
  }

  40% {
    transform: translate3d(0, 0, 0) scale3d(0.02, 1.1, 1);
    animation-timing-function: ease-out;
  }

  70% {
    transform: translate3d(0, -40px, 0) scale3d(0.8, 1.1, 1);
  }

  100% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}

@-webkit-keyframes expandOpen {
  0% {
    -webkit-transform: scale(1.8);
  }

  50% {
    -webkit-transform: scale(0.95);
  }

  80% {
    -webkit-transform: scale(1.05);
  }

  90% {
    -webkit-transform: scale(0.98);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes expandOpen {
  0% {
    transform: scale(1.8);
  }

  50% {
    transform: scale(0.95);
  }

  80% {
    transform: scale(1.05);
  }

  90% {
    transform: scale(0.98);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fadeInScale {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInScale {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -ms-transform: scale(0.3);
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }

  100% {
    opacity: 0;
  }
}

@keyframes zoomOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -ms-transform: scale(0.3);
    transform: scale(0.3);
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

.jumpUp {
  -webkit-animation-name: jumpUp;
  animation-name: jumpUp;
}

.expandOpen {
  animation-name: expandOpen;
  -webkit-animation-name: expandOpen;
}

.fadeInScale {
  animation-name: fadeInScale;
  -webkit-animation-name: fadeInScale;
}

body.lobibox-open {
  overflow: hidden;
}

.lobibox {
  position: fixed;
  z-index: 4001;
  -webkit-box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.5);
  * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .lobibox-header {
    font-size: 20px;
    padding: 5px 10px;
    color: #eee;
    .btn-close {
      float: right;
      background-color: transparent;
      cursor: pointer;
      border: none;
      outline: 0;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      &:hover {
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.7);
      }
    }
  }
  .lobibox-body {
    overflow: hidden;
    display: table;
    position: relative;
    width: 100%;
    padding: 15px 20px;
    background-color: rgba(255, 255, 255, 0.98);
    font-size: 16px;
    .lobibox-icon-wrapper {
      position: relative;
      height: 100%;
      display: table;
      font-size: 60px;
      .lobibox-icon {
        display: table-cell;
        vertical-align: middle;
      }
    }
    .lobibox-body-text-wrapper {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      padding-left: 10px;
    }
  }
  .lobibox-footer {
    text-align: center;
    padding: 6px;
    > * {
      margin: 0 10px 0 0;
    }
    &.text-center {
      text-align: center;
    }
    &.text-left {
      text-align: left;
    }
    &.text-right {
      text-align: right;
    }
  }
  &.lobibox-confirm {
    border: none;
    .lobibox-header {
      color: #eee;
      background-color: #3c2d2d;
    }
    .lobibox-body .lobibox-icon {
      color: #3c2d2d;
    }
    .lobibox-footer {
      background-color: #594343;
    }
  }
  &.lobibox-success {
    border: 1px solid #29b87e;
    .lobibox-header {
      color: #eee;
      background-color: #29b87e;
    }
    .lobibox-body .lobibox-icon {
      color: #29b87e;
    }
    .lobibox-footer {
      background-color: #40d498;
    }
  }
  &.lobibox-error {
    border: 1px solid #ca2121;
    .lobibox-header {
      color: #eee;
      background-color: #ca2121;
    }
    .lobibox-body .lobibox-icon {
      color: #ca2121;
    }
    .lobibox-footer {
      background-color: #e03e3e;
    }
  }
  &.lobibox-info {
    border: 1px solid #2e79b4;
    .lobibox-header {
      color: #eee;
      background-color: #2e79b4;
    }
    .lobibox-body .lobibox-icon {
      color: #2e79b4;
    }
    .lobibox-footer {
      background-color: #4593d0;
    }
  }
  &.lobibox-warning {
    border: 1px solid #ce812e;
    .lobibox-header {
      color: #eee;
      background-color: #ce812e;
    }
    .lobibox-body .lobibox-icon {
      color: #ce812e;
    }
    .lobibox-footer {
      background-color: #d99a56;
    }
  }
  &.lobibox-prompt {
    border: none;
    .lobibox-header {
      color: #eee;
      background-color: #3c2d2d;
    }
    .lobibox-body {
      padding: 20px;
      .lobibox-input {
        min-height: 38px;
        border: 1px solid #21cb91;
        width: 100%;
        padding: 5px;
        font-size: 18px;
        outline: 0;
        &:focus {
          background-color: #eee;
        }
        &.invalid {
          border-color: #dc2b2a;
        }
      }
      .lobibox-input-error-message {
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 13px;
        color: #dc2b2a;
      }
    }
    .lobibox-footer {
      background-color: #594343;
    }
  }
  &.lobibox-progress {
    .lobibox-header {
      background-color: #2f5d6d;
    }
    .lobibox-body {
      padding: 15px;
      font-size: 16px;
      .lobibox-progress-bar-wrapper {
        position: relative;
        height: 20px;
        border: 1px solid #c3c3c3;
        border-radius: 10px;
        background-color: #d5d5d5;
        .lobibox-progress-bar {
          width: 0;
          border-radius: 10px;
          background-color: #468ba2;
          height: 100%;
          text-align: center;
        }
        .lobibox-progress-text {
          position: absolute;
          text-align: center;
          top: 0;
          width: 100%;
        }
      }
      .lobibox-progress-outer {
        margin-bottom: 0;
        .progress-bar {
          transition: none;
        }
        [data-role="progress-text"] {
          font-weight: 700;
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
  &.lobibox-window {
    border: 3px solid #225eb8;
    border-radius: 6px;
    .lobibox-header {
      background-color: #225eb8;
      color: #eee;
      font-size: 18px;
    }
    .lobibox-body {
      overflow: auto;
      display: block;
      font-size: 14px;
      padding: 15px;
      background-color: #f5f8fd;
    }
    .lobibox-footer {
      background-color: #8ab0e9;
    }
    :last-child {
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
  &.draggable .lobibox-header {
    cursor: move;
  }
  .lobibox-btn {
    display: inline-block;
    padding: 8px 14px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 2px;
    line-height: initial;
    &.lobibox-btn-cancel {
      color: #fff;
      background-color: #ca2121;
      border-color: #ca2121;
      &.active,
      &.focus,
      &:active,
      &:focus,
      &:hover {
        color: #fff;
        background-color: #9e1a1a;
        border-color: #951818;
      }
    }
  }
}

.open > .dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-cancel {
  color: #fff;
  background-color: #9e1a1a;
  border-color: #951818;
}

.lobibox .lobibox-btn.lobibox-btn-cancel {
  &.disabled {
    background-color: #ca2121;
    border-color: #ca2121;
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background-color: #ca2121;
      border-color: #ca2121;
    }
  }
  &[disabled] {
    background-color: #ca2121;
    border-color: #ca2121;
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background-color: #ca2121;
      border-color: #ca2121;
    }
  }
}

fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-cancel {
  background-color: #ca2121;
  border-color: #ca2121;
  &.active,
  &.focus,
  &:active,
  &:focus,
  &:hover {
    background-color: #ca2121;
    border-color: #ca2121;
  }
}

.lobibox .lobibox-btn {
  &.lobibox-btn-cancel .badge {
    color: #ca2121;
    background-color: #fff;
  }
  &.lobibox-btn-yes {
    color: #fff;
    background-color: #29b87e;
    border-color: #29b87e;
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      color: #fff;
      background-color: #208e61;
      border-color: #1e865c;
    }
  }
}

.open > .dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-yes {
  color: #fff;
  background-color: #208e61;
  border-color: #1e865c;
}

.lobibox .lobibox-btn.lobibox-btn-yes {
  &.disabled {
    background-color: #29b87e;
    border-color: #29b87e;
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background-color: #29b87e;
      border-color: #29b87e;
    }
  }
  &[disabled] {
    background-color: #29b87e;
    border-color: #29b87e;
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background-color: #29b87e;
      border-color: #29b87e;
    }
  }
}

fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-yes {
  background-color: #29b87e;
  border-color: #29b87e;
  &.active,
  &.focus,
  &:active,
  &:focus,
  &:hover {
    background-color: #29b87e;
    border-color: #29b87e;
  }
}

.lobibox .lobibox-btn {
  &.lobibox-btn-yes .badge {
    color: #29b87e;
    background-color: #fff;
  }
  &.lobibox-btn-no {
    color: #fff;
    background-color: #0760b3;
    border-color: #0760b3;
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      color: #fff;
      background-color: #054682;
      border-color: #054078;
    }
  }
}

.open > .dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-no {
  color: #fff;
  background-color: #054682;
  border-color: #054078;
}

.lobibox .lobibox-btn.lobibox-btn-no {
  &.disabled {
    background-color: #0760b3;
    border-color: #0760b3;
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background-color: #0760b3;
      border-color: #0760b3;
    }
  }
  &[disabled] {
    background-color: #0760b3;
    border-color: #0760b3;
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background-color: #0760b3;
      border-color: #0760b3;
    }
  }
}

fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-no {
  background-color: #0760b3;
  border-color: #0760b3;
  &.active,
  &.focus,
  &:active,
  &:focus,
  &:hover {
    background-color: #0760b3;
    border-color: #0760b3;
  }
}

.lobibox .lobibox-btn {
  &.lobibox-btn-no .badge {
    color: #0760b3;
    background-color: #fff;
  }
  &.lobibox-btn-ok {
    color: #fff;
    background-color: #0760b3;
    border-color: #0760b3;
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      color: #fff;
      background-color: #054682;
      border-color: #054078;
    }
  }
}

.open > .dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-ok {
  color: #fff;
  background-color: #054682;
  border-color: #054078;
}

.lobibox .lobibox-btn.lobibox-btn-ok {
  &.disabled {
    background-color: #0760b3;
    border-color: #0760b3;
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background-color: #0760b3;
      border-color: #0760b3;
    }
  }
  &[disabled] {
    background-color: #0760b3;
    border-color: #0760b3;
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background-color: #0760b3;
      border-color: #0760b3;
    }
  }
}

fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-ok {
  background-color: #0760b3;
  border-color: #0760b3;
  &.active,
  &.focus,
  &:active,
  &:focus,
  &:hover {
    background-color: #0760b3;
    border-color: #0760b3;
  }
}

.lobibox .lobibox-btn {
  &.lobibox-btn-ok .badge {
    color: #0760b3;
    background-color: #fff;
  }
  &.lobibox-btn-default {
    color: #000;
    background-color: #e2e2e2;
    border-color: #dadada;
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      color: #000;
      background-color: #c9c9c9;
      border-color: #bcbcbc;
    }
  }
}

.open > .dropdown-toggle.lobibox .lobibox-btn.lobibox-btn-default {
  color: #000;
  background-color: #c9c9c9;
  border-color: #bcbcbc;
}

.lobibox .lobibox-btn.lobibox-btn-default {
  &.disabled {
    background-color: #e2e2e2;
    border-color: #dadada;
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background-color: #e2e2e2;
      border-color: #dadada;
    }
  }
  &[disabled] {
    background-color: #e2e2e2;
    border-color: #dadada;
    &.active,
    &.focus,
    &:active,
    &:focus,
    &:hover {
      background-color: #e2e2e2;
      border-color: #dadada;
    }
  }
}

fieldset[disabled] .lobibox .lobibox-btn.lobibox-btn-default {
  background-color: #e2e2e2;
  border-color: #dadada;
  &.active,
  &.focus,
  &:active,
  &:focus,
  &:hover {
    background-color: #e2e2e2;
    border-color: #dadada;
  }
}

.lobibox {
  .lobibox-btn.lobibox-btn-default .badge {
    color: #e2e2e2;
    background-color: #000;
  }
  &.lobibox-hidden {
    display: none;
  }
}

.lobibox-backdrop {
  position: fixed;
  z-index: 4000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.lobibox-notify-wrapper,
.lobibox-notify-wrapper-large {
  z-index: 5000;
  position: fixed;
}

.lobibox-notify-wrapper {
  max-width: 30rem;
  min-width: 20rem;
  &.v_top {
    top: 45%;
  }
  &.top {
    top: 0;
  }
  &.left {
    left: 0;
    margin-right: 0;
  }
  &.right {
    right: 0;
    margin-left: 0;
    .lobibox-notify {
      margin-left: auto;
    }
  }
  &.center {
    left: 43%;
  }
}

.lobibox-notify-wrapper-large {
  &.top {
    top: 0;
  }
  &.left {
    left: 0;
    .lb-notify-tabs > li {
      float: left;
      margin-left: 0;
      margin-right: 2px;
    }
  }
  &.right {
    right: 0;
  }
  .lb-notify-tabs {
    list-style: none;
    padding: 0;
    margin: 0 0 -5px;
    > li {
      float: right;
      margin-left: 2px;
      > a {
        text-align: center;
        display: table;
        text-decoration: none;
        font-size: 18px;
        height: 32px;
        color: #fff;
        width: 28px;
        opacity: 0.6;
        &:active,
        &:focus {
          color: #fff;
        }
        &:hover {
          color: #fff;
          &:active {
            color: #fff;
          }
        }
        .tab-control-icon {
          display: table-cell;
          vertical-align: middle;
        }
      }
      &.lobibox-notify-default > a {
        background-color: rgba(28, 28, 28, 0.9);
        border-color: #141414;
        &:hover {
          background-color: #1c1c1c;
          border-color: #0f0f0f;
        }
      }
      &.lobibox-notify-error > a {
        background-color: rgba(202, 33, 33, 0.9);
        border-color: #bd1f1f;
        &:hover {
          background-color: #ca2121;
          border-color: #b41d1d;
        }
      }
      &.lobibox-notify-success > a {
        background-color: rgba(41, 184, 126, 0.9);
        border-color: #26ab75;
        &:hover {
          background-color: #29b87e;
          border-color: #24a370;
        }
      }
      &.lobibox-notify-warning > a {
        background-color: rgba(206, 129, 46, 0.9);
        border-color: #c1792b;
        &:hover {
          background-color: #ce812e;
          border-color: #b97429;
        }
      }
      &.lobibox-notify-info > a {
        background-color: rgba(46, 121, 180, 0.9);
        border-color: #2b71a8;
        &:hover {
          background-color: #2e79b4;
          border-color: #296ba0;
        }
      }
      &.active > a {
        opacity: 1;
      }
    }
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }
  .lb-notify-wrapper {
    background-color: transparent;
    padding: 0;
    border: none;
    .lb-tab-pane {
      display: none;
      &.active {
        display: block;
      }
    }
    .lobibox-notify {
      min-height: 150px;
      .lobibox-notify-icon-wrapper {
        width: 100px;
      }
      .lobibox-notify-icon > div .icon-el {
        font-size: 78px;
      }
      .lobibox-notify-body {
        margin: 13px 20px 13px 130px;
      }
    }
  }
}

.lobibox-notify {
  position: relative;
  min-height: 85px;
  font-size: 14px;
  margin: 7px 0;
  border-radius: 0;
  border: 1px solid transparent;
  -webkit-box-shadow: 2px 2px 5px #aaa;
  box-shadow: 2px 2px 5px #aaa;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  min-width: 32rem;
  .lobibox-notify-icon-wrapper {
    position: absolute;
    left: 15px;
    width: 60px;
    height: 100%;
  }
  .lobibox-notify-icon {
    display: table;
    width: 100%;
    height: 100%;
    > div {
      display: table-cell;
      vertical-align: middle;
      > img {
        width: 100%;
        max-width: 100%;
        margin-top: 3px;
        border-radius: 4px;
      }
      .icon-el {
        text-align: center;
        font-size: 55px;
      }
    }
  }
  .lobibox-notify-body {
    margin: 10px 20px 10px 90px;
  }
  .lobibox-notify-title {
    font-size: 20px;
  }
  .lobibox-notify-msg {
    overflow: hidden;
  }
  .lobibox-close {
    position: absolute;
    text-align: center;
    border-radius: 50%;
    right: 10px;
    top: 10px;
    font-size: 20px;
    line-height: 19px;
    width: 19px;
    height: 19px;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
      font-weight: 700;
    }
  }
  .lobibox-delay-indicator {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    > div {
      position: relative;
      height: 100%;
      width: 0;
      background-color: #e8e8e8;
    }
  }
  &.lobibox-notify-default {
    border-color: #223035;
    background-color: #223035;
    color: #fff;
    &:hover {
      background-color: #223035;
      border-color: #fff;
    }
    .lobibox-close:hover {
      background-color: #4f4f4f;
    }
  }
  // &.lobibox-notify-error {
  //   border-color: #fd3550;
  //   background-color: #fd3550;
  //   color: #fff;
  //   &:hover {
  //     background-color: #fd3550;
  //     border-color: #fff;
  //   }
  // }
  // &.lobibox-notify-success {
  //   border-color: #15ca20;
  //   background-color: #15ca20;
  //   color: #fff;
  //   &:hover {
  //     background-color: #15ca20;
  //     border-color: #fff;
  //   }
  // }
  // &.lobibox-notify-warning {
  //   border-color: #ff9700;
  //   background-color: #ff9700;
  //   color: #fff;
  //   &:hover {
  //     background-color: #ff9700;
  //     border-color: #fff;
  //   }
  // }
  // &.lobibox-notify-info {
  //   border-color: #0dceec;
  //   background-color: #0dceec;
  //   color: #fff;
  //   &:hover {
  //     background-color: #0dceec;
  //     border-color: #fff;
  //   }
  // }

  &.lobibox-notify-error {
    font-weight: normal;
    border: 0.7rem solid #fd3550;
    border-radius: 1rem;
    background-color: #eaeaea;
    color: #333;
    i {
      color: #fd3550;
      font-size: 3rem;
    }
    // &:hover {
    //   background-color: #fd3550;
    //   border-color: #fff;
    // }
  }
  &.lobibox-notify-success {
    border: 0.7rem solid #333;
    border-radius: 1rem;
    background-color: #ffffff;
    color: #333;
    i {
      color: #2a8947;
      font-size: 3rem;
    }
    // &:hover {
    //   background-color: #15ca20;
    //   border-color: #fff;
    // }
  }
  &.lobibox-notify-warning {
    border: 0.7rem solid #ff9700;
    border-radius: 1rem;
    background-color: #eaeaea;
    color: #333;
    i {
      color: #ff9700;
    }
    // &:hover {
    //   background-color: #ff9700;
    //   border-color: #fff;
    // }
  }
  &.lobibox-notify-info {
    border: 0.7rem solid #0dceec;
    border-radius: 1rem;
    background-color: #eaeaea;
    color: #333;
    i {
      color: #0dceec;
    }
    // &:hover {
    //   background-color: #0dceec;
    //   border-color: #fff;
    // }
  }

  &.rounded {
    border-radius: 1rem;
  }
  &:hover {
    cursor: pointer;
    -webkit-box-shadow: 3px 3px 10px #aaa;
    box-shadow: 3px 3px 10px #aaa;
  }
  &.notify-mini {
    min-height: 36px;
    .lobibox-notify-title {
      margin-top: -5px;
      font-size: 20px;
      line-height: 22px;
    }
    .lobibox-notify-msg {
      font-weight: normal;
      line-height: 16px;
    }
    .lobibox-notify-icon-wrapper {
      left: 12px;
      width: 32px;
    }
    .lobibox-notify-icon > div .icon-el {
      font-size: 32px;
    }
    .lobibox-notify-body {
      margin: 15px 30px 15px 56px;
    }
  }
  &.without-icon .lobibox-notify-body {
    margin-left: 20px;
  }
}

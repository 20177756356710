#defaultRate {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 0px !important;
    padding-left: 10px !important;
  }
}

#specialPriceAddNew,
#exportItemTable {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.increaseEnable {
  color: #2d78b2;
  cursor: pointer;
  margin-left: -1.2rem;
}

.decreaseEnable {
  color: #f7535d;
  cursor: pointer;
  margin-right: -1rem;
}

.minusDateEnable {
  font-size: $other-font-size-5;
  color: #f7535d;
  padding: 0rem 0rem 0rem 0.5rem;
  cursor: pointer;
}

.addDateEnable {
  font-size: $other-font-size-5;
  color: #2d78b2;
  padding: 0rem 1rem 0rem 0rem;
  cursor: pointer;
}

.minusDateDisable {
  font-size: $other-font-size-5;
  padding: 0rem 0rem 0rem 0.5rem;
  color: var(--color-grey-dark-2);
  cursor: default;
}

.addDateDisable {
  font-size: $other-font-size-5;
  padding: 0rem 1rem 0rem 0rem;
  color: var(--color-grey-dark-2);
  cursor: default;
}

#pricingListTable .datagrid {
  .row {
    @extend %dataGridRow;
    text-align: center;
    grid-template-columns: 3% 11% 3% 3% 11% 3% 1% 1% 1% 7% 1% 1% 1% 1% 1% 1% 7% 1% 1% 1% 1% 1% 1% 7% 1% 1% 1% 1% 1% 1% 7% 1% 1% 1% 1% 1% 1% 7% 1% 1% 1% 5%;
  }

  .column {
    @extend %dataGridColumn;
    text-align: center;
    grid-template-columns: 17% 17% 13% 13% 13% 13% 13% 5%;
  }

  .view-only-row {
    @extend %dataGridRow;
    text-align: center;
    grid-template-columns: 17% 17% 13% 13% 13% 13% 13% 5%;
    border: none;
  }

  .row__column {
    padding: 0.8rem 0.4rem;
    text-align: left;
    text-overflow: ellipsis;
    overflow: initial !important;
    white-space: nowrap;
    border-right: 1px solid #fff;
  }
}

#specialPriceAddNew .datagrid {
  .column {
    @extend %dataGridColumn;
    grid-template-columns: 17% 17% 13% 13% 13% 13% 13% 5%;
  }
  .row {
    @extend %dataGridColumn;
    grid-template-columns: 17% 17% 13% 13% 13% 13% 13% 5%;
  }
}

#vehicleSettingMinimumRental .datagrid .row {
  @extend %dataGridColumn;
  grid-template-columns: 7% 16% 5% 12% 5% 12% 12% 8% 5% 8% 5% 2% 2%;
}

#VehicleSettingOfflineList .datagrid .row {
  @extend %dataGridColumn;
  grid-template-columns: 7% 16% 5% 12% 5% 12% 12% 2% 2%;
}

#exportItemTable {
  .datagrid .column {
    @extend %dataGridColumn;
    grid-template-columns: 10% 50%;
  }
  .datagrid .row {
    @extend %dataGridRow;
    grid-template-columns: 10% 45% 45%;
  }
  .checkBoxSelectAll {
    border: 1px solid #000;
    display: inline-block;
    padding: 0px;
    margin: 0px 0px 0px -1.5rem;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
  }
  .checkBoxSelectAll {
    background: #fff;
    color: #000;
  }
  .checkBoxSelectAll.checked {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: #fff;
  }

  .row__column {
    font-size: $other-font-size-1;
    font-weight: bold;
    input {
      font-size: $other-font-size-0;
      font-weight: bold;
    }
    .icheck-primary input {
      width: 0px;
    }
  }
}
#exportItemTable,
#specialPriceAddNew,
#pricingListTable {
  .datagrid .column__header,
  .datagrid .column__header_popup {
    padding: 0.7rem 0.4rem !important;
    background: transparent;
    color: #000;
    font-weight: 700;
    padding: 0;
    border-right: 0px solid #fff;
    position: relative;
  }

  .datagrid .row {
    display: grid;
    grid-auto-flow: column;
    border: none;
    -webkit-align-items: center;
    align-items: center;
    line-height: 1;
  }

  .datagrid .row__column {
    padding: 0.1rem 0.4rem;
    text-align: left;
    text-overflow: ellipsis;
    overflow: inherit;
    white-space: nowrap;
    border-right: 1px solid #fff;
  }
  .datagrid .row {
    background-color: transparent !important;
  }
}

#exportItemTable {
  .checkAllText {
    text-align: left !important;
    font-size: 1.5rem;
    padding-top: $other-font-size-2 !important;
    padding: 0.8rem 1rem 0rem 0rem !important;
    font-weight: bold;
  }
  .header-text {
    padding-top: 0 !important;
  }
  .datagrid .column__header {
    padding: 0rem 0.4rem 0.8rem 1.5rem !important;
  }
}

.icheck-primary .inheritedCheckBoxLabel {
  display: block;
  font-size: $other-font-size-3;
  //margin-block-start: 1em;
  font-weight: bold;
  width: 25rem !important;
  span {
    padding-left: 0.3rem;
  }
}

.settings-top-location {
  justify-content: left;
  margin-bottom: 0;
  label {
    font-size: $other-font-size-2;
    color: var(--color-primary);
  }
  select {
    border: 0.1rem solid var(--color-primary);
    font-size: $other-font-size-3;
    min-width: 20rem;
  }
}

#orgSettingPanel {
  .padding-settings-5 {
    padding-left: 0.6rem !important;
    padding-top: 0.175rem !important;
  }
  #main-settings .form-group {
    height: 2rem;
  }
  .rw-widget-picker input[type="text"] {
    padding-left: 0.5rem !important;
  }
  .settingFooter {
    border-top: 0.1rem solid #d3dce9;
    padding: 0.7rem 0 0px 0px;
    margin: 5px 0px -5px 0px;
    text-align: center !important;
    button {
      font-size: $other-font-size-2;
      width: 10rem;
    }
  }

  select {
    padding-left: 0rem !important;
  }

  fieldset {
    border: none;
  }

  fieldset:disabled {
    i {
      pointer-events: none;
      color: var(--color-grey-dark-2) !important;
    }
  }
  overflow: hidden;
  table tr {
    line-height: 1.4rem;

    td {
      max-width: 16rem;
      .tt-container {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        div {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .icheck-primary {
        display: contents;
      }
    }
  }

  #hours-of-operation {
    table tr td {
      min-width: 10rem !important;
    }
    .underLine {
      margin-bottom: 1rem;
      border-bottom: 1px solid #d4d7da;
      padding-bottom: 0.2rem;
    }
  }

  #insurance-settings {
    .form-group {
      height: 2rem;
    }
    table {
      .thead-primary {
        th[colspan] {
          border-bottom: 1px solid #dee2e6;
        }
        th:nth-child(1) {
          width: 6% !important;
        }
        th:last-child {
          border-bottom: none !important;
          width: 6% !important;
        }
        tr.table-sub-header {
          th {
            width: 6% !important;
          }
          th:nth-child(1) {
            border-left: none !important;
          }
        }
      }
    }
    #liability-fee-table {
      .thead-primary tr.table-top-header th:nth-child(2) {
        width: 40% !important;
      }
    }

    #cdw-fee-table {
      .thead-primary tr.table-top-header {
        th:nth-child(2) {
          width: 34% !important;
        }
        th:nth-child(3) {
          width: 6% !important;
        }
      }
    }

    #age-based-fee-table {
      .thead-primary tr.table-top-header {
        th:nth-child(2),
        th:nth-child(3) {
          width: 5% !important;
        }
        th:nth-child(4) {
          width: 7% !important;
        }
        th:nth-child(5) {
          width: 23% !important;
        }
      }
    }
  }

  .no-setting-record-found {
    font-size: 14px;
    font-weight: normal;
  }

  .asterisk:after {
    font-weight: bold;
    color: red;
    content: "*";
    position: absolute;
    font-size: $other-font-size-4;
    top: 0.1rem;
  }

  .verticallyAligned {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .default-rates {
    th {
      text-align: left;
    }
    td {
      &:nth-child(2) {
        border-left: 1px solid #dee2e6 !important;
      }
      label {
        line-height: 2rem;
        text-align: left !important;
      }
      min-width: 100px !important;
      text-align: left !important;
    }
    .atrribute-head {
      border: none !important;
      background: #fff !important;
    }
    .atrribute-cell {
      border: none !important;
      background: #fff !important;
      width: 140px !important;
      text-align: right !important;
    }
  }
  .special-rate-table {
    tr td {
      overflow: visible !important;
    }
    .rw-calendar-row {
      background-color: #fff !important;
    }
    .rw-head-cell {
      padding: 0.25em;
      color: #333 !important;
    }
    .rw-calendar-month .rw-cell {
      border-bottom: none;
    }

    .sp-input-ctl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
      .icon-div {
        display: grid;
        grid-auto-flow: column;
        align-items: baseline;
        grid-gap: 0.2rem;

        .down {
          color: #fd3550;
        }
        .up {
          color: var(--color-primary);
        }
      }
      .icon-disabled {
        color: gray !important;
        pointer-events: none !important;
      }
    }

    .date-ctl {
      justify-content: space-evenly;
    }
  }
  #permissionTable .tt-container {
    white-space: normal !important;
  }
}

#inherit-modal {
  .table-container {
    padding-right: 0px !important;
  }

  table tr {
    line-height: 1.4rem;

    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 16rem;

      .icheck-primary {
        display: contents;
      }
    }
  }

  .modal-body {
    padding: 1rem 2rem 1rem 2rem;
  }
}

#partner-list-id {
  height: max-content;
  align-self: center;
  border: 0.1rem solid var(--color-primary-light-1);
  border-radius: 0.5rem;
  padding-right: 0 !important;
  box-shadow: 1rem 1rem 3rem -1.2rem rgba(0, 0, 0, 0.42),
    0rem 0.4rem 2.5rem -1.2rem rgba(0, 0, 0, 0.12),
    0rem 0.8rem 1rem -1.2rem rgba(0, 0, 0, 0.2);
  padding-left: 0px !important;
  // next one line is to hide scrollbar in Chrome and set it to parent tag
  overflow: hidden;
  .line-clamp {
    word-break: break-all !important;
  }
  .tableScroll {
    // next one line is to hide scrollbar in other browsers
    scrollbar-width: none !important;
    max-height: 46rem !important;
    height: 75vh !important;
    // next four lines is to hide scrollbar in Chrome, start
    width: 100%;
    overflow-y: scroll;
    padding-right: 5px;
    box-sizing: content-box;
  }
  .scroll-up-arrow {
    text-align: center;
    // border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    // margin-top: 1.5rem;
    // padding: 0.3rem;
    background-color: var(--color-grey-light-4);
    &.pseudo-link {
      color: gray !important;
    }
    &.disabledLinkProp {
      color: lightgray !important;
      background-color: antiquewhite;
    }
  }
  .scroll-down-arrow {
    text-align: center;
    border-top: 1px solid #ccc;
    border-bottom: none !important;
    margin-bottom: -10px !important;
    padding-top: 0 !important;
    background-color: var(--color-grey-light-4);
    &.pseudo-link {
      color: gray !important;
    }
    &.disabledLinkProp {
      color: lightgray !important;
      background-color: antiquewhite;
    }
  }
  hr {
    margin-top: 1rem;
    margin-bottom: 0rem;
  }
  .grid-table {
    .datagrid {
      .row:hover {
        background: none !important;
        box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
          0 7px 10px -5px rgba(255, 255, 255, 0.4);
      }
      .row.selected-row-color:hover {
        color: #fff !important;
        background: var(--color-topmenu-main-dark) !important;
      }

      .row {
        border-left: none !important;
        border-right: none;
        background-color: inherit;
        color: var(--color-primary);
        line-height: inherit;
        .row__column {
          @extend .line-clamp;
          white-space: normal !important;
          padding: 0rem 0.7rem !important;
          border-right: none !important;
        }
        padding: 0.5rem 0 !important;
        width: 102%;
      }
      .row:first-child {
        border-top: none !important;
        border-radius: 0rem 0rem 0rem 0rem;
      }
      .row:last-child {
        border-bottom: 1px solid #ccc !important;
        border-radius: 0rem 0rem 0rem 0rem;
      }
    }
    .selected-row-color {
      color: #fff !important;
      background: var(--color-topmenu-main-dark) !important;
      box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
        0 7px 10px -5px rgba(60, 72, 88, 0.4);
      border-left: 5px solid #000;
    }
  }
}
#partner-info-details {
  border: 0.1rem solid #ccc;
  border-radius: 0.5rem;
  .form-header {
    font-weight: normal;
  }
  height: 70rem;
}

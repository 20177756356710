.common-res-container {
  .car-class {
    background: #e6e9ef !important;
  }
  .attr-bg3 {
    background: #fff4b0 !important;
    color: #001e60 !important;
    font-weight: 700;
    border-radius: 3rem !important;
  }
  .attr-lbl {
    padding: 0.3rem 0.6rem;
    border-radius: 1rem;
    color: #585858;
    background: #f1f0f0;
  }
  .vehicle-info-container {
    display: flex;
    justify-content: flex-start;
    column-gap: 1rem;
    margin-bottom: 1rem;
    .v-img {
      width: 7.4rem;
      object-fit: contain;
    }
    .vehicle-information {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      .attr-container {
        display: flex;
        column-gap: 0.5rem;
      }
      .feature-attr-container {
        display: flex;
        column-gap: 1rem;
        justify-content: flex-start;
        .feature-attr {
          display: flex;
          column-gap: 0.3rem;
        }
        .btn-more {
          color: #001e60;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .old-rate {
    background: #f8f8f8;
    border: 1px solid #f1f1f1;
  }
  .new-rate {
    background: #ffffff;
    border: 1px solid #f1f1f1;
  }
  .selected-rate {
    background: #ffffff;
    border: 1px solid #001e60;
  }
  .vehicle-total-container {
    border-radius: 1.2rem;
    padding: 1rem 0.8rem;
    // margin-top: 1rem;
    &:hover {
      border-color: #001e60 !important;
      .vehicle-reserve-btn {
        background: var(--btn-fox-yellow) !important;
        cursor: pointer;
      }
    }
    .vehicle-title-attr {
      display: flex;
      column-gap: 0.5rem;
    }
    .rate-summary {
      display: flex;
      column-gap: 0.5rem;
      margin-top: 1.2rem;
    }
    button {
      right: 0;
      top: -0.7rem;
      cursor: pointer;
    }
  }
}
.vehicle-tag-modal {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  top: 2rem;
  right: 0rem;
  .attr-lbl {
    border: 1px solid #f1f1f1;
    background-color: #ffffff;
  }
}

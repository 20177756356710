#activityTable .datagrid {
  .row {
    grid-template-columns: 6% 10% 10% 10% 6% 6% 8% 6% 6% 6% 26%;
  }
  .column {
    grid-template-columns: 6% 10% 10% 10% 6% 6% 8% 6% 6% 6% 26%;
  }
  .formRow {
    .col-sm-12 {
      padding-bottom: 0.5rem;
      margin-left: 0.1rem;
      padding-left: 0rem;
    }
    .col-sm-4 {
      padding-left: 0rem;
    }
    .col-sm-7 {
      padding-left: 0rem;
    }
  }

  .row__column {
    label,
    a,
    span {
      @extend %class-for-tooltip;
    }
    // span:not(> i) {
    //   overflow: initial !important;
    // }
  }
}

.actExpandIco,
.actCopyIco,
.actExpandIcoNormal {
  float: left;
  margin-right: 3px;
  color: #147bc6;
  font-weight: bold;
  font-size: $other-font-size-5;
  cursor: pointer;
}

.actExpandIcoDisabled {
  float: left;
  margin-right: 3px;
  color: #ccc;
  font-weight: bold;
  font-size: $other-font-size-5;
  pointer-events: none;
}

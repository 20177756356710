#billingTable,
#billingTableP2P {
  .row {
    @extend %dataGridRow;
    #icoEmployeeId {
      border-left: none !important;
      text-align: right !important;
    }
    #employeeNameId {
      border-right: none !important;
    }
  }
  .column {
    @extend %dataGridColumn;
  }
  .row__column label {
    @extend %class-for-tooltip;
  }
}

#billingTableP2P .datagrid {
  .column {
    grid-template-columns: 3% 11% 7% 5% 5% 8% 6% 7% 6% 7% 7% 7% 7% 7% 7%;
  }
  .row {
    grid-template-columns: 3% 11% 7% 5% 5% 6% 2% 6% 7% 6% 7% 7% 2% 5% 7% 7% 7%;
  }
}
#billingTable .datagrid {
  .column {
    grid-template-columns: 3% 11% 8% 5% 5% 10% 9% 7% 7% 7% 7% 7% 7% 7%;
  }
  .row {
    grid-template-columns: 3% 11% 8% 5% 5% 8% 2% 9% 7% 7% 7% 2% 5% 7% 7% 7%;
  }
}
.mic-ico {
  font-size: 1.5rem;
  color: #aaa;
  cursor: pointer;
  margin-left: 0.5rem;
}
.mic-ico-invoice {
  font-size: 1rem;
  color: #aaa;
  // margin-left: -0.5rem;
  // margin-right: 0.5rem;
}
.mic-ico-transaction {
  font-size: 1rem;
  color: #aaa;
  // margin-left: -0.5rem;
  // margin-right: 0.5rem;
}

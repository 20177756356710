/*Buttons */
.btn {
  cursor: pointer;
  letter-spacing: 1px;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: $other-font-size-1;
  line-height: 1.3;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    box-shadow: none;
  }

  // margin-left: 4px;
  // margin-right: 4px;
  // min-width: 8rem;
}

.btn-lg {
  padding: 15px 41px;
  font-size: 0.95rem;
}

.btn-sm {
  font-size: $other-font-size-0;
  font-weight: 600;
  padding: 8px 17px;
}

.btn-primary {
  color: #fff;
  background-color: var(--btn-cb-back-1);
  border-color: var(--btn-cb-back-1);
  text-decoration: none;
  // margin-left: 4px;
  // margin-right: 4px;
  min-width: 8rem;

  &:hover {
    color: #fff;
    background-color: var(--btn-hover-1);
    border-color: var(--btn-back-1);
  }

  &.focus,
  &:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #495057;
    background-color: #e9ecef;
    border-color: #e9ecef;
    cursor: default;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: var(--btn-back-1);
      border-color: var(--btn-border-1);
    }
  }
}

.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--btn-back-1);
  border-color: var(--btn-border-1);
}

.btn-primary:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary {
  color: #fff;
  background-color: #75808a;
  border-color: #75808a;

  &:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }

  &.focus,
  &:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: #545b62;
      border-color: #4e555b;
    }
  }
}

.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-success {
  color: #fff;
  background-color: #15ca20;
  border-color: #15ca20;
  min-width: 8rem;

  &:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }

  &.focus,
  &:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: #1e7e34;
      border-color: #1c7430;
    }
  }
}

.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-info {
  color: #fff;
  background-color: #0dceec;
  border-color: #0dceec;

  &:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
  }

  &.focus,
  &:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: #117a8b;
      border-color: #10707f;
    }
  }
}

.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-warning {
  color: #fff;
  background-color: #ff9700;
  border-color: #ff9700;

  &:hover {
    color: #fff;
    background-color: #e0a800;
    border-color: #d39e00;
  }

  &.focus,
  &:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: #d39e00;
      border-color: #c69500;
    }
  }
}

.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-danger {
  color: #fff;
  background-color: #b12828;
  border-color: #b12828;
  min-width: 8rem;

  &:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }

  &.focus,
  &:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #ccc;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: #f8f9fa;
      border-color: #f8f9fa;
    }
  }
}

.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-light {
  color: #212529;
  background-color: #e9eaea;
  border-color: #e9eaea;

  &:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }

  &.focus,
  &:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #212529;
      background-color: #dae0e5;
      border-color: #d3d9df;
    }
  }
}

.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-light.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-dark {
  color: #fff;
  background-color: #223035;
  border-color: #223035;

  &:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
  }

  &.focus,
  &:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: #1d2124;
      border-color: #171a1d;
    }
  }
}

.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-primary {
  color: var(--btn-cb-back-1);
  background-color: transparent;
  background-image: none;
  border-color: var(--btn-cb-back-1);

  &:hover {
    color: #fff;
    background-color: var(--btn-cb-back-1);
    border-color: var(--btn-cb-back-1);
  }

  &.focus,
  &:focus {
    color: #fff;
    background-color: var(--btn-cb-back-1);
    border-color: var(--btn-cb-back-1);
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: var(--btn-cb-back-1);
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: var(--btn-cb-back-1);
      border-color: var(--btn-cb-back-1);
    }
  }
}

.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--btn-cb-back-1);
  border-color: var(--btn-cb-back-1);
}

.btn-outline-primary:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-secondary {
  color: #75808a;
  background-color: transparent;
  background-image: none;
  border-color: #75808a;

  &:hover {
    color: #fff;
    background-color: #75808a;
    border-color: #75808a;
  }

  &.focus,
  &:focus {
    color: #fff;
    background-color: #75808a;
    border-color: #75808a;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #75808a;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: #75808a;
      border-color: #75808a;
    }
  }
}

.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #75808a;
  border-color: #75808a;
}

.btn-outline-secondary:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-success {
  color: #15ca20;
  background-color: transparent;
  background-image: none;
  border-color: #15ca20;

  &:hover {
    color: #fff;
    background-color: #15ca20;
    border-color: #15ca20;
  }

  &.focus,
  &:focus {
    color: #fff;
    background-color: #15ca20;
    border-color: #15ca20;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #15ca20;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: #15ca20;
      border-color: #15ca20;
    }
  }
}

.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #15ca20;
  border-color: #15ca20;
}

.btn-outline-success:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-info {
  color: #0dceec;
  background-color: transparent;
  background-image: none;
  border-color: #0dceec;

  &:hover {
    color: #fff;
    background-color: #0dceec;
    border-color: #0dceec;
  }

  &.focus,
  &:focus {
    color: #fff;
    background-color: #0dceec;
    border-color: #0dceec;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #0dceec;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: #0dceec;
      border-color: #0dceec;
    }
  }
}

.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0dceec;
  border-color: #0dceec;
}

.btn-outline-info:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-warning {
  color: #ff9700;
  background-color: transparent;
  background-image: none;
  border-color: #ff9700;

  &:hover {
    color: #fff;
    background-color: #ff9700;
    border-color: #ff9700;
  }

  &.focus,
  &:focus {
    color: #fff;
    background-color: #ff9700;
    border-color: #ff9700;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #ff9700;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: #ff9700;
      border-color: #ff9700;
    }
  }
}

.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ff9700;
  border-color: #ff9700;
}

.btn-outline-warning:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-danger {
  color: #b12828;
  background-color: transparent;
  background-image: none;
  border-color: #b12828;

  &:hover {
    color: #fff;
    background-color: #b12828;
    border-color: #b12828;
  }

  &.focus,
  &:focus {
    color: #fff;
    background-color: #b12828;
    border-color: #b12828;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: var(--color-fox-disabled);
    border-color: #eaeaea;
    background-color: transparent;
    cursor: default;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: #b12828;
      border-color: #b12828;
    }
  }
}

.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #b12828;
  border-color: #b12828;
}

.btn-outline-danger:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-light {
  color: #e9eaea;
  background-color: transparent;
  background-image: none;
  border-color: #e9eaea;

  &:hover {
    color: #212529;
    background-color: #e9eaea;
    border-color: #e9eaea;
  }

  &.focus,
  &:focus {
    color: #212529;
    background-color: #e9eaea;
    border-color: #e9eaea;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #e9eaea;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #212529;
      background-color: #e9eaea;
      border-color: #e9eaea;
    }
  }
}

.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #e9eaea;
  border-color: #e9eaea;
}

.btn-outline-light:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-dark {
  color: #223035;
  background-color: transparent;
  background-image: none;
  border-color: #223035;

  &:hover {
    color: #fff;
    background-color: #223035;
    border-color: #223035;
  }

  &.focus,
  &:focus {
    color: #fff;
    background-color: #223035;
    border-color: #223035;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #223035;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      background-color: #223035;
      border-color: #223035;
    }
  }
}

.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #223035;
  border-color: #223035;
}

.btn-outline-dark:not(:disabled):not(.disabled) {
  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-inverse-primary {
  color: var(--btn-cb-back-1);
  background-color: rgba(0, 140, 255, 0.18);
  border-color: rgb(209, 234, 255);

  &:hover {
    color: var(--btn-cb-back-1);
    background-color: rgba(0, 140, 255, 0.18);
    border-color: rgba(0, 140, 255, 0.18);
  }
}

.btn-inverse-secondary {
  color: #75808a;
  background-color: rgba(117, 128, 138, 0.18);
  border-color: rgb(230, 232, 234);

  &:hover {
    color: #75808a;
    background-color: rgba(117, 128, 138, 0.18);
    border-color: rgba(117, 128, 138, 0.18);
  }
}

.btn-inverse-success {
  color: #15ca20;
  background-color: rgba(21, 202, 32, 0.18);
  border-color: rgb(212, 246, 214);

  &:hover {
    color: #15ca20;
    background-color: rgba(21, 202, 32, 0.18);
    border-color: rgba(21, 202, 32, 0.18);
  }
}

.btn-inverse-danger {
  color: #b12828;
  background-color: rgba(253, 53, 80, 0.18);
  border-color: rgb(255, 218, 223);

  &:hover {
    color: #b12828;
    background-color: rgba(253, 53, 80, 0.18);
    border-color: rgba(253, 53, 80, 0.18);
  }
}

.btn-inverse-warning {
  color: #ff9700;
  background-color: rgba(255, 151, 0, 0.18);
  border-color: rgb(255, 236, 209);

  &:hover {
    color: #ff9700;
    background-color: rgba(255, 151, 0, 0.18);
    border-color: rgba(255, 151, 0, 0.18);
  }
}

.btn-inverse-info {
  color: #0dceec;
  background-color: rgba(13, 206, 236, 0.18);
  border-color: rgb(211, 246, 252);

  &:hover {
    color: #0dceec;
    background-color: rgba(13, 206, 236, 0.18);
    border-color: rgba(13, 206, 236, 0.18);
  }
}

.btn-inverse-light {
  color: #a7aaaa;
  background-color: rgba(233, 234, 234, 0.2);
  border-color: rgb(251, 251, 251);

  &:hover {
    color: #a7aaaa;
    background-color: rgba(233, 234, 234, 0.2);
    border-color: rgba(233, 234, 234, 0.2);
  }
}

.btn-inverse-dark {
  color: #223035;
  background-color: rgba(34, 48, 53, 0.2);
  border-color: #d7d9da;

  &:hover {
    color: #223035;
    background-color: rgba(34, 48, 53, 0.2);
    border-color: rgba(34, 48, 53, 0.2);
  }
}

.btn-gradient-bloody {
  background: #f54ea2;
  background: -webkit-linear-gradient(45deg, #f54ea2, #ff7676) !important;
  background: linear-gradient(45deg, #f54ea2, #ff7676) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-scooter {
  background: #17ead9;
  background: -webkit-linear-gradient(45deg, #17ead9, #6078ea) !important;
  background: linear-gradient(45deg, #17ead9, #6078ea) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-blooker {
  background: #ffdf40;
  background: -webkit-linear-gradient(45deg, #ffdf40, #ff8359) !important;
  background: linear-gradient(45deg, #ffdf40, #ff8359) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-quepal {
  background: #42e695;
  background: -webkit-linear-gradient(45deg, #42e695, #3bb2b8) !important;
  background: linear-gradient(45deg, #42e695, #3bb2b8) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-purpink {
  background: #7f00ff;
  background: -webkit-linear-gradient(45deg, #7f00ff, #e100ff) !important;
  background: linear-gradient(45deg, #7f00ff, #e100ff) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-ibiza {
  background: #ee0979;
  background: -webkit-linear-gradient(45deg, #ee0979, #ff6a00) !important;
  background: linear-gradient(45deg, #ee0979, #ff6a00) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-dusk {
  background: #2c3e50;
  background: -webkit-linear-gradient(45deg, #2c3e50, #fd746c) !important;
  background: linear-gradient(45deg, #2c3e50, #fd746c) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-meridian {
  background: #ff446a;
  background: -webkit-linear-gradient(45deg, #283c86, #45a247) !important;
  background: linear-gradient(45deg, #283c86, #45a247) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-violet {
  background: #4776e6;
  background: -webkit-linear-gradient(45deg, #4776e6, #8e54e9) !important;
  background: linear-gradient(45deg, #4776e6, #8e54e9) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-titanium {
  background: #283048;
  background: -webkit-linear-gradient(45deg, #283048, #859398) !important;
  background: linear-gradient(45deg, #283048, #859398) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-shifter {
  background: #bc4e9c;
  background: -webkit-linear-gradient(45deg, #bc4e9c, #f80759) !important;
  background: linear-gradient(45deg, #bc4e9c, #f80759) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-army {
  background: #414d0b;
  background: -webkit-linear-gradient(45deg, #414d0b, #727a17) !important;
  background: linear-gradient(45deg, #414d0b, #727a17) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-orange {
  background: #fc4a1a;
  background: -webkit-linear-gradient(45deg, #fc4a1a, #f7b733) !important;
  background: linear-gradient(45deg, #fc4a1a, #f7b733) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-yoda {
  background: #ff0099;
  background: -webkit-linear-gradient(45deg, #ff0099, #493240) !important;
  background: linear-gradient(45deg, #ff0099, #493240) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-royal {
  background: #141e30;
  background: -webkit-linear-gradient(45deg, #141e30, #2c527d) !important;
  background: linear-gradient(45deg, #141e30, #2c527d) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-knight {
  background: #ba8b02;
  background: -webkit-linear-gradient(45deg, #ba8b02, #181818) !important;
  background: linear-gradient(45deg, #ba8b02, #181818) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-forest {
  background: #000428;
  background: -webkit-linear-gradient(45deg, #000428, #004e92) !important;
  background: linear-gradient(45deg, #000428, #004e92) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-redmist {
  background: #000000;
  background: -webkit-linear-gradient(45deg, #000000, #e74c3c) !important;
  background: linear-gradient(45deg, #000000, #e74c3c) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-steelgray {
  background: #1f1c2c;
  background: -webkit-linear-gradient(45deg, #1f1c2c, #928dab) !important;
  background: linear-gradient(45deg, #1f1c2c, #928dab) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-influenza {
  background: #c04848;
  background: -webkit-linear-gradient(45deg, #c04848, #480048) !important;
  background: linear-gradient(45deg, #c04848, #480048) !important;
  color: #fff;
  border: 0;
}

.btn-round {
  border-radius: 30px !important;
}

.btn-square {
  border-radius: 0px !important;
}

.btn-group,
.btn-group-vertical {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.btn-group .btn,
.btn-group-vertical .btn {
  box-shadow: none !important;
}

.btn-group.group-round {
  > .btn {
    &:first-child {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }

    &:last-child {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }

  border-radius: 25px;
}

.btn-group-vertical.group-round-vertical {
  border-radius: 25px;

  > .btn {
    &:first-child {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
    }

    &:last-child {
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }
}

.split-btn-primary {
  border: 1px solid #0268d6 !important;
  background-color: #0268d6;

  &:hover {
    border: 1px solid #0268d6 !important;
    background-color: #0268d6;
  }
}

.split-btn-success {
  border: 1px solid #0f942e !important;
  background-color: #0f942e;

  &:hover {
    border: 1px solid #0f942e !important;
    background-color: #0f942e;
  }
}

.split-btn-danger {
  border: 1px solid #d02d3c !important;
  background-color: #d02d3c;

  &:hover {
    border: 1px solid #d02d3c !important;
    background-color: #d02d3c;
  }
}

.split-btn-secondary {
  border: 1px solid #586169 !important;
  background-color: #586169;

  &:hover {
    border: 1px solid #586169 !important;
    background-color: #586169;
  }
}

.split-btn-warning {
  border: 1px solid #e68901 !important;
  background-color: #e68901;

  &:hover {
    border: 1px solid #e68901 !important;
    background-color: #e68901;
  }
}

.split-btn-info {
  border: 1px solid #0296ad !important;
  background-color: #0296ad;

  &:hover {
    border: 1px solid #0296ad !important;
    background-color: #0296ad;
  }
}

.split-btn-dark {
  border: 1px solid #000000 !important;
  background-color: #000000;

  &:hover {
    border: 1px solid #000000 !important;
    background-color: #000000;
  }
}

.btn-social {
  width: 4.2rem;
  height: 4.2rem;
  font-size: $other-font-size-6;
  line-height: 42px;
  display: inline-block;
  border: 1px solid transparent;
  background: #ddd;
  color: #fff;
  text-align: center;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    color: #fff;
  }
}

.btn-social-text {
  font-size: 0.72rem;
  padding: 10px 20px;
  font-weight: 600;
  border: 1px solid transparent;
  display: inline-block;
  background: #ddd;
  color: #fff;
  text-align: center;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;

  i {
    font-size: 0.95rem;
    padding-right: 4px;
    vertical-align: middle;
    display: table-cell;
  }

  span {
    display: table-cell;
    vertical-align: middle;
  }

  &:hover {
    outline: 0;
    color: #fff;
  }
}

.btn-social {
  &:focus,
  &:active {
    outline: 0;
    color: #fff;
  }
}

.btn-social-circle {
  border-radius: 50%;
}

.btn-outline-facebook {
  box-shadow: 0 2px 2px 0 rgba(59, 89, 152, 0.14),
    0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12);
  color: #3b5998;
  background-color: #fff;
  border-color: #3b5998;

  &:hover,
  &:focus {
    box-shadow: 0 2px 2px 0 rgba(59, 89, 152, 0.14),
      0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12);
    color: #fff;
    background-color: #3b5998;
  }
}

.btn-outline-twitter {
  box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
    0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
  color: #55acee;
  background-color: #fff;
  border-color: #55acee;

  &:hover,
  &:focus {
    box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
      0 3px 1px -2px rgba(85, 172, 238, 0.2),
      0 1px 5px 0 rgba(85, 172, 238, 0.12);
    background-color: #55acee;
    color: #fff;
  }
}

.btn-outline-google-plus {
  box-shadow: 0 2px 2px 0 rgba(221, 75, 57, 0.14),
    0 3px 1px -2px rgba(221, 75, 57, 0.2), 0 1px 5px 0 rgba(221, 75, 57, 0.12);
  color: #dd4b39;
  background-color: #fff;
  border-color: #dd4b39;

  &:hover,
  &:focus {
    box-shadow: 0 2px 2px 0 rgba(221, 75, 57, 0.14),
      0 3px 1px -2px rgba(221, 75, 57, 0.2), 0 1px 5px 0 rgba(221, 75, 57, 0.12);
    background-color: #dd4b39;
    color: #fff;
  }
}

.btn-outline-linkedin {
  box-shadow: 0 2px 2px 0 rgba(9, 118, 180, 0.14),
    0 3px 1px -2px rgba(9, 118, 180, 0.2), 0 1px 5px 0 rgba(9, 118, 180, 0.12);
  background-color: #fff;
  color: #0976b4;
  border-color: #0976b4;

  &:hover,
  &:focus {
    box-shadow: 0 2px 2px 0 rgba(9, 118, 180, 0.14),
      0 3px 1px -2px rgba(9, 118, 180, 0.2), 0 1px 5px 0 rgba(9, 118, 180, 0.12);
    background-color: #0976b4;
    color: #fff;
  }
}

.btn-outline-pinterest {
  box-shadow: 0 2px 2px 0 rgba(204, 33, 39, 0.14),
    0 3px 1px -2px rgba(204, 33, 39, 0.2), 0 1px 5px 0 rgba(204, 33, 39, 0.12);
  color: #cc2127;
  background-color: #fff;
  border-color: #cc2127;

  &:hover,
  &:focus {
    box-shadow: 0 2px 2px 0 rgba(204, 33, 39, 0.14),
      0 3px 1px -2px rgba(204, 33, 39, 0.2), 0 1px 5px 0 rgba(204, 33, 39, 0.12);
    background-color: #cc2127;
    color: #fff;
  }
}

.btn-outline-youtube {
  box-shadow: 0 2px 2px 0 rgba(229, 45, 39, 0.14),
    0 3px 1px -2px rgba(229, 45, 39, 0.2), 0 1px 5px 0 rgba(229, 45, 39, 0.12);
  color: #e52d27;
  background-color: #fff;
  border-color: #e52d27;

  &:hover,
  &:focus {
    box-shadow: 0 2px 2px 0 rgba(229, 45, 39, 0.14),
      0 3px 1px -2px rgba(229, 45, 39, 0.2), 0 1px 5px 0 rgba(229, 45, 39, 0.12);
    background-color: #e52d27;
    color: #fff;
  }
}

.btn-outline-tumblr {
  box-shadow: 0 2px 2px 0 rgba(53, 70, 92, 0.14),
    0 3px 1px -2px rgba(53, 70, 92, 0.2), 0 1px 5px 0 rgba(53, 70, 92, 0.12);
  color: #35465c;
  background-color: #fff;
  border-color: #35465c;

  &:hover,
  &:focus {
    box-shadow: 0 2px 2px 0 rgba(53, 70, 92, 0.14),
      0 3px 1px -2px rgba(53, 70, 92, 0.2), 0 1px 5px 0 rgba(53, 70, 92, 0.12);
    background-color: #35465c;
    color: #fff;
  }
}

.btn-outline-github {
  box-shadow: 0 2px 2px 0 rgba(51, 51, 51, 0.14),
    0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12);
  color: #333333;
  background-color: #fff;
  border-color: #333333;

  &:hover {
    box-shadow: 0 2px 2px 0 rgba(51, 51, 51, 0.14),
      0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12);
    background-color: #333333;
    color: #fff;
  }
}

.btn-github:focus {
  box-shadow: 0 2px 2px 0 rgba(51, 51, 51, 0.14),
    0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12);
  background-color: #333333;
  color: #fff;
}

.btn-outline-behance {
  box-shadow: 0 2px 2px 0 rgba(23, 105, 255, 0.14),
    0 3px 1px -2px rgba(23, 105, 255, 0.2), 0 1px 5px 0 rgba(23, 105, 255, 0.12);
  color: #1769ff;
  background-color: #fff;
  border-color: #1769ff;

  &:hover,
  &:focus {
    box-shadow: 0 2px 2px 0 rgba(23, 105, 255, 0.14),
      0 3px 1px -2px rgba(23, 105, 255, 0.2),
      0 1px 5px 0 rgba(23, 105, 255, 0.12);
    background-color: #1769ff;
    color: #fff;
  }
}

.btn-outline-dribbble {
  box-shadow: 0 2px 2px 0 rgba(234, 76, 137, 0.14),
    0 3px 1px -2px rgba(234, 76, 137, 0.2), 0 1px 5px 0 rgba(234, 76, 137, 0.12);
  color: #ea4c89;
  background-color: #fff;
  border-color: #ea4c89;

  &:hover,
  &:focus {
    box-shadow: 0 2px 2px 0 rgba(234, 76, 137, 0.14),
      0 3px 1px -2px rgba(234, 76, 137, 0.2),
      0 1px 5px 0 rgba(234, 76, 137, 0.12);
    background-color: #ea4c89;
    color: #fff;
  }
}

.btn-outline-skype {
  box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
    0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
  color: #00aff0;
  background-color: #fff;
  border-color: #00aff0;

  &:hover,
  &:focus {
    box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
      0 3px 1px -2px rgba(85, 172, 238, 0.2),
      0 1px 5px 0 rgba(85, 172, 238, 0.12);
    background-color: #00aff0;
    color: #fff;
  }
}

.btn-facebook {
  box-shadow: 0 2px 2px 0 rgba(59, 89, 152, 0.14),
    0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12);
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-twitter {
  box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
    0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
  background-color: #55acee;
  border-color: #55acee;
}

.btn-google-plus {
  box-shadow: 0 2px 2px 0 rgba(221, 75, 57, 0.14),
    0 3px 1px -2px rgba(221, 75, 57, 0.2), 0 1px 5px 0 rgba(221, 75, 57, 0.12);
  background-color: #dd4b39;
  border-color: #dd4b39;
}

.btn-linkedin {
  box-shadow: 0 2px 2px 0 rgba(9, 118, 180, 0.14),
    0 3px 1px -2px rgba(9, 118, 180, 0.2), 0 1px 5px 0 rgba(9, 118, 180, 0.12);
  background-color: #0976b4;
  border-color: #0976b4;
}

.btn-pinterest {
  box-shadow: 0 2px 2px 0 rgba(204, 33, 39, 0.14),
    0 3px 1px -2px rgba(204, 33, 39, 0.2), 0 1px 5px 0 rgba(204, 33, 39, 0.12);
  background-color: #cc2127;
  border-color: #cc2127;
}

.btn-youtube {
  box-shadow: 0 2px 2px 0 rgba(229, 45, 39, 0.14),
    0 3px 1px -2px rgba(229, 45, 39, 0.2), 0 1px 5px 0 rgba(229, 45, 39, 0.12);
  background-color: #e52d27;
  border-color: #e52d27;
}

.btn-tumblr {
  box-shadow: 0 2px 2px 0 rgba(53, 70, 92, 0.14),
    0 3px 1px -2px rgba(53, 70, 92, 0.2), 0 1px 5px 0 rgba(53, 70, 92, 0.12);
  background-color: #35465c;
  border-color: #35465c;
}

.btn-github {
  box-shadow: 0 2px 2px 0 rgba(51, 51, 51, 0.14),
    0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12);
  background-color: rgba(76, 175, 80, 0.7);
  border-color: rgba(76, 175, 80, 0.7);
}

.btn-behance {
  box-shadow: 0 2px 2px 0 rgba(23, 105, 255, 0.14),
    0 3px 1px -2px rgba(23, 105, 255, 0.2), 0 1px 5px 0 rgba(23, 105, 255, 0.12);
  background-color: #1769ff;
  border-color: #1769ff;
}

.btn-dribbble {
  box-shadow: 0 2px 2px 0 rgba(234, 76, 137, 0.14),
    0 3px 1px -2px rgba(234, 76, 137, 0.2), 0 1px 5px 0 rgba(234, 76, 137, 0.12);
  background-color: #ea4c89;
  border-color: #ea4c89;
}

.btn-skype {
  box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
    0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
  background-color: #00aff0;
  border-color: #00aff0;
}

.btn-user-verified {
  box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
    0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
  background-color: #15ca20;
  border-color: #15ca20;
}

.user-verified-position {
  position: absolute !important;
  //right: 0;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  .waves-ripple {
    position: absolute;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.7s ease-out;
    -moz-transition: all 0.7s ease-out;
    -o-transition: all 0.7s ease-out;
    -ms-transition: all 0.7s ease-out;
    transition: all 0.7s ease-out;
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: transform, opacity;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    pointer-events: none;
  }

  &.waves-light .waves-ripple {
    background-color: rgba(255, 255, 255, 0.45);
  }

  &.waves-red .waves-ripple {
    background-color: rgba(244, 67, 54, 0.7);
  }

  &.waves-yellow .waves-ripple {
    background-color: rgba(255, 235, 59, 0.7);
  }

  &.waves-orange .waves-ripple {
    background-color: rgba(255, 152, 0, 0.7);
  }

  &.waves-purple .waves-ripple {
    background-color: rgba(156, 39, 176, 0.7);
  }

  &.waves-green .waves-ripple {
    background-color: rgba(76, 175, 80, 0.7);
  }

  &.waves-teal .waves-ripple {
    background-color: rgba(0, 150, 136, 0.7);
  }
}

.waves-notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

.waves-circle {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none;
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;

  .waves-button-input {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.waves-block {
  display: block;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--btn-back-1);
  border-color: var(--btn-border-1);
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-round-icon {
  border-radius: 50%;
  padding: 0 0.4rem;
  width: 2rem;
  height: 2rem;
  min-width: 0;
  outline: none;

  .fa-play {
    font-size: 0.8rem;
    padding: 0.5rem 0.5rem 0.5rem 0.2rem;
  }

  .fa-plus {
    padding: 0rem;
  }
}

.btn-yellow-default {
  color: var(--btn-fox-blue);
  background-color: var(--btn-fox-yellow);
  border-color: var(--btn-fox-yellow);
  text-decoration: none;
  // margin-left: 4px;
  // margin-right: 4px;
  min-width: 8rem;

  &:hover {
    // color: #fff;
    background-color: var(--btn-fox-yellow-hover);
    border-color: var(--btn-fox-yellow-hover);
  }

  &.focus,
  &:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #fff !important;
    background-color: var(--btn-fox-disabled) !important;
    border-color: var(--btn-fox-disabled) !important;
    cursor: default;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: var(--btn-fox-blue);
      background-color: var(--btn-fox-yellow-hover);
      border-color: var(--btn-fox-yellow-hover);
    }
  }
}

.btn-light-blue {
  color: var(--btn-fox-blue);
  background-color: var(--btn-fox-blue-light);
  border-color: var(--btn-fox-blue-light);
  text-decoration: none;
  // margin-left: 4px;
  // margin-right: 4px;
  min-width: 8rem;

  &:hover {
    color: #fff;
    background-color: var(--btn-fox-blue-hover);
    border-color: var(--btn-fox-blue-hover);
  }

  &.focus,
  &:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #fff !important;
    background-color: var(--btn-fox-disabled) !important;
    border-color: var(--btn-fox-disabled) !important;
    cursor: default;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: var(--btn-fox-blue);
      background-color: var(--btn-fox-blue-hover);
      border-color: var(--btn-fox-blue-hover);
    }
  }
}

.btn-light-danger {
  color: var(--btn-fox-danger);
  background-color: var(--btn-fox-danger-light);
  border-color: var(--btn-fox-danger-light);
  text-decoration: none;
  // margin-left: 4px;
  // margin-right: 4px;
  min-width: 8rem;

  &:hover {
    color: #fff;
    background-color: var(--btn-fox-danger-hover);
    border-color: var(--btn-fox-danger-hover);
  }

  &.focus,
  &:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: #fff !important;
    background-color: var(--btn-fox-disabled) !important;
    border-color: var(--btn-fox-disabled) !important;
    cursor: default;
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: var(--btn-fox-danger);
      background-color: var(--btn-fox-danger-hover);
      border-color: var(--btn-fox-danger-hover);
    }
  }
}

.btn-blue-outline {
  color: var(--btn-fox-blue);
  background-color: #fff;
  // background-image: none;
  border-color: #eaeaea;

  &:hover {
    color: #fff;
    background-color: var(--btn-fox-blue);
    border-color: var(--btn-fox-blue);
  }

  &.focus,
  &:focus {
    color: #fff;
    background-color: var(--btn-fox-blue);
    border-color: var(--btn-fox-blue);
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: var(--color-fox-disabled);
    // background-color: var(--btn-fox-blue-light);
  }

  &:not(:disabled):not(.disabled) {
    &.active,
    &:active {
      color: #fff;
      // background-color: var(--btn-fox-blue);
      border-color: #eaeaea;
    }
  }
}

body {
  font-family: "Lato", sans-serif, "Inter";
  font-weight: 400;
  line-height: 1.5;
  color: $color-grey-dark;
  //font-size: $default-font-size;
  @include font-size;
  padding-right: 0rem !important;
}
.heading-tertiary {
  font-size: $default-font-size;
  font-weight: 700;
}

.picky__input {
  height: auto;
}
.picky__input::after {
  top: 1rem;
  right: 1rem;
}
.picky__dropdown {
  top: 2.1rem;
  border: 1px solid #aaa !important;
  border-top: none !important;
  overflow-y: auto !important;
  .picky__filter__input {
    color: #aaa;
  }
  z-index: 401;
}
.picky__placeholder,
.picky__input {
  font-size: 1.1rem;
  color: #495057;
}
button.picky__input {
  border: 0.1rem solid #ced4da;
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap;
  display: block;
}

.picky__dropdown input[type="checkbox"],.picky__dropdown input[type="radio"] {
  vertical-align: text-top !important;
}
.picky__dropdown::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.picky__dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.picky__dropdown::-webkit-scrollbar-thumb {
  background: #aaa;
}

.picky__dropdown::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.picky__input:after {
  top: 0.8rem;
  right: 0.8rem;
}
.rw-input {
  padding-left: 0.5rem !important;
}

// Searcable Dropdwon CSS for Modal
.modal {

  .rw-btn,
  .rw-input-reset,
  .rw-input,
  .rw-dropdown-list-autofill,
  .rw-filter-input {
    color: inherit;
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
    background: none;
    background-image: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    -ms-touch-action: manipulation;
    touch-action: manipulation
  }

  .rw-widget-container {
    background-color: #fff;
    border: #ccc 1px solid;
    border-radius: 4px
  }

  .rw-widget-picker>* {
    display: table-cell
  }

  .rw-filter-input {
    position: relative;
    margin: 4px;
    padding-right: 0
  }
}
  
@import "../../../../sass/abstracts/_mixins.scss";
@import "../../../../sass/abstracts/variables";

#fox-reservation-rental-summary {
  display: flex;
  width: 100%;
  justify-content: center !important;
  background: var(--color-grey-light-5);
  font-family: $font-family-inter;

  .c-clipboard:hover img {
    content: url("../../../../assets//img/input/copy-01.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .res-summary-container-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1.75em 3em;
    min-width: 30rem;

    &:nth-child(1) {
      flex-basis: 75%;
    }

    &:nth-child(2) {
      flex-basis: 25%;
    }

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }

    border-right: 1px solid #e6e9ef;
    gap: 1.6em;

    /* Custom Message Box styles */
    .message-box {
      width: 40em;
      position: absolute;
      padding: 1rem;
      border-radius: 0.5rem;
      border-radius: 1.2rem;
      background: #fff;
      z-index: 10;
      box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.16);

      .message-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
      }

      .message-title {
        font-weight: bold;
        // font-family: Roboto;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        line-height: 1.4rem;
      }

      .message-subtitle {
        margin-bottom: 0.5rem;
        color: #000;
        // font-family: Roboto;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        line-height: 140%;
      }

      .message-content {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: $font-weight-normal;
        line-height: 150%;
      }

      .message {
        flex: 1;
        // margin-right: 1rem;
        text-align: justify;
      }

      .close-button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        font-size: 2rem;
        color: #aaa;
        width: 2rem !important;

        img {
          height: 2rem !important;
          width: 2rem !important;
        }
      }
    }

    /* End Custom Message Box styles */
  }

  .res-summary-action-block,
  .res-customer-items,
  .reservation-comments-section {
    display: flex;
    align-items: flex-start;
    min-width: 100%;

    .res-summary-actions {
      display: flex;
      align-items: flex-end;
      gap: 1.2rem;
      justify-content: flex-end;
      flex: 1 1;
      position: relative;
      flex-wrap: wrap;

      .summary-action-button {
        position: relative;
        display: flex;
        gap: 1rem;
        img {
          position: absolute;
          top: 0.7rem;
          left: 0.5rem;
        }

        .action-button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.8rem 0.8rem 0.8rem 2rem;
          gap: 0.6rem;
          border-radius: 0.8rem;
          font-size: 1.2rem;
          font-weight: $font-weight-semi-bold;
          line-height: 1.2rem;

          &.refresh,
          &.view,
          &.resend {
            padding-left: 2.2rem;
          }

          &:focus img,
          &:hover img,
          &.disabled img,
          &[disabled] img {
            &.cancel-icon {
              content: url("../../../../assets//img/input/cancel-01-hover.svg");
            }

            &.edit-icon {
              content: url("../../../../assets//img/input/edit-02-hover.svg");
            }

            &.view-icon {
              content: url("../../../../assets//img/input/view-hover.svg");
            }

            &.info-icon {
              content: url("../../../../assets//img/input/info-hover.svg");
            }

            &.refresh-icon {
              content: url("../../../../assets//img/input/reload-light.svg");
            }

            &.resend-icon {
              content: url("../../../../assets//img/input/mail-send-light.svg");
            }

            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }

      .action-icon {
        display: flex;
        padding: 0.8rem;
        align-items: center;
        gap: 0.6rem;
        border-radius: 3.2rem;
        background: #e6e9ef;
        cursor: pointer;

        &:hover {
          background-color: #001e60;

          img {
            &.cancel-icon {
              content: url("../../../../assets//img/input/cancel-01-hover.svg");
            }

            &.edit-icon {
              content: url("../../../../assets//img/input/edit-02-hover.svg");
            }

            &.info-icon {
              content: url("../../../../assets//img/input/info-hover.svg");
            }

            &.refresh-icon {
              content: url("../../../../assets//img/input/reload-light.svg");
            }

            &.resend-icon {
              content: url("../../../../assets//img/input/mail-send-light.svg");
            }

            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }

  .res-summary-top-bar,
  .res-summary-location-block,
  .res-summary-other-block,
  .customer-other-rows {
    display: flex;
    padding: 1.6rem;
    align-items: center;
    gap: 1.6rem;
    border-radius: 1.6rem;
    background: #fff;
    width: 100%;
    min-width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    top: 0.5rem;

    .res-items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.4rem;
      padding-left: 1.6rem;

      .field-title {
        color: #585858;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 1.8rem;
        letter-spacing: 0.018px;
      }

      .field-value {
        display: flex;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        line-height: 2rem;
        gap: 1rem;

        &.link-enabled {
          color: #001e60;
          cursor: pointer !important;

          label {
            cursor: pointer !important;
          }

          text-decoration: underline;
        }

        &.customer-note {
          width: 18rem;
        }
      }
    }
  }

  .res-summary-top-bar .res-items:first-child {
    padding-left: 0rem;
  }

  .res-summary-other-block .res-items {
    padding-left: 0rem;
  }

  .res-summary-top-bar .res-items {
    border-left: 1px solid #e6e9ef;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }

    // .field-title {
    //   min-width: 8rem;
    // }
  }

  .summary-bottom-container {
    display: flex;
    width: 100%;
    justify-content: center !important;
    gap: 1.6rem;

    .summary-bottom-left-sub-container {
      display: flex;
      padding: 1.6rem;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 1.6rem;
      gap: 16px;
      flex-basis: 35%;
      background: #fff;

      .res-summary-vehicle-block {
        width: 100%;

        .res-summary-vehicle-info {
          .more-info-icon {
            cursor: pointer;

            img {
              position: absolute;
              height: 1.6rem !important;
              width: 1.6rem !important;
              top: 0.8rem;
              right: 0rem;
              z-index: 1;
            }
          }

          .more-info {
            display: flex;
            align-items: center;
            gap: 0.6rem;
            flex-wrap: wrap;
            justify-content: space-between;

            .more-info-item {
              color: #585858;
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 500;
              line-height: 1.2rem;
              /* 100% */
              border-radius: 3.2rem;
              border: 1px solid #e6e9ef;
              padding: 0.8rem 1.2rem;
            }
          }

          .vehicle-class-item-in-card {
            img {
              width: 7.4rem;
              height: auto;
            }
          }

          .vehicle-section {
            .catgory {
              border-radius: 4rem;
              background: #e6e9ef;
              width: fit-content;
              display: inline-block;
              text-align: center;
            }
          }
        }

        .res-summary-location-block {
          position: relative;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 2rem;
          border-radius: 1.6rem;
          border: 1px solid #f1f1f1;

          .res-items {
            padding-left: 3rem;
            border-right: none;

            .other-field-value {
              @extend .field-value;
              font-weight: 500;
            }

            .field-value {
              position: relative;

              .input-icon {
                padding: 0.4rem;
                position: absolute;
                left: -3rem;
                top: -0.3rem;
              }

              .action-icon {
                right: -4rem;
                top: -0.3rem;
                cursor: pointer;

                &:hover img {
                  &.info-icon {
                    content: url("../../../../assets//img/input/info-hover.svg");
                  }

                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }
            }
          }

          .vertical-line {
            border-color: #eaeaea;
            margin: 0.2rem 0.3rem 0 0.1rem;
            height: 3.8rem !important;
            position: absolute;
            left: 2.6rem;
            top: 37.5%;
          }
        }
      }
    }

    .summary-bottom-right-sub-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 65%;
      align-items: flex-start;
      gap: 1.6rem;

      .reservation-comments-section,
      .res-summary-customer-block,
      .res-summary-other-block {
        display: flex;
        flex-direction: column;
        padding: 1.6rem;
        align-items: flex-start;
        gap: 1.2rem;
        background: #fff;
        border-radius: 1.6rem;
        min-width: 100%;

        .res-customer-items {
          gap: 1.2rem;

          .profile-image img {
            border-radius: 4rem;
            background: #f4ce9b;
          }

          .customer-details-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            gap: 0.8rem;
            min-width: calc(100% - 45px);

            .customer-name-row {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-start;
              gap: 0.8rem;
              min-width: 100%;
              position: relative;

              .customer-name {
                font-size: 1.4rem;
                font-style: normal;
                font-weight: $font-weight-semi-bold;
                line-height: 2rem;
                letter-spacing: 0.0021rem;
              }

              .summary-action-button {
                position: absolute;
                right: 0;
                top: 0;
              }
            }

            .customer-other-rows {
              justify-content: flex-start;
              align-items: flex-start;
              gap: 5%;
              padding: 0rem;
              position: relative;
              flex-wrap: wrap;

              .field-value {
                display: flex;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: $font-weight-medium;
                line-height: 1.8rem;
                letter-spacing: 0.0018rem;
                position: relative;
                padding-bottom: 0.8rem;
                padding-left: 2rem;
                min-width: 45%;
                width: 45%;
                box-sizing: border-box;
                gap: 3%;
                word-break: break-all;

                .input-icon {
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }
          }
        }
      }

      .res-summary-other-block {
        flex-direction: row;
        justify-content: flex-start;
        gap: 5%;
        position: relative;
        flex-wrap: wrap;
        row-gap: 1rem;

        .res-items {
          font-size: 1.2rem;
          font-style: normal;
          font-weight: $font-weight-medium;
          line-height: 1.8rem;
          letter-spacing: 0.0018rem;
          position: relative;
          padding-bottom: 1.2rem;
          width: 30%;
          min-width: 30%;
          box-sizing: border-box;
          border-right: none !important;

          .testRes {
            color: red;
          }
        }
      }

      .reservation-comments-section {
        background: transparent;
        flex-direction: row;
        padding: 1rem 0;

        .renter-comments-section,
        .callcenter-notes-section {
          display: flex;
          flex-direction: column;
          width: 49%;
          padding: 16px;
          align-items: flex-start;
          gap: 16px;
          border-radius: 16px;
          background: var(--Neutral-BNW-white, #fff);
        }

        .renterComments,
        .callCenterNotes {
          width: 100%;

          // background: #fff;
          textarea {
            border: none !important;
            border-radius: 0.8rem;

            &.editmode {
              background: #e6e9ef;
            }
          }
        }

        position: relative;
        flex-wrap: wrap;

        .renter-notes-title-actions {
          display: flex;
          min-width: 100%;
          width: 100%;

          .renter-notes-title {
            display: flex;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: $font-weight-semi-bold;
            line-height: 2rem;
            letter-spacing: 0.0021rem;
          }
        }

        textarea {
          font-family: Inter;
          font-size: 1.2rem;
          font-style: normal;
          line-height: 1.8rem;
          letter-spacing: 0.0018rem;
          padding: 0.5rem;
        }

        .renter-comments-section,
        .callcenter-notes-section {
          .renter-notes-title-actions .res-summary-actions .action-icon {
            display: none;
            position: absolute;
            top: 0;
            border-radius: 0.8rem;
          }

          &:hover
            .renter-notes-title-actions
            .res-summary-actions
            .action-icon {
            display: flex;
          }
        }

        // Expand or More feature CSS
        .label-with-more {
          display: block;
          overflow: hidden;
          font-size: 1.2rem;
        }

        .expanded .line-clamp {
          -webkit-line-clamp: initial;
          /* Show all lines when expanded */
        }

        .more,
        .less {
          cursor: pointer;
          display: inline-block;
          margin-left: 0.4rem;
          color: var(--color-primary);
          /* Adjust the color as needed */
        }
      }
    }
  }
}

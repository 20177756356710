.datagrid {
  border-radius: 0.5rem;
  [class*="icheck-"] > input:first-child + label::before,
  [class*="icheck-"]
    > input:first-child
    + input[type="hidden"]
    + label::before {
    position: relative;
    margin-left: -2.1rem;
  }
  .column {
    display: grid;
    grid-auto-flow: column;
    //grid-template-columns: 7% 7% 6% 6% 5% 8% 5% 5% 8% 7% 7% 7% 10% 6% 6%;
    line-height: 1;
  }
  %columnHeader {
    padding: 7px 4px !important;
    background: var(--table-Header-1);
    color: #fff;
    font-weight: 700;
    padding: 0;
    border-right: 1px solid #fff;
    position: relative;
    text-align: center !important;
  }
  .column__header {
    @extend %columnHeader;
    //font-size:  $default-font-size;
  }
  .cb-container {
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-container {
    padding: 0 !important;
    display: grid;
    grid: auto / repeat(4, minmax(0, 1fr)) !important;
    margin: 0.3rem 0rem !important;
  }
  .icon-right {
    justify-content: flex-end;
    padding-right: 0.5rem !important;
  }

  .row {
    display: grid;
    grid-auto-flow: column;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    align-items: center;

    //grid-template-columns: 7% 7% 6% 6% 5% 8% 5% 5% 8% 7% 7% 7% 10% 6% 6%;
    line-height: 1;
    &__column {
      padding: 0.7rem;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border-right: 0.1rem solid #fff;
      //font-size:  $default-font-size;

      img {
        display: inline-block;
        backface-visibility: hidden;
        height: 1.4rem;
        width: 1.4rem;
        column-gap: 0.2rem;
        // cursor: pointer;
      }

      .amount {
        text-align: right;
        align-self: center;
      }
      a {
        cursor: pointer;
        color: var(--color-primary);
        outline: none !important;
        text-decoration: none !important;
        background-color: transparent !important;
      }
      a:focus,
      a:hover {
        text-decoration: none;
        color: var(--color-primary-light);
      }
    }
    span:last-child {
      border-right: 0rem !important;
    }
  }

  .row:hover {
    background: none repeat scroll 0 0 #e4e4e4 !important;
  }
  .row:nth-child(even) {
    background-color: #f9f9f9;
  }
}
.sorting {
  cursor: pointer;
}
.sorting_asc {
  cursor: pointer;
}
.sorting_dsc {
  cursor: pointer;
}
%sortIcon {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: $other-font-size-3;
  position: absolute;
  right: 0.2rem;
  top: 0.5rem;
}

.sorting:before {
  @extend %sortIcon;
  content: "\f0de";
  color: #fff;
}
.sorting:after {
  @extend %sortIcon;
  content: "\f0dd";
  color: #fff;
}

.sorting_asc:before {
  @extend %sortIcon;
  content: "\f0de";
  color: #ccc;
}
.sorting_asc:after {
  @extend %sortIcon;
  content: "\f0dd";
  color: #fff;
}

.sorting_dsc:before {
  @extend %sortIcon;
  content: "\f0de";
  color: #fff;
}
.sorting_dsc:after {
  @extend %sortIcon;
  content: "\f0dd";
  color: #ccc;
}
%dataGridRow {
  display: grid;
  grid-auto-flow: column;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  line-height: 1;
}

%dataGridColumn {
  display: grid;
  grid-auto-flow: column;
  line-height: 1;
}

%overflowCommon {
  width: 88px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
%class-for-tooltip {
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap;
  display: block;
}
//Reservation status wise color set//
/*need to refactor later*/
.datagrid .row .row__column,
.resStatus {
  /*need to refactor later*/
  .colorBox {
    &.NOT_STARTED {
      background: #ff0000;
    }
    &.PENDING {
      background: #d36c19;
    }
    &.CONFIRMED {
      background: #5dbd91;
    }
    &.CANCELLED {
      background: #ff6a00;
    }
    &.MESSAGE {
      background: #d4bfff;
    }
    &.INCOMPLETE {
      background: #ffd800;
    }
    &.BACKGROUND_CHECK_PENDING {
      background: #de2f13;
    }
    &.PAYMENT_PENDING {
      background: #6b2222;
    }
    &.COMPLETED.true {
      background: #ff00ff;
    }
    &.COMPLETED.false {
      background: #8dacb0;
    }
    &.IN_PROGRESS.true {
      background: #d98880;
    }
    &.IN_PROGRESS.false {
      background: #eacf12;
    }
    &.LATE {
      background: #d98880;
    }
    &.RETURN_LATE {
      background: #ff00ff;
    }
    &.WEBERROR {
      background: darkgoldenrod;
    }
  }
}
/*need to refactor later*/
#serviceAgentReportTable,
#mechanicReportTable,
#readyLineReportTable {
  .colorBox.COMPLETED {
    background: #8dacb0;
  }
  .colorBox.ACTIVE {
    background: #3a78ab;
  }
  .colorBox.INACTIVE {
    background: #b5b2be;
  }
  .colorBox.HOLD {
    background: #0000ff;
  }
  .colorBox.ASSIGNED {
    background: #8b3aab;
  }
  .colorBox.OBSOLETE {
    background: #4bb4e3;
  }
  .colorBox.AUDIT_OK {
    background: #5dbd91 !important;
  }
  .colorBox.AUDIT_FAILED {
    background: #de2f13 !important;
  }
  .colorBox.NOT_AUDITED {
    background: #4bb4e3 !important;
  }
}
.area-container {
  width: 100%;
  height: 45rem;
  // border: #e0e0e0 solid 1px;
  border: #c8c8c8 solid 1px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
}

%noResultArea {
  display: block;
  font-size: large;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.no-search {
  @extend %noResultArea;
  color: #bdbdbd;
}

.no-record-found {
  @extend %noResultArea;
  color: #d54f32;
}

.no-record-found-normal {
  font-size: 11px;
  font-weight: normal;
  color: #777777;
}

.no-search-popup {
  @extend %noResultArea;
  color: #666;
  font-size: $default-font-size;
}

.default-message-container-popup {
  height: 10rem !important;
  border: none !important;
}

.noAnchorLink,
.noAnchorLink:hover {
  cursor: default !important;
  color: #777 !important;
  pointer-events: none !important;
}

.noImageLink,
.noImageLink:hover {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  cursor: no-drop;
  pointer-events: none;
}

.resGenreIconForRow {
  width: 2.8rem;
  height: 14px;
  background: red;
  color: #fff;
  text-align: center;
  font-size: $other-font-size-0;
  font-weight: bold;
  vertical-align: middle;
  padding: 0.2rem;
  padding-left: 0rem;
}

.grid-column-start-1 {
  grid-column-start: 1 !important;
}

//Specially written for "Add Free Credit Grant/Reverse" Modal

.grid-container {
  display: grid;
  grid-template-columns: 15% 40% 40%;
}
.grid-col-row {
  display: grid;
  grid-template-columns: 35% 60%;
  column-gap: 1% !important;
  padding: 0.2rem 0rem;
  position: relative;
}

.grid-col-1 {
  grid-row: 1 / 5;
}
.grid-col-2 {
  grid-column: 2/4;
}

@import "../abstracts/variables";
@import "../layout/grid";

%checkBoxRolesPermissionsAll {
  border: 1px solid #696f71;
  display: inline-block;
  padding: 0 !important;
  cursor: pointer;
  width: 1.3rem;
  height: 1.3rem;
  text-align: center;
  background: #fff;
  color: #000;
  //position: relative;
  i {
    font-size: $other-font-size-2;
    color: #000;
    padding: 0 !important;
    margin: 0 !important;
    vertical-align: baseline;
  }
}
.tblRolesPermissionSettings {
  .header-td {
    z-index: 1;
  }
  .roles-legend {
    color: rgb(0, 0, 0);
    background: rgb(255, 255, 255);
    width: 325px;
    padding-top: 0px;
    padding-left: 0px;
    border-right: 1px solid rgb(0, 0, 0);
    i {
      font-size: $default-font-size;
    }
    .legend-div {
      text-align: left;
      padding: 0.5rem;
    }
    .legend-cb {
      height: 1.2rem;
      width: 1.2rem;
    }
    .legend-child-div {
      width: 48%;
      float: left;
    }
    .legend-cb-margin {
      margin-right: 0.5rem;
    }
  }

  .roles-type {
    font-weight: bold;
    background: #444444;
    color: #fff;
  }
  thead td {
    background: #fff;
    border-right: 1px solid #dddddd;
  }
  .detail-td {
    //background: #ebebeb !important;
    border-right: 2px solid #ffffff;
  }
  .border-top-1 {
    border-top: 1px solid #444;
  }
  .border-right-1 {
    border-right: 1px solid #444;
  }
  .border-left-lightgray-1 {
    border-left: 1px solid lightgray;
  }
  .border-right-lightgray-1 {
    border-right: 1px solid lightgray;
  }
  th {
    background: #696f71;
    vertical-align: bottom;
    border-right: 1px solid #dddddd;
  }
  .header-size {
    max-height: 50px;
    width: 50px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #fff;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  th:first-child {
    border-left: 1px solid transparent;
  }

  th:last-child {
    border-right: 1px solid #000;
  }
  tbody tr:last-child td {
    border-bottom: 1px solid #000;
  }
  .fieldset-legend {
    width: 160px;
    margin: 0px;
    border: 1px solid #ddd;
    color: gray;
  }
  .checkBoxRolesPermisisons,
  .checkBoxRolesPermissionsAll {
    @extend %checkBoxRolesPermissionsAll;
  }
  .cb-modified {
    border: 1px solid red;
  }

  .fa-checkmark {
    font-family: "check-mark";
    font-style: normal;
    // font-weight: bold;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //font-size:  $default-font-size;
  }
  .fa-times {
    color: red !important;
  }
  .fa-check {
    font-weight: bold;
    color: #000;
  }

  tr:hover {
    background-color: #ffa !important;
  }
}

.table-fixed-head {
  overflow-y: auto;
}
.table-fixed-head thead tr th,
.table-fixed-head thead th {
  position: -webkit-sticky !important;; /* for Safari */
  position: sticky !important;;
  top: 0;
  z-index: 1;
}

.table {
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  //overflow: hidden;
}
.table-bordered {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  /* border-collapse: separate; */
  border-color: #dddddd #dddddd #dddddd -moz-use-text-color;
  border-image: none;
  border-radius: 4px 4px 4px 4px;
  border-style: solid solid solid none;
  border-width: 1px 1px 1px 0;
}
.table th,
.table td {
  text-align: center;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ffffff;
  padding: 5px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.close {
  color: #fff !important;
  font-size: $other-font-size-2-0;
}

//New code from old file
%dataGridRow {
  display: grid;
  grid-auto-flow: column;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  line-height: 1;
}

%dataGridColumn {
  display: grid;
  grid-auto-flow: column;
  line-height: 1;
}

#rolesPermissionTable {
  .datagrid {
    .row {
      @extend %dataGridRow;
      grid-template-columns: 4% 6% 2% 10% 2% 10% 10% 10% 12% 10% 10% 8% 6%;
      #icoEmployeeId,
      #icoCcaLoginAllowed {
        border-left: none !important;
        text-align: right !important;
      }
      #employeeNameId,
      #membershipId {
        border-right: none !important;
      }
    }
    .column {
      @extend %dataGridColumn;
      grid-template-columns: 4% 8% 12% 10% 10% 10% 12% 10% 10% 8% 6%;
    }

    .row__column label {
      @extend %class-for-tooltip;
    }
  }
  .checkBoxRolesPermissionsAll {
    @extend %checkBoxRolesPermissionsAll;
  }
}
#permissionTable {
}

#rolesTable {
  .datagrid .column {
    @extend %dataGridColumn;
    grid-template-columns: 5% 18% 35% 15% 10% 10% 7%;
  }

  .datagrid .row {
    @extend %dataGridRow;
    grid-template-columns: 5% 18% 35% 15% 10% 10% 7%;
  }
  .checkBoxRolesPermissionsAll {
    @extend %checkBoxRolesPermissionsAll;
  }
}
#permission-urls {
  border: none !important;
  .datagrid {
    .row {
      @extend %dataGridRow;
      border: none !important;
      grid-template-columns: 10% 80% 5% 5%;
    }
    .row:hover {
      background: none !important;
    }
    .row:nth-child(even) {
      background-color: inherit !important;
    }
  }
  .row__column {
    padding: 0 !important;
  }
}

.popupScroll {
  overflow-y: auto;
  max-height: 145px;
  overflow-x: hidden;
}
.popupScroll .row {
  font-size: $default-font-size;
}

.disabledLinkProp {
  color: gray;
  cursor: initial;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  pointer-events: none;
  text-decoration: none;
}

.orgRolesPermissionIco {
  float: left;
  margin-right: 3px;
  color: #147bc6;
  font-weight: bold;
  font-size: $other-font-size-3;
  cursor: pointer;
}

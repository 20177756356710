/* Tables */
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
// .table tbody tr:hover {
//   background: none repeat scroll 0 0 #e4e4e4;
// }
.table td:first-child,
.table th:first-child {
  border-left: 1px solid #dee2e6;
}
.table td:last-child,
.table th:last-child {
  border-right: 1px solid #dee2e6;
}

.table td,
.table th {
  padding: 0.4rem;
  vertical-align: top;
  //border-top: 1px solid #dee2e6;
}

.table th {
  font-weight: bold !important;
  color: #ffffff;
  border-right: 1px solid #ffffff;
}
.table td {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ffffff;
}
.table thead th {
  vertical-align: middle;
  border-bottom: 1px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 1px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}
.image-table td {
  padding: 0 !important;
}
.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background: none repeat scroll 0 0 #f5f5f5 !important;
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}
table.grid {
  width: 100%;
  border: none;
  background-color: #f1f1f1;
  padding: 0px;
}
table.grid td {
  border: 4px solid white;
  padding: 8px;
}

table.table tr {
  a,
  a label {
    cursor: pointer;
    color: var(--color-primary);
    outline: none !important;
    text-decoration: none !important;
    background-color: transparent !important;
  }
}

.card .table {
  margin-bottom: 0px;
}

.card .table td,
.card .table th {
  // padding-right: 1.5rem;
  // padding-left: 1.5rem;
}
.table.align-items-center td,
.table.align-items-center th {
  vertical-align: middle;
}

.table thead th {
  // font-size: $default-font-size !important;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  // letter-spacing: 1px;
  //text-transform: uppercase;
  border-bottom: 1px solid #e9ecef;
}
.table-flush td,
.table-flush th {
  border-right: 0;
  border-left: 0;
}
.table td,
.table th {
  white-space: normal;
  vertical-align: middle;
}

.table td a {
  text-decoration: none;
  color: #027bdf;
}

.table th {
  font-weight: 600;
}
.table-responsive {
  white-space: nowrap;
}
.table .thead-primary th {
  color: #fff;
  background-color: var(--table-Header-1);
  border-color: var(--table--border-1);
  padding: 0.4rem;
}

.thead-primary th:last-child {
  border-right: 1px solid var(--table--border-1);
}
.table .thead-secondary th {
  color: #fff;
  background-color: #75808a;
  border-color: #56616b;
}

.table .thead-success th {
  color: #fff;
  background-color: #15ca20;
  border-color: #03af0d;
}

.table .thead-danger th {
  color: #fff;
  background-color: #fd3550;
  border-color: #cc011c;
}

.table .thead-warning th {
  color: #fff;
  background-color: #ff9700;
  border-color: #da8100;
}

.table .thead-info th {
  color: #fff;
  background-color: #0dceec;
  border-color: #01b9d6;
}

.table .thead-dark th {
  color: #fff;
  background-color: #223035;
  border-color: #000000;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9eaea;
  border-color: #d6d6d6;
}
.table-primary {
  color: #fff;
  background-color: #2678c8;
}

.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: rgba(244, 245, 250, 0.25);
}

.table-secondary {
  color: #fff;
  background-color: #75808a;
}

.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: rgba(244, 245, 250, 0.25);
}

.table-success {
  color: #fff;
  background-color: #15ca20;
}

.table-success td,
.table-success th,
.table-success thead th {
  border-color: rgba(244, 245, 250, 0.25);
}

.table-danger {
  color: #fff;
  background-color: #fd3550;
}

.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: rgba(244, 245, 250, 0.25);
}

.table-warning {
  color: #fff;
  background-color: #ff9700;
}
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: rgba(244, 245, 250, 0.54);
}

.table-info {
  color: #fff;
  background-color: #0dceec;
}
.table-info td,
.table-info th,
.table-info thead th {
  border-color: rgba(244, 245, 250, 0.25);
}
.table-dark {
  color: #fff;
  background-color: #223035;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: rgba(244, 245, 250, 0.25);
}
.table-light {
  color: #212529;
  background-color: #e9eaea;
}
.table-light td,
.table-light th,
.table-light thead th {
  border-color: rgb(221, 222, 222);
}
.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.02);
}

.table .thead-dark-light th {
  color: #fff;
  background-color: #394F58;
  border-color: #32383e;
}


.capacity-table {
  table {
    th,
    td,
    label,
    input {
      font-size: 1.3rem;
    }
    td {
      border-left: 1px solid #000 !important;
      border-bottom: 1px solid #000 !important;
    }
    td:last-child {
      border-right: 1px solid #000;
    }
  }
  .cell-white {
    background: #fff !important;
    border: none !important;
    color: #1e4e9c !important;
    border-bottom: 1px solid #000 !important;
  }
  .cell-table-header {
    background: var(--table-Header-1);
    color: #fff !important;
    border: none !important;
    font-size: 1.3rem;
  }
  .preiod-row {
    label {
      font-weight: 700;
    }
    background: #dbe3ea;
    width: 110px;
  }
  .border-none {
    border: none !important;
  }
  .normal-cell {
    width: 110px;
    padding-top: 0;
    padding-bottom: 0;
    height: 3rem;
  }
}
/*Todo: h3 title color for capacity, need to change according to convention later*/
.h3-title{
  font-size: 1.5rem !important;
  color: var(--table-Header-1);
}

/* Styles for sortable columns */

%sortIcon {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: $other-font-size-3;
  position: absolute;
  right: 0.2rem;
  top: 0.3rem !important;
}

th.sorting:before {
  @extend %sortIcon;
  content: "\f0de";
  color: #fff;
}
th.sorting:after {
  @extend %sortIcon;
  content: "\f0dd";
  color: #fff;
}

th.sorting_asc:before {
  @extend %sortIcon;
  content: "\f0de";
  color: #ccc;
}
th.sorting_asc:after {
  @extend %sortIcon;
  content: "\f0dd";
  color: #fff;
}

th.sorting_dsc:before {
  @extend %sortIcon;
  content: "\f0de";
  color: #fff;
}
th.sorting_dsc:after {
  @extend %sortIcon;
  content: "\f0dd";
  color: #ccc;
}
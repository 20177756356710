.color-primary {
  color: #001e60 !important;
}
.bg-granted {
  background-color: #e8cb15;
  color: #513e3e !important;
}
.bg-used {
  background-color: #26630e;
}
.bg-reverted {
  background-color: #af0000;
}
.bg-antiquewhite {
  background-color: antiquewhite !important;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.align-self-end {
  align-self: flex-end;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.flex-space-around {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.justify-content-center {
  justify-content: center;
}
.justify-space-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.divider {
  width: 2px;
  margin: 6px 0;
  background: #ccc;
}
.font-color-granted {
  color: #e8cb15;
}
.font-color-used {
  color: #26630e;
}
.font-color-reverted {
  color: #af0000;
}
.font-primary {
  color: #000000;
}
.font-color-1 {
  color: #001e60;
}
.font-color-disabled {
  color: #ccc;
}
.border-color-primary {
  border-color: #001e60 !important;
}
.filter-gray {
  filter: grayscale(100%);
}
.fit-content {
  width: fit-content !important;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.margin-1 {
  margin-bottom: 1rem;
}
.gap-1 {
  column-gap: 1rem;
}
.gap-0-5 {
  column-gap: 0.5rem;
}
.row-gap-1 {
  row-gap: 1rem !important;
}

.lbl-link {
  color: #001e60;
  font-weight: 600;
  cursor: pointer;
}
.txt-underline {
  text-decoration: underline;
}
.common-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #000000;
}
.common-title-secondary {
  font-size: 1.2rem;
  font-weight: 500;
  color: #585858;
}
.font-size-all {
  font-size: 1.2rem !important;
}
.font-size-1 {
  font-size: 1rem !important;
}
.font-size-1-1 {
  font-size: 1.1rem !important;
}
.font-size-1-3 {
  font-size: 1.3rem !important;
}
.font-size-1-6 {
  font-size: 1.6rem !important;
}
.font-size-2 {
  font-size: 2rem !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.width-2 {
  width: 2rem;
}
.flex-size-1 {
  width: 10%;
}
.flex-size-2 {
  width: 20%;
}
.flex-size-3 {
  width: 30%;
}
.flex-size-3-3 {
  width: 33%;
}
.flex-size-4 {
  width: 40%;
}
.flex-size-4-8 {
  width: 48%;
}
.flex-size-7-5 {
  width: 75%;
}
.height-150 {
  height: 150px;
}
.height-auto {
  height: auto;
}
.flex-grid {
  display: flex;
  //max-height: 140px;
  min-height: 140px;
  overflow-x: auto;
  margin-bottom: 2rem;
  .table-common {
    &.table {
      position: relative !important;
      z-index: 2 !important;
    }

    th,
    td {
      font-size: 1rem;
    }

    th {
      background: #dadada !important;
      color: #000000;
      border: 0.5px solid #eaeaea;
      position: -webkit-sticky; // for safari
      position: sticky;
      top: 0;
      left: 0;
      padding: 4px;
      z-index: 3;
    }
    tbody {
      td {
        border: 0.5px solid #eaeaea;
        padding: 2px;
      }
    }
    th {
      position: -webkit-sticky; // for safari
      position: sticky;
      z-index: 3;
      width: 200px;
      top: 74px;
    }
    td {
      position: relative;

      &:first-child {
        position: -webkit-sticky; // for safari
        position: sticky;
        left: 0px;
        z-index: 2;
        width: 200px;
      }
    }

    td:first-child {
      tr:nth-child(odd) & {
        box-shadow: inset -2px 0px rgba(black, 0.25);
      }

      tr:nth-child(even) & {
        box-shadow: inset -2px 0px rgba(black, 0.25);
      }
    }
  }
  th {
    position: -webkit-sticky;
    position: sticky;
    z-index: 3;
    width: 200px;
    top: 0 !important;
  }
  td {
    font-size: 1.2rem !important;
  }
  // .no-record-found {
  //   height: 100%;
  // }
}
.overflow-auto {
  overflow: auto;
  scrollbar-width: thin;
}
.overflow-auto::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.overflow-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.overflow-auto::-webkit-scrollbar-thumb {
  background: #aaa;
}

.overflow-auto::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.micro-loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #001e60;
  border-bottom: 16px solid #001e60;
  width: 50px;
  height: 50px;
  margin: auto;
  -webkit-animation: spin1 2s linear infinite;
  animation: spin 2s linear infinite;
}
.micro-loader-input {
  width: 5px !important;
  height: 5px !important;
  position: absolute;
  right: 5px;
  top: 5px;
}
@-webkit-keyframes spin1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.badge {
  // background-color: #fefefe;
  // color: white;
  // padding: 4px 8px;
  // text-align: center;
  // border-radius: 5px;

  display: inline-block;
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.img-icon {
  display: flex;
  padding: 0.8rem;
  align-items: center;
  gap: 0.6rem;
  border-radius: 3.2rem;
  background: #e6e9ef;
  cursor: pointer;
  color: #001e60;
  &:hover {
    background-color: #001e60;
    i {
      color: #fff;
    }
    img {
      &.invoice-icon {
        content: url("../../assets//img/input/invoice-hover.svg");
      }
      &.transaction-icon {
        content: url("../../assets//img/input/transaction-hover.svg");
      }
      &.voucher-icon {
        content: url("../../assets//img/input/coupon-percent-hover.svg");
      }
      &.car-history-icon {
        content: url("../../assets//img/input/car-history-light.svg");
      }
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

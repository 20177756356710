.login__container {
  background-image: url(../../assets/img/authentication-img.png);
  background-color: var(--color-primary);
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
  justify-content: center;
  position: absolute;
  &--flex1 {
    flex: 1;
  }
}

.aligner {
  display: flex;
  &__cmmonBorder--1 {
    flex: 0.4 0 0%;
    background: transparent;
  }
}
.flashyCar {
  flex: 0.7 0 1%;
  &__img {
    width: 125%;
  }
}
.loginBox {
  background-color: $color-grey-light-1;
  flex: 0.5 0 1%;
  padding: 3.5rem 2.25rem 3.5rem 2.25rem;
  height: fit-content;
  height: -moz-fit-content;
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
  &__logo {
    text-align: center;
    padding-bottom: 2rem;
    &--img {
      height: 80px;
      display: inline-block;
    }
  }
  &__orLine {
    margin-bottom: 2rem;
  }
  &__submit {
    display: grid;
    grid-auto-flow: column;
    &--forgot {
      text-align: end;
      &--link {
        font-size: 1rem !important;
        padding: 0 !important;
        border: none !important;
      }
    }
  }
  &__GButton {
    text-align: center;
    &--btn {
      width: 200px;
    }
  }
}
#loginButton {
  font-size: $other-font-size-4;
  width: 8rem;
}
.or-line {
  margin-top: 10px;
  color: #636363;
  overflow: hidden;
  text-align: center;
  font-size: $other-font-size-4;
}
.or-line:before,
.or-line:after {
  background-color: #f89406;
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 50%;
  opacity: 0.5;
}

#userProfile {
  .form-group {
    height: 2rem;
  }
  .eyeIconPosition {
    top: -2px !important;
  }
  .form-footer {
    border-top: 0.1rem solid #d3dce9 !important;
    padding-top: 1rem !important;
    margin-top: 1rem !important;
  }
  .padding-none-edit-label {
    padding-left: 0.6rem !important;
    padding-top: 0.25rem !important;
  }
}
.rw-widget-picker input[type="text"] {
  padding: 0 0.3rem;
}

#global-search-area-id.search-area-container {
    position: absolute;
    background: white;
    top: 2.7rem;
    z-index: 501;
    width: 55rem;
    max-width: 60rem;
    height: 7.5rem;
    padding-right: 0rem;
    padding-left: 0rem;
    border: 0.1rem solid #acaaaa;
    border-top: 0.15rem solid #d8d8d8;
    border-radius: 0rem 0rem 0.25rem 0.25rem;

    .search-area-item-container {
            display: flex;
            justify-content: space-evenly;
            top: 1.9rem;
            left: -5rem;
            height: 4rem;
            border-radius: 0 0 0.5rem;
            border-top: 0.05rem solid #d8d8d8;

            .btn {
                border-radius: 1.2rem;
                height: 2rem;
                padding-top: 0.3rem;
            }
    
            .btn-outline-primary:hover,
            .btn-outline-primary:focus,
            .btn-outline-primary:active {
                background-color: var(--btn-cb-back-1) !important;
                border-color: var(--color-mainmenu) !important;
                background-image: linear-gradient(to bottom, var(--color-mainmenu) 0%, var(--color-mainmenu-1) 44%, var(--color-mainmenu-2) 100%);
                opacity: 1;
            }
            .btn-outline-primary:focus-visible {
                outline: 2px solid transparent
            }
        }

        .all-item {
            display: flex;
            justify-content: center;
            padding: .3rem 0 .5rem !important;
            position: relative;
            align-items: center;
            border-radius: 0.3rem 0;
            .all-label {
                font-size: 1rem;
                position: absolute;
                bottom: -1.5rem;
                text-align: center;
                color: #9d3135;
            }
            span.btn {
                color: #fff !important;
                // background: linear-gradient(to bottom,#9d3135 0,#C52529 44%,#731414 100%);
                background: #9d3135;
                border-color: #c52529 !important;
                // background: darkred;
                // opacity: 0.45;
                
            }
        }
}

#txt-global-search {
    &:focus {
        border-color: #d8d8d8 !important;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(82, 168, 236, 0.6) !important;
        outline: 0;
    }

    &.border-radius-global-search-box {
        border-radius: 0.25rem 0.25rem 0rem 0rem !important;
        border: 0.1rem solid #acaaaa !important;
    }
}
.modern-modal {
  font-family: "Inter";
  .modal-content {
    margin: 0 !important;
    //padding: 0.8rem 1.6rem 1.6rem !important;
    padding: 0.2% 1% 1% !important;
    border-radius: 1.6rem;
    height: 100%;
    .modal-header {
      //padding: 0.5rem !important;
      margin: 0rem 0rem 1.2rem 0rem !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      z-index: 123;
      width: 98%;
      background: #fff;
      .modal-title {
        font-weight: 600;
        color: #000000;
        margin: 0 !important;
        padding: 0 !important;
      }
      .close {
        font-size: 3rem;
        color: #000000 !important;
        opacity: 1;
        font-weight: 500;
        margin: 0 !important;
        padding: 0 !important;
      }
    }
    .modal-body {
      padding: 0 !important;
      display: flex;
      flex-direction: column;
      min-height: 100px;
      flex-grow: 1;
      justify-content: space-between;
      margin-top: 50px;
      overflow: auto;
    }
    .btn-container {
      display: flex;
      justify-content: space-between;
      column-gap: 1%;
      margin-top: 1rem;
      button {
        min-width: 48%;
        border-radius: 0.8rem;
        height: 3.5rem !important;
        padding: 0 !important;
        margin: 0 !important;
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
  }
}

.discount-history-modal {
  max-height: 700px !important;
  min-height: 700px !important;
}

.res-location-quick-modal-container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 1.2rem 1.6rem;

  .location-quick-modal-left-section,
  .location-quick-modal-right-section {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    flex-direction: column;
  }

  .location-info-section,
  .provider-info-section,
  .business-hour-section {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 16px;
    border: 1px solid #f1f1f1;
    background: #fff;
    min-width: 36rem;
    min-height: 20.5rem;
  }

  .business-hour-section {
    border: none;
  }

  .location-info-items-container,
  .provider-info-items-container,
  .business-hour-items-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.4rem;
  }

  .business-hour-section {
    width: 100%;
    border-radius: 1.6rem;
    background: var(--color-grey-light-5);
  }

  .business-hour-items-container {
    width: 100%;
    gap: 0.4rem;

    .columnTitle,
    .day-row-container {
      display: flex;
      width: 100%;
      padding: 1rem 1.6rem;
      align-items: center;
      gap: 1.2rem;
      border-radius: 12px;

      .vertical-line {
        height: 2rem !important;
        width: 0.1rem;
        margin: 0rem 0.3rem 0;
      }
    }

    .columnTitle {
      background: #334b80;

      .vertical-line {
        border-color: #546894;
      }

      .columnName {
        color: #fff;
        min-width: 7.2rem;
        height: 1.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.8rem;
        /* 150% */
        letter-spacing: 0.0018rem;
      }
    }

    .day-row-container {
      background: #fff;
      border-radius: 1.2rem;

      .vertical-line {
        border-color: #eaeaea;
      }

      .dayColumn,
      .valueColumn {
        color: #000;
        font-size: 1.2rem;
        line-height: 1.8rem;
        /* 150% */
        letter-spacing: 0.0018rem;
      }

      .dayColumn {
        min-width: 7.2rem;
        height: 1.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 600;
        position: relative;

        .info-icon {
          position: absolute;
          right: 0;
        }
      }

      .valueColumn {
        font-weight: 500;
        white-space: nowrap;
      }
    }

    .fox-res-default-page {
      padding: 9.8rem 0;
    }
  }

  .res-items {
    display: flex;
    align-items: flex-start;
    gap: 0.4rem;
    // padding-left: 1.6rem;

    .field-title {
      color: #000;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.8rem;
      letter-spacing: 0.018px;
      width: 6.4rem;
    }

    .field-value {
      display: flex;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.8rem;
      letter-spacing: 0.018px;
      gap: 1rem;
      max-width: 26rem;
    }
  }

  .sectionTitle {
    color: #000;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.4rem;
    letter-spacing: 0.024px;
  }
}

.customer-reservation-history {
  .modal-body {
    padding: 0;
  }

  .modal-content {
    padding: 1rem 2rem 2rem;
  }

  .modal-header {
    border: none;
  }

  .history-container {
    display: flex;
    padding: 1.6rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.6rem;
    border-radius: 1.6rem;
    border: 1px solid #eaeaea;
    background: var #fff;
  }

  .title-section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    // gap: 928px;
  }

  .common-title {
    color: var(--Neutral-BNW-black, #000);
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.8rem;
    /* 140% */
  }

  .search-box {
    input[type="text"] {
      display: flex;
      padding: 1.3rem 2rem;
      align-items: flex-start;
      gap: 1rem;
      border-radius: 1rem;
      background: #fff;
      min-width: 45rem;
      font-size: 1.4rem;
      color: #737373;
      font-style: normal;
      font-weight: normal;
      line-height: 2rem;
      /* 142.857% */
      letter-spacing: 0.0021rem;
      background: #e6e9ef;
    }

    .fa-times {
      font-size: 1.4rem;
    }
  }

  #table-container {
    display: flex;
    width: 100%;
  }

  .flex-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ddd;
  }

  .flex-table-header,
  .flex-table-row {
    display: flex;
    border-bottom: 1px solid #ddd;

    .field-value {
      word-break: break-all;
    }
  }

  .flex-table-header {
    background-color: var(--color-grey-light-5);
    font-weight: bold;

    .flex-table-cell {
      height: 5.2rem;
      align-content: center;
    }
  }

  .flex-table-row:nth-child(odd) {
    background-color: #fafafa;
  }

  .flex-table-cell {
    flex: 1;
    padding: 8px;
    text-align: left;

    :nth-child(3) {
      width: 19.5rem !important;
    }
  }

  .flex-table-cell {
    border-right: 1px solid #ddd;

    &:last-child {
      border-right: none;
    }
  }

  .field-value {
    display: flex;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8rem;
    gap: 0.8rem;

    &.link-enabled {
      color: #001e60;
      cursor: pointer !important;

      label {
        cursor: pointer !important;
      }

      text-decoration: underline;
    }

    &.customer-note {
      width: 18rem;
    }
  }
}

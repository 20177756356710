.auto-search-ctrl {
  width: 400px;
  border: 1px solid #001e60;
  border-radius: 5px;
  height: 35px;
  padding: 0.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;

  i {
    font-size: 1.8rem;
    color: #000;
  }

  div {
    width: calc(100% - 30px);
  }

  input {
    border: none;
    width: 300px;
    /* padding: 1rem; */
    background: transparent;
    box-shadow: none;
    height: 20px;
    margin-left: 8px;
    margin-top: 10px;
    font-size: 1.2rem;
  }

  input:focus {
    border: none;
    box-shadow: none !important;
    outline: 0;
    background: transparent !important;
  }

  .dropdown {
    position: absolute;
    width: 95%;
    padding: 0;
    text-align: left;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid #001e60;
    border-top: none;
    border-top: none;
    z-index: 2;
    top: 40px;
    left: 10px;
    //   padding: 1rem;
    border-radius: 0 rem 1rem;

    .item {
      display: block;
      cursor: pointer;
      font-size: 12px;
      padding: 5px;
      width: 100%;
      border-bottom: 1px solid #ccc;
    }

    .item.selected-item {
      background-color: #ecf0f1;
      color: #000;
    }

    .item:hover {
      background-color: #ecf0f1;
      color: #766f6f;
    }
  }

  .arrow {
    position: absolute;
    right: 15px;
    font-size: 2.2rem;
    top: 8px;
    cursor: pointer;
  }

  .clear {
    position: absolute;
    right: 36px;
    font-size: 1.5rem;
    top: 12px;
    cursor: pointer;
    color: red;
  }

  .caption {
    position: absolute;
    left: 40px;
    top: 1px;
  }
}

.periodic-ctrl {
  width: 18rem;
  border: 1px solid #001e60;
  border-radius: 0.5rem;
  height: 3.2rem;
  padding: 0.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;

  i {
    font-size: 1.8rem;
    color: #000;
  }

  .rw-widget {
    width: 15.5rem;
    margin-top: 3px;
  }

  .rw-input {
    text-align: center;
  }

  input {
    border: none;
    width: 300px;
    /* padding: 1rem; */
    background: transparent;
    box-shadow: none;
    height: 20px;
    margin-left: 8px;
    margin-top: 10px;
    font-size: 1.2rem;
  }

  .rw-state-focus>.rw-widget-container:hover {
    border: none;
    box-shadow: none !important;
    outline: 0;
    background: transparent !important;
  }

  .rw-widget-container {
    border: none;
  }

  .rw-widget-input,
  .rw-filter-input {
    box-shadow: none;
  }

  .rw-state-selected {
    background-color: #001e60;
    border-color: #001e60;
    color: white;
  }

  .rw-list {
    max-height: 300px;
    margin-top: 10px;

    li:first-child {
      border-top: 1px solid #ccc;
    }
  }

  .rw-list-option {
    height: auto;
    border-bottom: 1px solid #ccc;
    padding: 0.8rem 5px;
    text-align: center;
  }
}

.widget-ctrl {
  border: 1px solid #001e60;
  border-radius: 5px;
  height: 30px;
  padding: 0.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;

  .caption {
    left: 26px;
    position: absolute;
    top: -9px;
    background: #fff;
  }

  i {
    font-size: 1.8rem;
    color: #000;
  }

  .rw-widget {
    width: 300px;
    margin-top: 3px;
  }

  .rw-filter-input {
    position: relative;
    margin: 0;
    padding: 10px 5px 0px 5px;
    width: 100%;

    .rw-input-reset {
      width: 100%;
      margin: auto;
      padding-left: 5px;
      height: 2.5rem;
      border: 1px solid;
    }

    .rw-select {
      position: absolute;
      right: 25px;
      top: 5px;
    }
  }

  .rw-list {
    max-height: 300px;
    margin-top: 10px;

    li:first-child {
      border-top: 1px solid #ccc;
    }
  }

  .rw-list-option {
    height: auto;
    border-bottom: 1px solid #ccc;
    padding: 1rem 5px;
  }

  .rw-dropdown-list-input {
    height: 25px;
    cursor: pointer;
  }

  input {
    border: none;
    // width: 300px;
    /* padding: 1rem; */
    background: transparent;
    box-shadow: none;
    //height: 30px;
    margin-left: 8px;
    margin-top: 10px;
    font-size: 1.2rem;
  }

  .rw-state-focus>.rw-widget-container:hover {
    border: none;
    box-shadow: none !important;
    outline: 0;
    background: transparent !important;
  }

  .rw-widget-container {
    border: none;
  }

  .rw-widget-input,
  .rw-filter-input {
    box-shadow: none;
  }

  .rw-state-selected {
    background-color: #001e60;
    border-color: #001e60;
    color: white;
  }
}
/* (1920x1080) Full HD Display */
// @media screen and (max-width: 2880px) {
//   .fz-1 {
//     font-size: 1.5rem;
//   }
//   // .nav-main > li > a {
//   //   font-size: 1.5rem;
//   // }
// }
// @media screen {
//   * {
//     font-size: 1.2rem;
//   }
// }
@mixin font-size() {
  @media only screen and (max-width: 3840px) {
    font-size: 1.4rem;
  }
  @media only screen and (max-width: 3200px) {
    font-size: 1.3rem;
  }
  @media only screen and (max-width: 2880px) {
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 2560px) {
    font-size: 1.1rem;
  }
  @media only screen and (max-width: 2048px) {
    font-size: 1.1rem;
  }
  @media only screen and (max-width: 1920px) {
    font-size: 1.1rem;
    #customer-info .mobile-number, .customer-info .mobile-number{
      min-width: 3rem !important;
    }
    #customer-info .mobile-code, .customer-info .mobile-code{
      margin-bottom: 0.2rem;
    }
    #new-res-rental-edit .zoom{
      max-width: 550px;
      min-width: 550px;
    }
    #new-res-rental-edit #customer-info .enabled-field-for-tooltip label,  #new-res-rental-edit .customer-info .enabled-field-for-tooltip label{
      width: 10rem;
    }
    #reservation-rental-summary .column-gap-1{
      column-gap: 2rem;
    }
  }
  @media only screen and (max-width: 1536px) {
    font-size: 0.8rem;
    form .form-header,h1 {
      font-size: 1rem;
    }
    .col-form-label,label{
      font-size: .8rem;
    }
    #personal-info .form-header .hbCheckBox {
      line-height: 1.2;
    }
    [class*=icheck-] > input:first-child + label::before, [class*=icheck-] > input:first-child + input[type=hidden] + label::before {
      height: 1rem;
      width: 1rem;
    }
    .nav-main > li > a {
      font-size: .9rem !important;
    }
  }
  @media only screen and (max-width: 1366px) {
    font-size: 0.8rem;
    form .form-header {
      font-size: 1rem;
    }
    .col-form-label,label{
      font-size: 1rem;
    }
    [class*=icheck-] > input:first-child + label::before, [class*=icheck-] > input:first-child + input[type=hidden] + label::before {
      height: 1rem;
      width: 1rem;
    }
    #personal-info .form-header .hbCheckBox {
      line-height: 1.2;
    }
    .nav-main > li > a {
      font-size: .9rem !important;
    }
  }
  @media only screen and (max-width: 900px) {
    font-size: 0.8rem;
    form .form-header {
      font-size: .8rem;
    }
    .col-form-label,label{
      font-size: .8rem;
    }
    #personal-info .form-header .hbCheckBox {
      line-height: 1.2;
    }
    [class*=icheck-] > input:first-child + label::before, [class*=icheck-] > input:first-child + input[type=hidden] + label::before {
      height: 1rem;
      width: 1rem;
    }
    .nav-main > li > a {
      line-height: 1.1rem;
      font-size: .8rem;
    }
  }
}

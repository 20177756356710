#rentalTable .datagrid {
  .row {
    @extend %dataGridRow;
    grid-template-columns: 7% 7% 6% 6% 5% 8% 5% 5% 8% 7% 7% 7% 10% 6% 6%;
  }
  .column {
    @extend %dataGridColumn;
    grid-template-columns: 7% 7% 6% 6% 5% 8% 5% 5% 8% 7% 7% 7% 10% 6% 6%;
  }
}

.input-ctrl {
  position: relative;
  img,
  i {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 1;
  }
  .drop-clear-icon {
    top: 1.3rem;
    right: 2.5rem !important;
  }
  .place-holder {
    position: absolute;
    color: #585858;
    font-size: 1rem;
    left: 3.6rem;
    top: 0.1rem;
    z-index: 1;
  }
  .caret {
    position: absolute;
    top: 1.3rem;
    left: 86%;
    font-size: 1.7rem;
    color: #001e60;
    cursor: pointer;
  }
  label.required::after {
    font-weight: bold;
    color: #d06161;
    content: "*";
    position: absolute;
    font-size: 1.2rem;
    top: -0.2rem;
    padding-left: 0.1rem;
  }
  input,
  select,
  textarea,
  .rw-widget-picker {
    background-color: #e6e9ef !important;
    border-color: #0000;
    border-radius: 0.8rem !important;
    color: #000;
    font-family: "Inter" !important;
    font-size: 1.2rem;
    height: 4.2rem !important;
    padding: 1.6rem 0.8rem 0.4rem 3.6rem;
    padding-left: 3.6rem !important;
    &::placeholder {
      color: #737373 !important;
      font-size: 1.2rem !important;
      font-weight: 400;
    }
    input {
      width: 100%;
    }
    .rw-input {
      padding-left: 3.5rem !important;
      padding-top: 1rem !important;
      font-weight: 600;
    }
    // .rw-input,
    // .rw-select,
    // .rw-btn-select {
    //   &:hover {
    //     background-color: #e6e9ef !important;
    //   }
    // }
  }

  textarea {
    height: 6rem !important;
    padding: 1.4rem 0.8rem 0.4rem 3.6rem !important;
    background: #e6e9ef !important;
    :disabled {
      background: #e6e9ef !important;
    }
  }
  .error-field {
    border-radius: 0.8rem !important;
    border: 1px solid #d06161 !important;
    &:focus {
      box-shadow: 0 1px 1px rgba(101, 6, 6, 0.075) inset,
        0 0 8px rgba(236, 82, 143, 0.6) !important;
      outline: 0 !important;
    }
    input {
      border: none !important;
      &:focus {
        border: none !important;
        box-shadow: 0 1px 1px rgba(101, 6, 6, 0.075) inset,
          0 0 8px rgba(236, 82, 143, 0.6) !important;
        outline: 0 !important;
      }
    }
  }
  .rw-state-disabled {
    border-radius: 0.8rem;
  }
  .rw-popup-container {
    .rw-popup {
      border-radius: 1rem 1rem 0rem 0rem;
    }
  }
  .rw-filter-input {
    height: auto !important;
  }
  .rw-filter-input input {
    height: auto !important;
  }
  .rw-widget-picker {
    padding: 0rem 0rem 0rem 0rem !important;
    &.rw-filter-input {
      padding: 0.3rem 0rem 0.3rem 0.8rem !important;
      input {
        padding: 0.3rem 0rem 0.3rem 0.8rem !important;
      }

      .rw-select .rw-btn-select {
        font-size: 1.6rem;
      }
    }
    input[role="combobox"] {
      padding-top: 1rem !important;
    }
  }
  .rw-combobox {
    .rw-select-bordered {
      position: absolute;
      border: none;
      top: 0;
      right: 2rem;
      button {
        background: none;
        border: none;
        &:hover {
          background: none;
          border: none;
        }
        &:hover {
          background: none;
          border: none;
        }
      }
    }
  }
  //// CSS regarding include country dropdown along with flag
  .react-tel-input {
    input {
      font-size: 1.2rem !important;
      padding-top: 1rem !important;
    }
    .country-list {
      margin-top: -8px;
      margin-right: 5px;
    }
  }
  .code-with-flag {
    .invalid-number-message {
      position: absolute;
      z-index: 1;
      font-size: 13px;
      left: 70px;
      top: -8px;
      background: #faf0f0;
      padding: 0 2px;
      color: #de0000;
    }
    input {
      width: 100%;
      background: #e6e9ef;
      padding-left: 8rem !important;
    }
    .special-label {
      display: block !important;
      top: 1.5rem !important;
      background: transparent !important;
      left: 20px !important;
      font-size: 1.2rem !important;
      width: 4.3rem;
      text-align: center;
      pointer-events: none;
    }

    .special-label:has(~ .open) {
      z-index: 15 !important;
    }

    .flag-dropdown {
      padding: 0.9rem 0rem;
      top: 0.1rem;
      height: 4rem;
      left: 1px;
      border-radius: 0.8rem 0 0 0.8rem !important;
      background-color: transparent;
      border: none;
      // background: linear-gradient(
      //   to bottom,
      //   #d5deec 0%,
      //   #c7cbd3 44%,
      //   #a9abac 100%
      // );
      .search-box {
        padding: 5px !important;
        margin: 0 !important;
        width: 95%;
      }
      .search-emoji {
        position: absolute;
        right: 2.2rem;
        top: 2rem;
      }
      .selected-flag {
        width: 65px;
        padding: 0 0 0 0.5rem;
        top: -0.5rem;
        height: 4rem;
        border-radius: 0.8rem 0 0 0.8rem !important;

        .flag {
          margin-top: -6px;
        }

        .arrow {
          margin-top: -2px;
          left: 50px;
        }

        &:hover,
        &:focus {
          // outline-color: var(--btn-cb-back-1) !important;
          // border: var(--btn-cb-back-1) !important;
          background: none;
        }

        &.open {
          &:hover {
            border: 0.1rem solid transparent;
          }
        }
      }

      &.open {
        .selected-flag {
          // height: 2.3rem;
          background: transparent !important;

          &:hover {
            border: none !important;
          }

          background: none;
        }
      }
    }
  }
  .icon {
    background: #001e60;
    color: #fff;
    border-radius: 50%;
    padding: 0.5rem;
  }
  .clear-icon {
    position: absolute;
    right: 1rem;
    font-size: 1.5rem;
    left: auto;
    color: red;
    cursor: pointer;
  }
}
.loc-ctrl {
  position: relative;
  .rw-widget-input {
    .rw-select {
      display: none;
    }
  }
  .input-ctrl {
    cursor: pointer;
  }
  i {
    position: absolute;
    right: 0rem;
    top: 0;
    font-size: 2rem;
    cursor: pointer;
    //color: #001e60;
    height: 4rem;
    width: 3rem;
    line-height: 4rem;
  }
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.check-box-ctrl {
  // display: block;
  position: relative;
  padding-left: 2.5rem;
  //margin-bottom: 1.2rem;
  cursor: pointer;
  font-size: 1.4rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ .checkmark {
        background-color: #001e60;
        &:after {
          display: block;
        }
      }
    }
  }
  // &:hover input ~ .checkmark {
  //   background-color: #ccc;
  // }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    border: 1.5px solid #585858;
    border-radius: 0.5rem;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 0.6rem;
      top: 0.3rem;
      width: 0.5rem;
      height: 1rem;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  .checkmark-radio {
    border-radius: 5rem !important;
  }
}
.checkmark {
  height: 2rem;
  width: 2rem;
}
.disabled {
  color: #ccc;
  cursor: no-drop;
  .checkmark {
    border: 1.5px solid #ccc;
  }
}
.select-input-ctrl {
  position: relative;
  margin-bottom: 1rem;
  .si-input {
    width: 100%;
    input {
      height: 4rem !important;
      padding-left: 11.5rem !important;
    }
  }
  .si-select {
    position: absolute;
    border-radius: 0;
    top: 1px;
    height: 3.3rem;
    z-index: 1;
    padding: 0.2rem;
    select {
      color: #000;
      border: none;
      height: 3.3rem !important;
      &:focus {
        border-color: none !important;
        outline: none !important;
        box-shadow: none !important;
        border: none !important;
      }
      width: 110px !important;
      padding: 0.1rem 0rem 0 3.3rem !important;
    }
  }
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: $other-font-size-6;
  border: none;
  cursor: pointer;
}

%dropdow-menu {
}
.dropmenu {
  position: relative;
  display: inline-block;
  &__content {
    text-align: left;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 20rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 4.9rem;

    &--link {
      color: black;
      padding: 8px;
      text-decoration: none;
      display: block;
      border-bottom: 1px solid #ccc;
      cursor: pointer;
      text-align: left;
      &--logo {
        height: 2.5rem;
        width: 5rem;
        background-size: cover;
        backface-visibility: hidden;
        display: inline-block;
        vertical-align: middle;
      }

      &--text {
        text-align: right;
        vertical-align: middle;
        padding-left: 1rem;
        display: inline-block;
      }
    }
    &--link:hover,
    &--link.active {
      background-color: var(--color-quicklink-hover-1);
      color: #fff;
      &#noOrgFound:active,
      &#noOrgFound:hover {
        background-color: transparent !important;
      }
    }
  }

  &--right {
    right: 0;
  }
}
.dropmenu:hover .dropmenu__content {
  display: block;
}

.dropmenu:hover .dropbtn {
  background-color: #3e8e41;
}
.clickDropMenu {
  position: relative;
}
%dropMenuShadow {
  box-shadow: 0rem 0.8rem 1.6rem 0rem rgba(0, 0, 0, 0.2);
  font-size: $default-font-size;
}
.dropdown-menu {
  @extend %dropMenuShadow;
}
.availabilityDropdown {
  @extend %dropMenuShadow;
  li {
    text-align: left;
    list-style: none;
    padding: 1rem 0 0rem 6.5rem;
    min-height: 7rem;
    position: relative;
    overflow: hidden;
    display: block;
    border-bottom: 0.1rem solid #ccc;
    margin: 0;
    vertical-align: top;
    strong {
      color: #777;
    }
    .quantity {
      display: block;
      margin-top: 0rem;
      //font-size:  $default-font-size;
      display: inline-block;
      display: -ms-inline-flexbox;
      display: inline-flex;
      margin-right: 1em;
      white-space: nowrap;
      vertical-align: top;
      white-space: nowrap;
      color: #111;
      font-weight: bold;
    }
  }
  a {
    display: block;
    margin-bottom: 0.5rem;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-primary);
    text-decoration: none;
    font-weight: bold;
    &:hover {
      color: #111;
    }
  }
  a.remove {
    position: absolute;
    right: 0;
    z-index: 9;
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    font-size: $other-font-size-5 !important;
    line-height: $other-font-size-9 !important;
    border-radius: 100%;
    color: #ccc;
    font-weight: bold;
    text-align: center;
    border: 0.2rem solid currentColor;
  }
  img {
    top: 0.5rem;
    position: absolute;
    left: 0;
    width: 5rem;
    height: 5rem;
    margin-bottom: 0rem;
    object-fit: cover;
    object-position: 50% 50%;
    border-style: none;
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    transition: opacity 1s;
    opacity: 1;
  }
}
.dropdown {
  .show {
    background: #fff !important;
    z-index: 1000 !important;
  }
  button {
    background: none;
    border: none;
  }
}
.historyDropDown {
  // .show {
  //   opacity: 0.9;
  // }
  #historyDropMenu {
    color: #111;
    border-radius: 1rem 0 1rem 1rem;
    @extend %dropMenuShadow;
    font-size: $default-font-size;
    .column__header_dropmenu {
      padding: 7px 4px !important;
      // background: #fff;
      color: #111;
      font-weight: 700;
      padding: 0;
      //border: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      // border-left: 1px solid #111;
      position: relative;
      text-align: left !important;
    }

    .dropmenu_no_record {
      color: #585858;
      text-align: center;
      font-weight: 500;
    }
    .row {
      border-left: none !important;
      border-right: none !important;
    }
    .row__column {
      border-left: none;
      border-right: none;
    }
    .form-header {
      color: #111;
      border-bottom: 0.1px solid #111;
      text-align: left;
      img {
        vertical-align: bottom;
        height: 3rem;
        width: 3rem;
      }
    }
  }
}
.drop-menu-border {
  border: 0.05rem solid var(--color-primary);
  border-radius: 1rem 0 1rem 1rem;
}

.parent-org-dropdown-caret {
  display: inline-block !important;
  vertical-align: middle;
  border-top: 1rem solid;
  border-right: 0.5rem solid transparent;
  border-left: 0.5rem solid transparent;
  color: #e3e3e3;
}

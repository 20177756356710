*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%

  @media only screen and (max-width: $bp-large) {
    font-size: 50%;
  }
}

@font-face {
  font-family: "check-mark";
  src: url("../fonts/check-mark.eot?294887");
  src: url("../fonts/check-mark.eot?294887#iefix") format("embedded-opentype"),
    url("../fonts/check-mark.woff?294887") format("woff"),
    url("../fonts/check-mark.ttf?294887") format("truetype"),
    url("../fonts/check-mark.svg?294887#check-mark") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-grey-dark-2);
  // background-image: linear-gradient(
  //   to right bottom,
  //   var(--color-primary-light),
  //   var(--color-primary-dark)
  // );
  // background-size: cover;
  // background-repeat: no-repeat;

  //min-height: 100vh;
  //overflow: hidden;
}

header {
  width: 100%;
  height: 9rem;
}

.h5,
h5 {
  //font-size: $default-font-size;
  line-height: 2.2rem;
}

.h6,
h6 {
  font-size: $other-font-size-1;
  line-height: 1.8rem;
}

a {
  color: var(--color-primary);
  cursor: pointer;
  text-decoration: none;
}

a:focus,
a:hover {
  text-decoration: none;
  color: var(--color-primary-light);
}

input:focus,
select:focus,
textarea:focus,
button:focus,
.picky__input:focus {
  border-color: rgba(82, 168, 236, 0.8) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(82, 168, 236, 0.6) !important;
  outline: 0;
}

.rw-multiselect .rw-input-reset:focus,
.rw-multiselect .rw-state-focus:focus {
  box-shadow: none !important;
  border-color: transparent !important;
  outline: 0;
}

.content-wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 7rem;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: auto;
  height: 90vh;
}

.content-wrapper1 {
  margin-left: 16rem;
  // padding-top: 70px;
  padding-left: 1rem;
  padding-right: 0rem;
  padding-bottom: 7rem;
  overflow-x: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  // min-height: 100vh;
}

.content-wrapper1.active {
  margin-left: 4rem;
}

.gridContainer {
  width: 100%;
  height: calc(100vh - 11.2rem);
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  // margin-right: -15px;
  // margin-left: -15px;
}

// .pb-2,
// .py-2 {
//   padding-bottom: 0.5rem !important;
// }
// .pt-2,
// .py-2 {
//   padding-top: 0.5rem !important;
// }
// body::-webkit-scrollbar {
//   width: 1em;
// }

// body::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }

// body::-webkit-scrollbar-thumb {
//   background-color: darkgrey;
//   outline: 1px solid slategrey;
// }

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: $other-font-size-6;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 25px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: $other-font-size-6;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__suggestion-match {
  color: red;
  font-weight: bold;
}

/*Thinner scrollbar CSS*/
body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/*Entire application body*/

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #aaa;
}

.body::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.tableScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.tableScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.tableScroll::-webkit-scrollbar-thumb {
  background: #aaa;
}

.tableScroll::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.thinnerScrollBar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.thinnerScrollBar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.thinnerScrollBar::-webkit-scrollbar-thumb {
  background: #aaa;
}

.thinnerScrollBar::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.tooltip {
  z-index: 10000 !important;
}

.common-tooltip {
  color: #fff;
  padding: 0.5rem 0.5rem;
  font-size: $other-font-size-1;
  border-radius: 5px;
  background-color: gray;
  font-family: Verdana, Arial, Helvetica, Tahoma, sans-serif;
  z-index: 999999;
  text-align: justify;
  line-height: 1.5;
  font-weight: normal;

  p {
    font-weight: normal;
  }

  //word-break: break-all;
  width: max-content;
  word-wrap: anywhere;
}

.eyeIconPosition,
.searchIconPosition,
.input-clear-icon-position,
.search-panel-inline-icon-position,
.search-area-dropdown {
  position: absolute;
  z-index: 2;
  display: block;
  width: 3.5rem;
  // height: 3.5rem;
  // line-height: 3.5rem;
  text-align: center;
  font-size: medium;
  padding: 0;
  right: 1rem;
  top: 0px;
  cursor: pointer;
}

.inputClassForEyeIcon {
  right: 0;
  top: 0.2rem;
}

.searchIconPosition {
  top: 0;
}

.search-panel-inline-icon-position {
  top: 3px;
  text-align: end;
  right: 5px;
  font-size: 10px;
  color: #ccc;
}

.footer {
  width: 100%;
  height: 16px;
  border-top: #eceded solid 1px;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0.5rem;
  z-index: 999999;
}

.footerBarSection {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 30% 70%;
}

.footerBar {
  display: grid;
  grid-auto-flow: column;
  justify-self: end;
  grid-gap: 0rem;
  list-style: none;

  img {
    height: 1.2rem;
  }

  .verticalLineSeparator {
    border-left: 0.1rem solid var(--color-primary);
    margin: 0.2rem 0.3rem 0 0.1rem;
    height: 1.2rem !important;
  }

  li {
    padding-right: 0.5rem !important;

    span {
      padding-left: 0.3rem !important;
    }
  }
}

.verticalLineSeparator {
  border-left: 0.1rem solid var(--color-primary);
  margin: 0.2rem 0.3rem 0 0.1rem;
  height: 1.2rem;
}

.vertical-line {
  border-left: 0.1rem solid var(--color-primary);
  height: 100%;
  width: 0;
  display: inline-block;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.height-auto {
  height: auto !important;
}

.img-thumbnail {
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
}

/* firefox */
//firefox

body {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

#main-content-wrapper1 {
  scrollbar-width: thin !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.impersonate-border {
  border: 3px solid var(--impersonate-mode-outer-color);
  border-top: none;
}

.sidebar-impersonate-border {
  border-left: 3px solid var(--impersonate-mode-outer-color);
}

.rw-multiselect-tag {
  color: #fff;
  background-color: #6e8ebf;
}

%landmark-table-template-columns {
  grid-template-columns: 15% 20% 15% 20% 20% 10%;
}

#landmarksTable .datagrid {
  .row {
    @extend %dataGridRow;

    #shortCodeIcon {
      border-right: none !important;
      text-align: left !important;
    }

    #landmarkNameId {
      border-left: none !important;
    }

    #landmarkCodeIconId {
      text-align: left !important;
    }

    #landmarkCodeId {
      border-right: none !important;
    }

    grid-template-columns: 13% 2% 2% 18% 15% 20% 20% 10%;
  }

  .column {
    @extend %dataGridColumn;
    @extend %landmark-table-template-columns;
  }

  .row__column label,
  .row__column a,
  .row__column span {
    @extend %class-for-tooltip;
  }
}

%shuttle-trips-table-template-columns {
  grid-template-columns: 10% 10% 14% 10% 12% 10% 10% 15% 5% 4%;
}

#shuttleReportTable {
  .datagrid {
    .row {
      @extend %dataGridRow;
      grid-template-columns: 10% 10% 12% 2% 10% 12% 10% 10% 15% 5% 4%;
    }

    .column {
      @extend %dataGridColumn;
      @extend %shuttle-trips-table-template-columns;
    }

    .row__column label,
    .row__column a,
    .row__column span {
      @extend %class-for-tooltip;
    }

    #icoEmployeeId {
      border-left: none !important;
    }

    #driverName {
      border-right: none !important;
    }
  }

  .shadow {
    background-color: #f9f9f9;
    border: none;
    box-shadow: 10px 5px 10px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
  }

  .round-cicle {
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
  }

  .details-container {
    border: 1px solid #ccc;
    border-top: 0;
    border-bottom: 0;
    padding: 1.5rem;
    position: relative;
  }

  .time-container {
    @extend .shadow;
    align-items: center;
    position: relative;
    border-radius: 0.5rem;
    min-height: 8rem;
    border: 0.5rem solid #394f58;
  }

  .speed-container {
    @extend .shadow;
    @extend .round-cicle;
  }

  .multicolor-bar {
    width: 100%;
  }

  .status-container {
    @extend .shadow;
    position: relative;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;

    :first-child {
      border-radius: 0.5rem 0 0 0.5rem;
    }

    :last-child {
      border-radius: 0 0.5rem 0.5rem 0;
    }

    .shuttle-status-bar-label {
      position: absolute;
      font-size: 10px;
      color: black;

      &.odd-item {
        top: -5rem;
      }

      &.even-item {
        top: 5rem;
      }
    }

    .no-trip {
      color: gray;
      align-content: center;
    }
  }

  .status-bar-green {
    background: #77a659;
  }

  .status-font-color-green {
    color: #77a659 !important;
  }

  .status-bar-red {
    background: #d26d6a;
  }

  .status-font-color-red {
    color: #d26d6a !important;
  }

  .status-bar-yellow {
    background: #efc54d;
  }

  .status-font-color-yellow {
    color: #efc54d !important;
  }

  .status-bar-blue {
    background: blue;
  }

  .status-font-color-blue {
    color: blue !important;
  }

  .expand-bg-color {
    background: rgba(173, 191, 222, 0.2901960784);
  }

  .selected-row-color {
    background-color: #adbfde !important;
  }
}

.filled-circle-shuttle {
  font-family: sans-serif;
  font-size: $other-font-size-1;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-weight: normal;
  width: 1.8rem;
  height: 1.8rem;
  line-height: 1.8rem;
  -moz-border-radius: 0.9rem;
  -webkit-border-radius: 0.9rem;
  border-radius: 0.9rem;
  background: var(--color-primary);
}

%shuttle-table-template-columns {
  grid-template-columns: 10% 15% 14% 17% 15% 16% 13%;
}

#shuttleTable {
  .datagrid {
    .row {
      @extend %dataGridRow;
      @extend %shuttle-table-template-columns;
    }

    .column {
      @extend %dataGridColumn;
      @extend %shuttle-table-template-columns;
    }

    .row {
      grid-template-columns: 8% 2% 15% 2% 12% 17% 15% 16% 13%;

      #shuttleCodeIconId,
      #shuttleName {
        border-left: none !important;
      }

      #shuttleCodeId,
      #shuttleIconColor {
        border-right: none !important;
      }
    }

    .row__column label,
    .row__column a,
    .row__column span {
      @extend %class-for-tooltip;

      i {
        cursor: pointer;
      }
    }
  }

  .pagination__displayperpage {
    i {
      font-size: 2rem;
      cursor: pointer;
    }
  }
}

#shuttle-page,
#landmark-info {
  .form-group {
    height: 2rem;
  }
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

.leaflet-marker-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.leaflet-popup-content {
  line-height: 1;
  margin: 5px 5px 5px 5px !important;

  table th,
  .table td {
    padding: 0.2em;
  }

  table tr {
    line-height: 1;

    td {
      max-width: 15rem;
      white-space: nowrap;

      label {
        @extend %class-for-tooltip;
      }
    }
  }
}

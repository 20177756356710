.modal-main-container {
  z-index: 2;
  display: none;
}
.custom-modal-show {
  position: absolute;
  display: block;
}
/* Modal Content */
.custom-modal-content {
  background-color: #fefefe;
  // margin: auto;
  padding: 10px;
  // border: 1px solid #888;
  width: 100%;
  border-radius: 1.6rem;
  box-shadow: 0px 16px 32px 0px #00000029;
}
/* The Close Button */
.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  .custom-modal-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: #000;
  }

  .custom-modal-close {
    color: #585858;
    font-size: 1.6rem;
    font-weight: 600;
  }
}
.custom-modal-close:hover,
.custom-modal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.reservation-quick-modal {
  right: 18.5%;
  top: 1%;
}

.title {
  color: #fff;
  text-align: center;
  background-color: #44b39d;
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  padding: 5rem;
  margin-bottom: 2rem;
  font-size: 3.25rem;
}

// .main {
//   padding: 0 7rem;
//   font-size: 1.1rem;
// }

.description {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.08);
  -webkit-transition: color 0.2s, background-color 0.2s, border-color 0.2s;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;
  border: 1px solid rgba(255, 255, 255, 0.2);
  text-decoration: none;
  /* display: inline-block; */
  text-align: center;
  padding: 0.6rem 0.9rem;
  font-size: 0.9rem;
  width: 50px;
  margin: 0 auto;
}

.description a {
  color: #ffffff;
}

.subtitle {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: normal;
  color: #44b39d;
  text-align: center;
  border-bottom: 2px dashed rgb(68, 179, 157);
}

.subcontainer {
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #020202;
}

.subleft {
  width: calc(50% - 30px);
  overflow-x: auto;
}

.subright {
  width: calc(50% - 30px);
}
.hover {
  background-color: #474747;
  width: 300px;
  height: 150px;
  //border-style: dashed;
  max-width: 300px;
  border-color: chocolate;
  padding: 1rem;
}

.trigger {
  font-size: 1.5em;
  background-color: #44b39d;
  color: white;
  width: 200px;
  cursor: pointer;
  p {
    text-align: center;
  }
}

.quote {
  padding: 15px 25px;
  color: #ffffff;
  font-size: 1.2rem;
  width: calc(100% - 40px);
  float: right;
  margin-top: 2rem;
}

.thumbnail {
  float: left;
  margin-top: 40px;
}

.people {
  float: right;
  margin-top: -13px;
  color: #ffffff;
}
.tooltipContainer {
  opacity: 1;
  z-index: 1000;
  width: fit-content;
  span {
    white-space: normal !important;
  }
}
.tooltipContainer-max {
  white-space: break-spaces;
  max-width: 30rem !important;
}
.width-max-content {
  width: max-content !important;
}

// .tt-container {
//   display: block;
// }

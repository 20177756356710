// COLORS
$color-primary: #1e4e9c;
$color-primary-light: #6ea4fc;
$color-primary-light-1: #486cbf;

$color-primary-dark: #0e397e;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #666;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

//FONT FAMILY
$font-family-inter: "Inter";

// FONT SIZE
$default-font-size: 1.2rem;

$other-font-size-07: 0.7rem;
$other-font-size-08: 0.8rem;
$other-font-size-09: 0.9rem;
$other-font-size-0: 1rem;
$other-font-size-1: 1.1rem;
$other-font-size-2: 1.2rem;
$other-font-size-3: 1.3rem;
$other-font-size-4: 1.4rem;
$other-font-size-5: 1.5rem;
$other-font-size-6: 1.6rem;
$other-font-size-7: 1.7rem;
$other-font-size-8: 1.8rem;
$other-font-size-9: 1.9rem;
$other-font-size-2-0: 2rem;
$other-font-size-2-1: 2.1rem;
$other-font-size-2-2: 2.2rem;
$other-font-size-2-3: 2.3rem;
$other-font-size-2-4: 2.4rem;
$other-font-size-2-5: 2.5rem;
$other-font-size-2-6: 2.6rem;
$other-font-size-2-7: 2.7rem;
$other-font-size-2-8: 2.8rem;
$other-font-size-2-9: 2.9rem;
$other-font-size-3-0: 3rem;
$other-font-size-3-5: 3.5rem;
$other-font-size-4-0: 4rem;

$font-primary: "Nunito", sans-serif;
$font-display: "Josefin Sans", sans-serif;

:root {
  --color-primary: #1e4e9c;
  --color-primary-light: #6ea4fc;
  --color-primary-light-1: #486cbf;

  --color-notification: #eb2f64;
  --color-notification-light: #ff3366;
  --color-notification-dark: #ba265d;

  --color-topmenu-main: #296ddb;
  --color-topmenu-main-light: #537bbe;
  --color-topmenu-main-dark: #136aa8;

  --color-mainmenu: #7293c8;
  --color-mainmenu-1: #3b5b92;
  --color-mainmenu-2: #16486c;

  --color-grey-primary: lightgray;
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-light-5: #F8F8F8;

  --color-grey-dark-1: #333;
  --color-grey-dark-2: #666;
  --color-grey-dark-3: #999;

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);

  --line: 1px solid var(--color-grey-light-2);

  --color-quicklink-hover-1: #486cbf;

  --random-color-1: #147bc4;
  --table-Header-1: #1e4e9c;
  --table--border-1: #0376f1;

  --btn-cb-back-1: #1e4e9c;
  --btn-cb-border-1: #1e4e9c;

  --btn-back-1: #0062cc;
  --btn-border-1: #005cbf;
  --btn-hover-1: #0069d9;

  --pricing-header-1: #0062cc;
  --pricing-header-2: #005cbf;
  --pricing-header-3: #0069d9;

  --impersonate-mode-outer-color: #c52529;
  --color-warning: #ff9700;

  --btn-fox-yellow: #FFDF17;
  --btn-fox-yellow-hover: #E8CB15;

  --btn-fox-disabled: #BEBEBE;

  --btn-fox-blue: #001E60;
  --btn-fox-blue-hover: #001544;

  --btn-fox-blue-light: #E6E9EF;

  --btn-fox-danger-light: #FEEBEB;
  --btn-fox-danger: #E33333;
  --btn-fox-danger-hover: #B12828;

  --color-fox-disabled: #949494;



}

$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;

$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
#serviceAgentReportTable .datagrid {
  .row {
    @extend %dataGridRow;
    grid-template-columns: 2% 7% 7% 6% 9% 8% 6% 6% 6% 6% 6% 6% 8% 8% 9%;
  }

  .column {
    @extend %dataGridColumn;
    grid-template-columns: 2% 7% 7% 6% 9% 8% 6% 6% 6% 6% 6% 6% 8% 8% 9%;
  }

  .row__column label {
    @extend %class-for-tooltip;
  }
}

#mechanicReportTable .datagrid {
  .row {
    @extend %dataGridRow;
    grid-template-columns: 2% 6% 6% 5% 5% 7% 6% 3% 5% 5% 5% 5% 6% 8% 8% 9% 4.5% 4.5%;
  }

  .column {
    @extend %dataGridColumn;
    grid-template-columns: 2% 6% 6% 5% 5% 7% 6% 3% 5% 5% 5% 5% 6% 8% 8% 9% 4.5% 4.5%;
  }

  .row__column label {
    @extend %class-for-tooltip;
  }
}

#mechanicReportTable .datagrid #reportProblemDetailsSearchTable .datagrid .row {
  @extend %dataGridRow;
  grid-template-columns: 15% 15% 15% 15% 5% 15% 15% 5%;
}

#reportProblemDetailsSearchTable .datagrid .column {
  @extend %dataGridColumn;
  grid-template-columns: 15% 15% 15% 15% 5% 15% 15% 5%;
}

.mechanic-container {
  display: grid;
  grid-auto-flow: column;
  padding: 25px;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
}

.trackerDetails {
  display: grid;
  grid-auto-flow: row;
  /*grid-template-rows: repeat(3, 1fr);*/
  align-self: start;
}

.trackerInfo {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 1fr);
  align-self: start;
}

.trackerInfo h3 {
  padding: 0;
  grid-column: 1/-1;
  border-bottom: 1px solid #e0e0e0;
  color: var(--color-primary);
  margin: 10px 0px 10px 0px;
}

/*.trackerInfo h3:first-child {
                margin-top:0px;
            }*/
.trackerInfo__Detail--img {
  grid-column: 1/-1;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-top: 15px;
}

.trackerInfo__Detail--img img {
  width: 60px;
}

.trackerInfo__Title {
  color: #494949;
  font-weight: bold;
  font-size: $default-font-size;
  min-height: 20px;
  white-space: normal;
  vertical-align: middle;
  text-overflow: ellipsis;
  text-align: left;
  word-wrap: break-word;
  width: 100px;
}

.trackerInfo__Detail {
  color: #494949;
  font-weight: normal;
  width: 200px;
  font-size: $default-font-size;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  text-overflow: ellipsis;
  text-align: left;
}

#readyLineReportTable .datagrid {
  .row {
    @extend %dataGridRow;
    grid-template-columns: 2% 7% 7% 11% 7% 8% 8% 5% 11% 14% 10% 10%;
  }

  .column {
    @extend %dataGridColumn;
    grid-template-columns: 2% 7% 7% 11% 7% 8% 8% 5% 11% 14% 10% 10%;
  }

  .row__column label {
    @extend %class-for-tooltip;
  }
}
%formRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.formRow {
  @extend %formRow;
  margin-right: -15px;
  margin-left: -15px;
}

.form-group {
  margin-bottom: 0.5rem;
}
.form-control.error {
  background: rgb(251, 227, 228);
  border: 1px solid #fbc2c4;
  color: #8a1f11;
}
.form-group.required .col-form-label:after {
  font-weight: bold;
  color: #d06161;
  content: "*";
  position: absolute;
  font-size: $other-font-size-4;
  top: -0.2rem;
  padding-left: 0.1rem;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
// @media (min-width: 992px) .col-lg-6 {
//   -ms-flex: 0 0 50%;
//   flex: 0 0 50%;
//   max-width: 50%;
// }
.input-group .btn {
  box-shadow: none;
  padding: 0.375rem 0.75rem;
}

.col-form-label {
  font-size: $other-font-size-1;
  overflow: auto;
}

.form-control-rounded {
  border-radius: 30px !important;
}

.form-control-square {
  border-radius: 0px !important;
}

.form-control-xl {
  height: 6rem !important;
  font-size: 2.6rem !important;
}

.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}

.has-icon-left .form-control {
  padding-right: 0.85rem;
  padding-left: 2.9rem;
}

.form-control-position {
  position: absolute;
  top: -8px;
  right: 0;
  z-index: 2;
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
}

.has-icon-left .form-control-position {
  right: auto;
  left: 0px;
}

.has-icon-right .form-control-position {
  right: 0px;
  left: auto;
}

.has-icon-right .form-control {
  padding-right: 37px;
}
form .form-header, .modal .form-header {
  color: var(--color-primary);
  line-height: 3rem;
  font-size: $other-font-size-3;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 1rem;
  border-bottom: 0.1rem solid var(--color-primary);
  i {
    padding-right: 0.5rem;
  }
  &[disabled]{
    background-color: #e9ecef !important;
    opacity: 0.5;
    color: gray;
  }
}

form .form-footer {
  border-top: 0.1rem solid #d3dce9;
  padding: 1rem 0 10px 0;
  margin-top: 1rem;
}

form.form-bordered .form-group {
  margin: 0;
  border-bottom: 1px solid #ebeff5;
}

form.form-bordered .form-group > div {
  padding: 20px;
  padding-right: 0;
  border-left: 1px solid #ebeff5;
}

form.form-bordered .form-group .col-form-label {
  padding-top: 30px;
}

form.striped-rows .form-group:nth-child(2n) {
  background-color: #f4f5fa;
}

form.striped-rows .form-group {
  margin: 0;
}
.search-input {
  margin-bottom: 10px;
}
.custom-header {
  background: #484545;
  padding: 5px;
  color: white;
}

.input-group-prepend [type="checkbox"]:checked,
.input-group-prepend [type="checkbox"]:not(:checked),
.input-group-prepend [type="radio"]:checked,
.input-group-prepend [type="radio"]:not(:checked) {
  position: initial;
  opacity: 1;
  margin-top: 0px;
}

.wizard > .content {
  min-height: 400px;
}

.dropzone {
  min-height: 230px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 6px;
}
.dropzone .dz-message {
  font-size: 3rem;
}

.border-radius {
  border-radius: 0px;
}

.payment-icons img {
  width: 100px;
}
.bootstrap-touchspin .input-group-text {
  border-radius: 0px;
}

.datepicker table tr td,
.datepicker table tr th {
  width: 40px;
  height: 40px;
}

.user-lock {
  height: 150px !important;
}

.user-lock-img {
  width: 130px;
  margin: auto;
}

.user-lock-img img {
  width: 100%;
  border-radius: 50%;
  margin-top: 80px;
  border: 4px solid white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.memberInput {
  display: grid;
  grid-auto-flow: column;
  justify-content: left;
  grid-column-gap: 1rem;
}
.memberInput-phone {
  grid-template-columns: 5rem 1% 1fr;
}
.memberInput-ddl {
  grid-template-columns: 1fr 1fr;
}
.newMemberCaption {
  border-bottom: 1px solid var(--color-primary);
  color: var(--color-primary);
  //font-size:  $default-font-size;
  margin: 1rem;
}

/* User Cards */
.user-box {
  width: 110px;
  margin: auto;
  margin-bottom: 20px;
}

.user-box img {
  width: 100%;
  border-radius: 50%;
  padding: 3px;
  background: #fff;
  -webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

.profile-card-2 .card {
  position: relative;
}

.profile-card-2 .card .card-body {
  z-index: 1;
}

.profile-card-2 .card::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  height: 112px;
  background-color: #e6e6e6;
}

.profile-card-2 .card.profile-primary::before {
  background-color: #008cff;
}
.profile-card-2 .card.profile-success::before {
  background-color: #15ca20;
}
.profile-card-2 .card.profile-danger::before {
  background-color: #fd3550;
}
.profile-card-2 .card.profile-warning::before {
  background-color: #ff9700;
}
.profile-card-2 .user-box {
  margin-top: 30px;
}

.profile-card-3 .user-fullimage {
  position: relative;
}

.profile-card-3 .user-fullimage .details {
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  background: #000;
  opacity: 0.7;
  padding: 1rem;
}

.profile-card-4 .user-box {
  width: 110px;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 15px;
}

.profile-card-4 .list-icon {
  display: table-cell;
  font-size: 3rem;
  padding-right: 20px;
  vertical-align: middle;
  color: #223035;
}
y .profile-card-4 .list-details {
  display: table-cell;
  vertical-align: middle;
  font-weight: 600;
  color: #223035;
  font-size: $other-font-size-5;
  line-height: $other-font-size-5;
}

.profile-card-4 .list-details small {
  display: table-cell;
  vertical-align: middle;
  //font-size: $default-font-size;
  font-weight: 400;
  color: #808080;
}

.icon a:hover {
  background: #03a9f4;
  color: #fff;
  text-decoration: none;
}

.icon a {
  display: block;
  color: #424242;
  padding: 8px;
  line-height: 32px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 2px;
}

.icon a i {
  padding-right: 10px;
}

.icon-section {
  clear: both;
  overflow: hidden;
}
.icon-container {
  width: 240px;
  padding: 0.7em 0;
  float: left;
  position: relative;
  text-align: left;
}
.icon-container [class^="ti-"],
.icon-container [class*=" ti-"] {
  color: #000;
  position: absolute;
  margin-top: 3px;
  transition: 0.3s;
}
.icon-container:hover [class^="ti-"],
.icon-container:hover [class*=" ti-"] {
  font-size: 2.2em;
  margin-top: -5px;
}
.icon-container:hover .icon-name {
  color: #000;
}
.icon-name {
  color: #424242;
  margin-left: 35px;
  transition: 0.3s;
}
.icon-container:hover .icon-name {
  margin-left: 45px;
}

.preview {
  padding: 15px 0;
  position: relative;
}

.show-code {
  color: #101010;
}

.icons {
  font-size: $other-font-size-5;
  padding-right: 7px;
}

.name {
  font-size: $other-font-size-5;
}

.preview a {
  padding: 15px;
}
.preview a:hover {
  padding: 15px;
  text-decoration: none;
}

.preview a i {
  margin-right: 1rem;
  font-size: 1.8rem;
}

.icon-preview-box div:hover {
  background: #eef5f9;
}

.flag-icon-list div {
  cursor: pointer;
  line-height: 6rem;
  white-space: nowrap;
  color: #424242;
}

.flag-icon-list div:hover {
  background: #eef5f9;
}

.flag-icon-list i {
  -webkit-transition: all 0.2s;
  -webkit-transition: font-size 0.2s;
  display: inline-block;
  font-size: 1.8rem;
  margin: 0 1.5rem 0 10px;
  text-align: left;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -o-transition: font-size 0.2s;
  transition: font-size 0.2s;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-truncate-block {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.flex-end-content {
  justify-content: flex-end;
}
.flex-center-content {
  justify-content: center;
}
.flex-align-self-center {
  align-self: center;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 1rem;
  border: 0;
  border-top: 1px solid #e4eaec;
  margin-bottom: 1rem;
}

.porfileiMage1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  // font-weight: bold;
}
.setting-row {
  @extend %formRow;
}

.DisplayGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
}

.underLine {
  margin-bottom: 1rem;
  border-bottom: 0.1rem solid var(--color-primary);
}

.pseudo-link {
  color: var(--color-primary);
  outline: none;
  cursor: pointer;
  label {
    cursor: pointer !important;
  }
}
.pseudo-link:hover {
  color: var(--color-primary-light);
  text-decoration: none;
}
.show-more-link {
  font-family: monospace;
  font-size: $other-font-size-2;
}
.popupButtonRow {
  border-top: 0.1rem solid #d3dce9;
  padding: 1rem 0 0px 0px;
  margin: 1rem 0px -1rem 0px;
}
.user-fullimage {
  position: relative;
}
.user-fullimage .details {
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  background: #000;
  opacity: 0.7;
  padding: 1rem;
}
.user-fullimage img {
  height: 20rem;
}
.uploadPhotoClose {
  position: absolute;
  right: 0;
  font-size: 2.5rem;
  color: red;
  display: none;
  cursor: pointer;
  // background: #fff;
}
.fileUploadDocName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.uploadIcon {
  //font-size:  $default-font-size;
  margin: 0.4rem;
  vertical-align: middle;
  color: #fff;
}
.loggedUserUploadImg {
  display: none;
}
.user-fullimage:hover .loggedUserUploadImg {
  display: block;
  opacity: 0.7;
}
.display-hidden {
  display: none;
}
.visibility-hidden {
  visibility: hidden;
}
.rw-widget > .error {
  background: #fbe3e4;
  border: 1px solid #fbc2c4;
  color: #8a1f11;
}
.actionDisabled {
  pointer-events: none;
}
.normal-header1 {
  color: var(--color-primary);
  line-height: 3rem;
  font-size: $other-font-size-3;
  // letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.noTextDecoration {
  text-decoration: none !important;
}
.align-content-baseline {
  align-content: baseline !important;
}

#additional-driver-modal,
#personal-info,
#userProfile,
#main-settings,
#vehicle-details-id,
.modal,
#edit-new-reservation-rental,
#landmark-info,
.zoom {
  .form-group.required {
    .validation-failed {
      .rw-widget-input,
      select,
      input,
      input + label::before,
      textarea {
        border: 1px solid #d06161;
      }
    }
    .rw-widget-input {
      input {
        border: none;
      }
    }
  }

  .form-group{
    .validation-warned {
      input,
      .rw-combobox .rw-widget-container {
        border: 1px solid var(--color-warning) !important;
        input{
          border: none !important;
        }
      }
    }
  }
}
#impersonate-modal-id {
  input.validation-failed {
    border: 1px solid #d06161;
  }
}
// input[aria-invalid='false'] {
//   border: 3px solid green;
// }
input[type="text"][aria-invalid='true'],
input[type="number"][aria-invalid='true'],
select[aria-invalid='true'] {
  border: 1px solid #d06161;
}

input[type="radio"][aria-invalid='true']+label::before {
  border: 1px solid red !important;
}
// /*Nav Tabs & Pills */

// .navboot {
//   display: grid !important;
//   grid-auto-flow: column;
//   justify-content: end;
//   z-index: 0 !important;
// }
// .nav-link {
//   display: block;
//   padding: 0.5rem 1rem;
// }

// .nav-link:focus,
// .nav-link:hover {
//   text-decoration: none;
// }

// .nav-link.disabled {
//   color: #6c757d;
// }

// .nav-tabs {
//   border-bottom: 1px solid #dee2e6;
// }

// .nav-tabs .nav-item {
//   margin-bottom: -1px;
// }

// .nav-tabs .nav-link {
//   border: 1px solid transparent;
//   border-top-left-radius: 0.25rem;
//   border-top-right-radius: 0.25rem;
//   text-decoration: none;
// }

// .nav-tabs .nav-link:focus,
// .nav-tabs .nav-link:hover {
//   border-color: #e9ecef #e9ecef #dee2e6;
// }

// .nav-tabs .nav-link.disabled {
//   color: #6c757d;
//   background-color: transparent;
//   border-color: transparent;
// }

// .nav-tabs .nav-item.show .nav-link,
// .nav-tabs .nav-link.active {
//   color: #495057;
//   background-color: #fff;
//   border-color: #dee2e6 #dee2e6 #fff;
// }

// .nav-tabs .dropdown-menu {
//   margin-top: -1px;
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
// }

// .nav-pills .nav-link {
//   border-radius: 0.25rem;
// }

// .nav-pills .nav-link.active,
// .nav-pills .show > .nav-link {
//   color: #fff;
//   background-color: #007bff;
// }

// .nav-fill .nav-item {
//   -ms-flex: 1 1 auto;
//   flex: 1 1 auto;
//   text-align: center;
// }

// .nav-justified .nav-item {
//   -ms-flex-preferred-size: 0;
//   flex-basis: 0;
//   -ms-flex-positive: 1;
//   flex-grow: 1;
//   text-align: center;
// }

// .tab-content > .tab-pane {
//   display: none;
// }

// .tab-content > .active {
//   display: block;
// }

// .navbar {
//   position: relative;
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-wrap: wrap;
//   flex-wrap: wrap;
//   -ms-flex-align: center;
//   align-items: center;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   padding: 0.5rem 1rem;
// }

// .navbar > .container,
// .navbar > .container-fluid {
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-wrap: wrap;
//   flex-wrap: wrap;
//   -ms-flex-align: center;
//   align-items: center;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
// }

// .navbar-brand {
//   display: inline-block;
//   padding-top: 0.3125rem;
//   padding-bottom: 0.3125rem;
//   margin-right: 1rem;
//   font-size: 1.25rem;
//   line-height: inherit;
//   white-space: nowrap;
// }

// .navbar-brand:focus,
// .navbar-brand:hover {
//   text-decoration: none;
// }

// .navbar-nav {
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   padding-left: 0;
//   margin-bottom: 0;
//   list-style: none;
// }

// .navbar-nav .nav-link {
//   padding-right: 0;
//   padding-left: 0;
// }

// .navbar-nav .dropdown-menu {
//   position: static;
//   float: none;
// }

// .navbar-text {
//   display: inline-block;
//   padding-top: 0.5rem;
//   padding-bottom: 0.5rem;
// }

// .navbar-collapse {
//   -ms-flex-preferred-size: 100%;
//   flex-basis: 100%;
//   -ms-flex-positive: 1;
//   flex-grow: 1;
//   -ms-flex-align: center;
//   align-items: center;
// }

// .navbar-toggler {
//   padding: 0.25rem 0.75rem;
//   font-size: 1.25rem;
//   line-height: 1;
//   background-color: transparent;
//   border: 1px solid transparent;
//   border-radius: 0.25rem;
// }

// .navbar-toggler:focus,
// .navbar-toggler:hover {
//   text-decoration: none;
// }

// .navbar-toggler:not(:disabled):not(.disabled) {
//   cursor: pointer;
// }

// .navbar-toggler-icon {
//   display: inline-block;
//   width: 1.5em;
//   height: 1.5em;
//   vertical-align: middle;
//   content: "";
//   background: no-repeat center center;
//   background-size: 100% 100%;
// }

// @media (max-width: 575.98px) {
//   .navbar-expand-sm > .container,
//   .navbar-expand-sm > .container-fluid {
//     padding-right: 0;
//     padding-left: 0;
//   }
// }
// .nav-tabs .nav-link {
//   color: #223035;
//   font-size: 12px;
//   text-align: center;
//   letter-spacing: 1px;
//   font-weight: 600;
//   margin: 2px;
//   margin-bottom: 0;
//   padding: 12px 20px;
//   text-transform: uppercase;
//   border: 1px solid transparent;
//   border-top-left-radius: 0.25rem;
//   border-top-right-radius: 0.25rem;
//   background-image: none;
// }
// .nav-tabs .nav-link:hover {
//   border: 1px solid transparent;
// }
// .nav-tabs .nav-link i {
//   margin-right: 2px;
//   font-weight: 600;
// }

// .top-icon.nav-tabs .nav-link i {
//   margin: 0px;
//   font-weight: 500;
//   display: block;
//   font-size: 20px;
//   padding: 5px 0;
// }

// .nav-tabs-primary.nav-tabs {
//   border-bottom: 1px solid #008cff;
// }

// .nav-tabs-primary .nav-link.active,
// .nav-tabs-primary .nav-item.show > .nav-link {
//   color: #008cff;
//   background-color: #fff;
//   border-color: #008cff #008cff #fff;
//   border-top: 3px solid #008cff;
//   text-decoration: none;
// }

// .nav-tabs-success.nav-tabs {
//   border-bottom: 1px solid #15ca20;
// }

// .nav-tabs-success .nav-link.active,
// .nav-tabs-success .nav-item.show > .nav-link {
//   color: #15ca20;
//   background-color: #fff;
//   border-color: #15ca20 #15ca20 #fff;
//   border-top: 3px solid #15ca20;
//   text-decoration: none;
// }

// .nav-tabs-info.nav-tabs {
//   border-bottom: 1px solid #0dceec;
// }

// .nav-tabs-info .nav-link.active,
// .nav-tabs-info .nav-item.show > .nav-link {
//   color: #0dceec;
//   background-color: #fff;
//   border-color: #0dceec #0dceec #fff;
//   border-top: 3px solid #0dceec;
//   text-decoration: none;
// }

// .nav-tabs-danger.nav-tabs {
//   border-bottom: 1px solid #fd3550;
// }

// .nav-tabs-danger .nav-link.active,
// .nav-tabs-danger .nav-item.show > .nav-link {
//   color: #fd3550;
//   background-color: #fff;
//   border-color: #fd3550 #fd3550 #fff;
//   border-top: 3px solid #fd3550;
// }

// .nav-tabs-warning.nav-tabs {
//   border-bottom: 1px solid #ff9700;
// }

// .nav-tabs-warning .nav-link.active,
// .nav-tabs-warning .nav-item.show > .nav-link {
//   color: #ff9700;
//   background-color: #fff;
//   border-color: #ff9700 #ff9700 #fff;
//   border-top: 3px solid #ff9700;
// }

// .nav-tabs-dark.nav-tabs {
//   border-bottom: 1px solid #223035;
// }

// .nav-tabs-dark .nav-link.active,
// .nav-tabs-dark .nav-item.show > .nav-link {
//   color: #223035;
//   background-color: #fff;
//   border-color: #223035 #223035 #fff;
//   border-top: 3px solid #223035;
// }

// .nav-tabs-secondary.nav-tabs {
//   border-bottom: 1px solid #75808a;
// }
// .nav-tabs-secondary .nav-link.active,
// .nav-tabs-secondary .nav-item.show > .nav-link {
//   color: #75808a;
//   background-color: #fff;
//   border-color: #75808a #75808a #fff;
//   border-top: 3px solid #75808a;
// }

// .tabs-vertical .nav-tabs .nav-link {
//   color: #223035;
//   font-size: 12px;
//   text-align: center;
//   letter-spacing: 1px;
//   font-weight: 600;
//   margin: 2px;
//   margin-right: -1px;
//   padding: 12px 1px;
//   text-transform: uppercase;
//   border: 1px solid transparent;
//   border-radius: 0;
//   border-top-left-radius: 0.25rem;
//   border-bottom-left-radius: 0.25rem;
// }

// .tabs-vertical .nav-tabs {
//   border: 0;
//   border-right: 1px solid #dee2e6;
// }

// .tabs-vertical .nav-tabs .nav-item.show .nav-link,
// .tabs-vertical .nav-tabs .nav-link.active {
//   color: #495057;
//   background-color: #fff;
//   border-color: #dee2e6 #dee2e6 #fff;
//   border-bottom: 1px solid #dee2e6;
//   border-right: 0;
//   border-left: 1px solid #dee2e6;
// }

// .tabs-vertical-primary.tabs-vertical .nav-tabs {
//   border: 0;
//   border-right: 1px solid #008cff;
// }

// .tabs-vertical-primary.tabs-vertical .nav-tabs .nav-item.show .nav-link,
// .tabs-vertical-primary.tabs-vertical .nav-tabs .nav-link.active {
//   color: #008cff;
//   background-color: #fff;
//   border-color: #008cff #008cff #fff;
//   border-bottom: 1px solid #008cff;
//   border-right: 0;
//   border-left: 3px solid #008cff;
// }

// .tabs-vertical-success.tabs-vertical .nav-tabs {
//   border: 0;
//   border-right: 1px solid #15ca20;
// }

// .tabs-vertical-success.tabs-vertical .nav-tabs .nav-item.show .nav-link,
// .tabs-vertical-success.tabs-vertical .nav-tabs .nav-link.active {
//   color: #15ca20;
//   background-color: #fff;
//   border-color: #15ca20 #15ca20 #fff;
//   border-bottom: 1px solid #15ca20;
//   border-right: 0;
//   border-left: 3px solid #15ca20;
// }

// .tabs-vertical-info.tabs-vertical .nav-tabs {
//   border: 0;
//   border-right: 1px solid #0dceec;
// }

// .tabs-vertical-info.tabs-vertical .nav-tabs .nav-item.show .nav-link,
// .tabs-vertical-info.tabs-vertical .nav-tabs .nav-link.active {
//   color: #0dceec;
//   background-color: #fff;
//   border-color: #0dceec #0dceec #fff;
//   border-bottom: 1px solid #0dceec;
//   border-right: 0;
//   border-left: 3px solid #0dceec;
// }

// .tabs-vertical-danger.tabs-vertical .nav-tabs {
//   border: 0;
//   border-right: 1px solid #fd3550;
// }

// .tabs-vertical-danger.tabs-vertical .nav-tabs .nav-item.show .nav-link,
// .tabs-vertical-danger.tabs-vertical .nav-tabs .nav-link.active {
//   color: #fd3550;
//   background-color: #fff;
//   border-color: #fd3550 #fd3550 #fff;
//   border-bottom: 1px solid #fd3550;
//   border-right: 0;
//   border-left: 3px solid #fd3550;
// }

// .tabs-vertical-warning.tabs-vertical .nav-tabs {
//   border: 0;
//   border-right: 1px solid #ff9700;
// }

// .tabs-vertical-warning.tabs-vertical .nav-tabs .nav-item.show .nav-link,
// .tabs-vertical-warning.tabs-vertical .nav-tabs .nav-link.active {
//   color: #ff9700;
//   background-color: #fff;
//   border-color: #ff9700 #ff9700 #fff;
//   border-bottom: 1px solid #ff9700;
//   border-right: 0;
//   border-left: 3px solid #ff9700;
// }

// .tabs-vertical-dark.tabs-vertical .nav-tabs {
//   border: 0;
//   border-right: 1px solid #223035;
// }

// .tabs-vertical-dark.tabs-vertical .nav-tabs .nav-item.show .nav-link,
// .tabs-vertical-dark.tabs-vertical .nav-tabs .nav-link.active {
//   color: #223035;
//   background-color: #fff;
//   border-color: #223035 #223035 #fff;
//   border-bottom: 1px solid #223035;
//   border-right: 0;
//   border-left: 3px solid #223035;
// }

// .tabs-vertical-secondary.tabs-vertical .nav-tabs {
//   border: 0;
//   border-right: 1px solid #75808a;
// }

// .tabs-vertical-secondary.tabs-vertical .nav-tabs .nav-item.show .nav-link,
// .tabs-vertical-secondary.tabs-vertical .nav-tabs .nav-link.active {
//   color: #75808a;
//   background-color: #fff;
//   border-color: #75808a #75808a #fff;
//   border-bottom: 1px solid #75808a;
//   border-right: 0;
//   border-left: 3px solid #75808a;
// }

// .nav-pills .nav-link {
//   border-radius: 0.25rem;
//   color: #223035;
//   font-size: 12px;
//   text-align: center;
//   letter-spacing: 1px;
//   font-weight: 600;
//   text-transform: uppercase;
//   margin: 3px;
//   padding: 12px 20px;
//   -webkit-transition: all 0.3s ease;
//   -moz-transition: all 0.3s ease;
//   -o-transition: all 0.3s ease;
//   transition: all 0.3s ease;
// }

// .nav-pills .nav-link:hover {
//   background-color: #f4f5fa;
// }

// .nav-pills .nav-link i {
//   margin-right: 2px;
//   font-weight: 600;
// }

// .top-icon.nav-pills .nav-link i {
//   margin: 0px;
//   font-weight: 500;
//   display: block;
//   font-size: 20px;
//   padding: 5px 0;
// }

// .nav-pills .nav-link.active,
// .nav-pills .show > .nav-link {
//   color: #fff;
//   background-color: #008cff;
//   box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
//     0 7px 10px -5px rgba(0, 140, 255, 0.5);
// }

// .nav-pills-success .nav-link.active,
// .nav-pills-success .show > .nav-link {
//   color: #fff;
//   background-color: #15ca20;
//   box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
//     0 7px 10px -5px rgba(21, 202, 32, 0.5);
// }

// .nav-pills-info .nav-link.active,
// .nav-pills-info .show > .nav-link {
//   color: #fff;
//   background-color: #0dceec;
//   box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
//     0 7px 10px -5px rgba(13, 206, 236, 0.5);
// }

// .nav-pills-danger .nav-link.active,
// .nav-pills-danger .show > .nav-link {
//   color: #fff;
//   background-color: #fd3550;
//   box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
//     0 7px 10px -5px rgba(253, 53, 80, 0.5);
// }

// .nav-pills-warning .nav-link.active,
// .nav-pills-warning .show > .nav-link {
//   color: #fff;
//   background-color: #ff9700;
//   box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
//     0 7px 10px -5px rgba(255, 151, 0, 0.5);
// }

// .nav-pills-dark .nav-link.active,
// .nav-pills-dark .show > .nav-link {
//   color: #fff;
//   background-color: #223035;
//   box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
//     0 7px 10px -5px rgba(34, 48, 53, 0.5);
// }

// .nav-pills-secondary .nav-link.active,
// .nav-pills-secondary .show > .nav-link {
//   color: #fff;
//   background-color: #75808a;
//   box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
//     0 7px 10px -5px rgba(117, 128, 138, 0.5);
// }
// .card .tab-content {
//   padding: 1rem 0 0 0;
// }

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.8rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
  cursor: pointer;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

// .navbar-light .navbar-toggler-icon {
//   background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
// }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

// .navbar-dark .navbar-toggler-icon {
//   background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
// }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

#sidebar {
  position: absolute;
  top: 9rem;
  bottom: 0;
  left: 0;
  z-index: 7;
  width: 17rem;
  background: #ffffff;

  .toggleBtn {
    background: var(--color-primary);
    outline: none;
    i {
      color: #fff;
    }
  }
  .collapse {
    display: none;
  }
  &.active {
    min-width: 5rem;
    max-width: 5rem;
    text-align: center;

    .project-name {
      display: none;
    }

    a {
      text-decoration: none;
      svg {
        width: 1.5em;
        margin: 0 10px 0 5px;
      }

      span {
        display: none;
      }
      i {
        color: #a9afbb;
      }
    }
    .sidebar-header {
      text-align: center;
      //   padding: 25px 0 25px 0px;

      img.collapsed {
        display: block;
        transition: all 0.2s ease-in-out;
        max-width: 2em;
        // display: inline-block;
      }

      .pisces-logo {
        display: none;
        transition: all 0.2s ease-in-out;
      }

      .project-name {
        margin-top: 2px;
        text-align: center;
      }
    }

    ul {
      li {
        position: relative;

        a {
          i {
            margin: 0 1.5rem 0 0.5rem !important;
            width: 1.1em;
            height: auto;
            margin-right: 0;
            display: block;
            font-size: $other-font-size-5;
            margin-bottom: 5px;
            transition: all 0.3s ease-in-out;
            text-align: center;
          }

          svg {
            margin: 0 1.5rem 0 0.5rem;
            width: 1.1em;
            height: auto;

            path {
              fill: #568e99;
              transition: all 0.3s ease-in-out;
            }
          }
          &:hover {
            span {
              position: absolute;
              text-align: center;
              color: #3c4858;
              display: block;
              box-shadow: none;
              width: 15rem;
              padding: 1.5rem 1rem;
              left: 5rem;
              top: 0;
              background-color: #ffffff;
              box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
                0 7px 10px -5px rgba(255, 255, 255, 0.4);
              &:hover {
                border-left: 5px solid var(--color-primary);
              }
            }
          }
        }
      }

      ul {
        a {
          padding: 10px !important;
          font-size: 0.9em !important;
          padding-left: 30px !important;
          // background: #26727f;
          color: #3c4858 !important;
        }
      }
    }

    .dropdown-toggle {
      &:after {
        position: relative;
        left: 9px;
      }
    }

    .multimenu:hover {
      background: rgba(255, 255, 255, 0.15);
      border-left: 5px solid #000;
      color: #fff;
      transition: all 0.3s ease-in-out;
      text-decoration: none !important;
      position: fixed;
      .collapseMenu {
        left: 5.5rem !important;
        position: absolute !important;
        display: block !important;
        width: 10rem;
        top: 0;
        z-index: 2000;
        a {
          background: #fff !important;
          color: #3c4858 !important;
          box-shadow: none;
          background-color: #ffffff;
          box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
            0 7px 10px -5px rgba(255, 255, 255, 0.4);
        }
        a:hover {
          border-left: 5px solid #000;
          background: #4c88cc !important;
          color: #fff !important;
        }
      }
    }
  }

  .sidebar-header {
    padding: 1.2rem;
    display: flex;
    justify-content: flex-end;
    img.collapsed {
      display: none;
      transition: all 0.2s ease-in-out;
    }

    .pisces-logo {
      max-width: 120px;
      margin-left: 20px;
      display: block;
      transition: all 0.2s ease-in-out;
    }
    box-shadow: 1rem 0rem 2rem -1.2rem rgba(0, 0, 0, 0.42);
  }
  ul {
    li:first-child {
      a {
        border-top: 1px solid rgb(218, 218, 218);
      }
    }
    li {
      position: relative;
      a {
        padding: 1.5rem 0.5rem;
        //font-size: $default-font-size;
        display: block;
        color: #3c4858;
        border-left: 5px solid transparent;
        border-bottom: 1px solid rgb(218, 218, 218);
        &:hover {
          border-left: 5px solid var(--color-primary);
          color: #000000 !important;
          transition: all 0.3s ease-in-out;
          text-decoration: none !important;

          /*New Code*/
          background: #ffffff;
          color: #3c4858;
          transition: all 0.3s ease-in-out;
          text-decoration: none !important;

          color: #3c4858;
          box-shadow: none;
          background-color: #ffffff;
          box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
            0 7px 10px -5px rgba(255, 255, 255, 0.4);

          i {
            color: #a9afbb;
            transition: all 0.3s ease-in-out;
            text-align: center;
          }

          svg {
            path {
              fill: #fff !important;
              transition: all 0.3s ease-in-out;
            }
          }
        }

        i {
          color: #a9afbb;
          margin-left: 5px;
          margin-right: 20px;
          display: inline-block;
          width: 1.1em;
          height: auto;
          font-size: 1.5em;
          text-align: center;
          &:hover {
            color: #000000 !important;
          }
        }

        svg {
          margin: 0 15px 0 10px;
          width: 1.1em;
          height: auto;

          path {
            fill: #000;
          }
        }
      }

      &.active {
        a {
          color: #fff;
          background: var(--color-primary) !important;
          box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
            0 7px 10px -5px rgba(60, 72, 88, 0.4);
          border-left: 5px solid #000;
          i {
            color: #fff !important;
          }

          svg {
            path {
              fill: #fff !important;
            }
          }
        }
        span {
          color: #fff;
        }
      }
      a {
        text-decoration: none;
        text-align: left;
      }
    }
    .multimenu:hover {
      background: rgba(255, 255, 255, 0.15);
      .collapse {
        border-left: 0;
        display: block !important;

        li {
          border-left: 0 !important;
        }
        a {
          border-left: 0 !important;
          padding: 10px !important;
          font-size: 0.9em !important;
          padding-left: 30px !important;
          background: #004ba0;
          color: #fff !important;
        }
        a:hover {
          background: #ffffff;
          border-left: 5px solid #000;
          color: #fff;
          transition: all 0.3s ease-in-out;
          text-decoration: none !important;
          /*New Code*/
          color: #3c4858;
          box-shadow: none;
          background-color: #ffffff;
          box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
            0 7px 10px -5px rgba(255, 255, 255, 0.4);
        }
      }
    }
    box-shadow: 0rem 1rem 3rem -1.2rem rgba(0, 0, 0, 0.42),
      0rem 0.4rem 2.5rem 0rem rgba(0, 0, 0, 0.12),
      0rem 0.8rem 1rem -0.5rem rgba(0, 0, 0, 0.2);
  }
}

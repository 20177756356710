select {
  display: block;
  width: 100%;
  height: 2rem;
  padding: 0rem 1.5rem 0rem 0.2rem;
  font-size: $other-font-size-1;
  line-height: 1.5;
  color: #495057;
  background-color: #fff !important;
  background-clip: padding-box;
  border: 0.1rem solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: url(../../assets/img/icon/dropdown-arrow.png) right 0.5rem center
    no-repeat;
  option[disabled] {
    color: lightgray;
  }
}

/* CAUTION: IE hackery ahead */
select::-ms-expand {
  display: none; /* remove default arrow on ie10 and ie11 */
}
select:disabled,.rw-state-disabled {
  background-color: #e9ecef !important;
  opacity: 0.5;
}

// .rw-select-bordered{
//   border-left: none;
// }

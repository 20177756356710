.mainmenu {
  height: 4rem;
  background: linear-gradient(
    to bottom,
    var(--color-topmenu-main) 0%,
    var(--color-topmenu-main-light) 44%,
    var(--color-topmenu-main-dark) 100%
  );
  position: relative;
  margin-top: -0.1rem;
  &__container {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

%heading {
  font-family: $font-display;
  font-weight: 400;
}
/* Reset */
.nav-main,
.nav-main a,
.nav-main ul,
.nav-main li,
.nav-main div,
.nav-main form,
.nav-main input {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

.nav-main a {
  text-decoration: none;
}

.nav-main li {
  list-style: none;
}

/* Menu Container */
.nav-main {
  display: inline-block;
  position: relative;
  cursor: default;
  z-index: 500;
}
/* Menu List */
.nav-main > li {
  display: inline;
  text-align: center;
  margin-left: 0.5rem;
}
/* Menu Links */
.nav-main > li > a {
  position: relative;
  display: block;
  z-index: 510;
  line-height: 2rem;
  padding: 0.3rem 1rem 0.3rem 1rem;
  display: inline-block;
  //min-width: 55px;
  background-position: top;
  background-repeat: repeat-x;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  -webkit-border-top-left-radius: 6px;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-topright: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-size: $default-font-size;
  border-bottom: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;

  background-image: linear-gradient(
    to bottom,
    var(--color-mainmenu) 0%,
    var(--color-mainmenu-1) 44%,
    var(--color-mainmenu-2) 100%
  );
}

.nav-main > li > a.active {
  background-color: rgb(250, 250, 250);
  background-image: none;
  color: #5a5a5a;
  border: 1px solid #fff;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  // border-bottom: none;
  text-align: center;
  font-size: $other-font-size-3;
}

.nav-main > li:hover > a {
  background: var(--color-primary);
  color: #fcfcfc;
}

.nav-main > li:first-child > a {
  // border-radius: 3px 0 0 3px;
  // border-left: none;
}

/* Menu Dropdown */
.nav-main > li > div {
  position: absolute;
  display: block;
  width: 100%;
  top: 2rem;
  left: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;

  background: #ffffff;
  border-radius: 0 0 3px 3px;

  -webkit-transition: all 0.3s ease 0.15s;
  -moz-transition: all 0.3s ease 0.15s;
  -o-transition: all 0.3s ease 0.15s;
  -ms-transition: all 0.3s ease 0.15s;
  transition: all 0.3s ease 0.15s;
  box-shadow: 0 2px 2px 1px #858585;
}
.nav-main > li:hover > div {
  opacity: 1;
  visibility: visible;
  overflow: visible;
}
.nav-main > li > div > a {
  padding: 5px;
  border-bottom: 1px solid#ccc;
}

@import "../../sass/abstracts/_mixins.scss";
@import "../../sass/abstracts/variables";

.rs-top-filter {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0 0.5rem;

  .feature-lbl {
    color: #000c28;
    font-size: 24px;
    font-style: normal;
    font-weight: $font-weight-semi-bold;
    line-height: 20px;
  }

  .rs-top-filter-ctrl-container {
    width: calc(100% - 200px);
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
  }

  .widget-ctrl {
    height: 4.8rem;
    display: flex;
    padding: 9px 16px;
    align-items: center;
    gap: 6px;
    border-radius: 12px;
    border: 1px solid #001e60;
    background: #fff;

    .rw-input {
      font-size: 1.4rem !important;
      font-style: normal;
      font-weight: $font-weight-semi-bold;
      line-height: 14px;
    }

    .rw-widget {
      width: 33rem;
    }
  }
}

.history-icon {
  display: flex;
  padding: 0.4rem;
  padding-right: 0.3rem;
  align-items: center;
  gap: 0.8rem;
  border-radius: 5.6rem;
  background: var(--neutral-grey-grey-100, #f1f1f1);
  width: 1.6rem;
  height: 1.6rem;
}

.content-title {
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.reserve-sync-grid-container {
  // border: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  min-height: 60vh;

  .no-search {
    margin: auto;
  }
}

.secondary-filter-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  position: sticky;
  top: 20px;
  background: #fff;
  z-index: 3;
  border: 1px solid #eaeaea;

  .secondary-filter,
  .legend-export {
    display: flex;
    column-gap: 1.5rem;
    align-items: center;

    .refresh-period-ico {
      color: #001e60;
      font-size: 18px;
      cursor: pointer;
    }

    .refresh-period-ico:hover {
      color: rgb(17, 40, 89);
      font-size: 20px;
      cursor: pointer;
    }

    .rw-widget-input {
      .rw-input {
        font-size: 1.3rem;
      }
    }

    .rw-list-option {
      font-size: 1.3rem;
    }
  }

  .legend-export {
    gap: 6.4rem;
  }

  .legend-container {
    display: flex;
    // width: 460px;
    height: 12px;
    justify-content: space-between;
    gap: 2.4rem;

    .star-container {
      display: flex;
      column-gap: 10px;
      align-items: center;
    }

    .accepting-reservation {
      display: flex;
      column-gap: 10px;
      align-items: center;

      div {
        height: 12px;
        width: 12px;
        background: linear-gradient(0deg, #eaf9f4, #eaf9f4),
          linear-gradient(0deg, #2ec28d, #2ec28d);
        border: 1px solid #2ec298;
      }
    }

    .review-reservation {
      display: flex;
      column-gap: 10px;
      align-items: center;

      div {
        height: 12px;
        width: 12px;
        background: linear-gradient(0deg, #fffce8, #fffce8),
          linear-gradient(0deg, #ffdd00, #ffdd00);
        border: 1px solid #ffdd00;
      }
    }

    .close-reservation {
      display: flex;
      column-gap: 10px;
      align-items: center;

      div {
        height: 12px;
        width: 12px;
        background: linear-gradient(0deg, #feebeb, #feebeb),
          linear-gradient(0deg, #f93838, #f93838);
        border: 1px solid #f93838;
      }
    }
  }

  .exportDiv {
    width: 330px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ctrl-lbl {
      font-weight: 600;
    }

    .export-type-container {
      width: 120px;
    }

    .rw-widget-container {
      border: 1px solid #001e60;
      border-radius: 5px !important;
      height: 32px !important;
      padding: 2px 10px;
    }

    .rw-state-selected {
      background-color: #001e60;
      border-color: #001e60;
      color: white;
    }
  }

  .action-button {
    padding: 0.5rem;
    border-radius: 0.6rem;
    background: #e6e9ef;
    border: 1px solid #e6e9ef;
  }
}

#table-fllet-forecast {
  &.table {
    position: relative !important;
    z-index: 2 !important;
  }

  th,
  td {
    font-size: 1rem;
  }

  th {
    background: #dadada !important;
    color: #000000;
    border: 0.5px solid #eaeaea;
    position: -webkit-sticky; // for safari
    position: sticky;
    top: 0;
    left: 0;
    padding: 4px;
    z-index: 3;
    // &:first-child {
    //   z-index: 3;
    // }
  }

  tbody {
    td {
      border: 0.5px solid #eaeaea;
      padding: 2px;
    }

    .cell-container {
      border: none;
    }

    .param-cell {
      border: 1px solid #eaeaea !important;
      color: #1e4e9c !important;
      cursor: pointer;

      div {
        margin-left: 10px;
      }
    }

    .left {
      text-align: left;
    }

    .right {
      text-align: right;
    }

    tr:nth-of-type(odd) {
      background-color: transparent !important;
    }
  }

  th {
    position: -webkit-sticky; // for safari
    position: sticky;
    // left: 0px;
    z-index: 3;
    width: 200px;
    top: 74px;
  }

  td {
    position: relative;

    &:first-child {
      position: -webkit-sticky; // for safari
      position: sticky;
      left: 0px;
      z-index: 2;
      width: 200px;
    }
  }

  td:first-child {
    tr:nth-child(odd) & {
      box-shadow: inset -2px 0px rgba(black, 0.25);
    }

    tr:nth-child(even) & {
      box-shadow: inset -2px 0px rgba(black, 0.25);
    }
  }

  .broder-edit-cell {
    border-right: 0.8px solid #b0b9ce !important;
    border-bottom: 0.8px solid #b0b9ce !important;
    position: relative;

    label {
      width: 100%;
      cursor: pointer;
    }

    input {
      width: 100%;
      height: 15px;
      border: none;
      text-align: right;
    }

    .edit-ctrl {
      position: absolute;
      display: flex;
      column-gap: 5px;
      top: 5px;
      left: 5px;
    }

    .fa-times-circle {
      cursor: pointer;
      color: #f93838;
    }

    .fa-check-circle {
      cursor: pointer;
      color: #2ec28d;
    }

    &.non-editable {
      // border-right: 0.8px transparent !important;
      // border-bottom: 0.8px transparent !important;
      label {
        cursor: default !important;
      }
    }
  }

  .edit-right {
    border-right: 0.8px solid #b0b9ce !important;
  }

  .edit-bottom {
    border-bottom: 0.8px solid #b0b9ce !important;
  }

  .bg-accepting {
    background: #eaf9f4;
  }

  .bg-near-to-close {
    background: #fffce8;
  }

  .bg-close {
    background: #feebeb;
  }

  .td-link {
    color: #3456e3;
    cursor: pointer;
  }

  .td-forecast-cell {
    background: #f1f1f1;
    color: #000000;
    font-weight: 400;
  }

  .td-reset {
    display: flex;
    width: 100%;
    column-gap: 2px;
    align-items: center;

    .fa-undo {
      cursor: pointer;
    }

    &.non-editable {
      .fa-undo {
        cursor: default !important;
      }
    }
  }

  .show {
    display: block;
  }

  .hidden {
    display: none;
  }

  .car-group-col-width {
    width: 10%;
  }

  .param-col-width {
    width: 12%;
  }

  .date-col-width {
    width: 5.7%;
  }

  .border-edit-ctrl {
    border: 1px solid #001e60 !important;
  }

  .lbl-override {
    cursor: pointer;
  }

  .td-tooltip {
    position: relative;
    // display: flex;
    border-bottom: 1px dotted black;
  }

  .td-tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    min-width: 250px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 25px;
  }

  .td-tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .tooltip-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5px;
    text-align: left;
  }
}

.search-criteria-container {
  display: flex;
  column-gap: 1rem;
  position: sticky;
  top: 0;
  z-index: 3;
  background: #fff;
  height: 25px;
  padding: 5px;
}

.fleet-override-modal {
  .modal-content {
    width: 310px !important;
    //height: 230px !important;
    height: auto !important;
  }
}

.fleet-logs-modal,
.fleet-override-modal {
  textarea,
  input[type="number"] {
    background: #eee;
  }

  ::placeholder {
    color: #c2bebe !important;
  }

  .modal-header {
    color: #000000 !important;

    .close {
      color: #000000 !important;
    }
  }

  .override-prev-block {
    padding: 10px;
    border: 1px solid #f1f1f1;
    background: #f1f1f1;
    margin-top: 1rem;
    margin-bottom: 1rem;

    div {
      display: flex;
      column-gap: 1rem;
      align-items: center;

      span {
        font-weight: 600;
      }

      i {
        font-size: 0.8rem;
      }
    }
  }

  .res-sync-input-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    row-gap: 5px;
    position: relative;

    .required:after {
      font-weight: bold;
      color: #d06161;
      content: "*";
      position: absolute;
      font-size: 1.4rem;
      top: 0;
      padding-left: 0.1rem;
    }
  }
}

.btn-fox {
  color: #ffffff;
  background: #001e60;
  border: 1px solid #001e60;
  border-radius: 5px;

  &.disabled,
  &:disabled {
    color: #495057;
    background-color: #e9ecef;
    border-color: #001e60;
    cursor: default;
  }
}

.btn-fox-cancel {
  background: #ffffff;
  border: 1px solid #001e60;
  border-radius: 5px;
}

.cancel-changes {
  display: flex;
  justify-content: center;

  button {
    padding: 1.4rem 1.6rem;
    border-radius: 12px;
    border: 1px solid #eaeaea;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: $font-weight-semi-bold;
    line-height: 20px;
  }
}

.simpleXML ul {
  list-style: none;
  margin: 0px;
  padding-left: 32px;
}

.simpleXML {
  font-family: "Courier New";
}

.simpleXML-comment {
  color: green;
}

.simpleXML-tagHeader {
  color: blue;
}

.simpleXML-cdata {
  color: gray;
}

.simpleXML-tagValue {
  color: darkred;
}

.simpleXML-collapsedText {
  color: lightgray;
}

.simpleXML-attrName {
  color: red;
}

.simpleXML-attrValue {
  color: blue;
}

.simpleXML span.simpleXML-expander,
.simpleXML span.simpleXML-expanderClose {
  height: 12px;
  width: 12px;
  display: inline-block;
}

.simpleXML span.simpleXML-expanderHeader {
  cursor: pointer;
}

.simpleXML span.simpleXML-expander-expanded:before {
  font-size: 0.9em;
  color: gray;
  content: "\25BC";
  display: inline-block;
  width: 1.1em;
  text-align: center;
  line-height: 1.8em;
}

.simpleXML span.simpleXML-expander-expanded:hover:before {
  color: #aaa;
}

.simpleXML span.simpleXML-expander-collapsed:before {
  font-size: 0.9em;
  color: gray;
  content: "\25BC";
  display: inline-block;
  width: 1.1em;
  text-align: center;
  line-height: 1.8em;
  transform: rotate(-90deg);
}
